import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Container } from '~/pages/Customer/List/styles';
import Dashboard from '~/components/Dashboard';
import Card from '~/components/Card';
import AddOrdersComponent from '~/components/Orders/Add';
import { ReportContainer, ReportItem, ReportList, LeftContent, RightContent } from './styles';
import { UserAstronaut } from '@styled-icons/fa-solid/UserAstronaut';
import { ClipboardPencil } from '@styled-icons/foundation/ClipboardPencil';
import { Colors } from '~/constants/styles';
import HeadingTitle from '~/components/HeadingTitle';
import EmployeeReport from '~/components/Reports/Employee';
import CustomerReports from '~/components/Reports/Customer';
import OrdersReports from '~/components/Reports/Orders';
import TotalReport from '~/components/Reports/Total';

export default function ReportsPage(props) {

    const { t } = useTranslation();

    return (
        <Container>
            <Dashboard>
                <Card style={{ marginTop: 20 }}>
                   <TotalReport />
                </Card>
                <Card style={{ marginTop: 20 }}>
                    <OrdersReports />
                </Card>
                <Card style={{ marginTop: 20 }}>
                   <EmployeeReport />
                </Card>
                <Card style={{ marginTop: 20 }}>
                    <CustomerReports />
                </Card>
            </Dashboard>
        </Container>
    );
}
