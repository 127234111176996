import styled from 'styled-components';

export const Container = styled.div`
    width: 50px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    svg {
        width: 25px;
        height: 25px;
    }
`;
