import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import HeadingTitle from '~/components/HeadingTitle';
import { ReportContainer, ReportItem, ReportList, LeftContent, RightContent } from '~/components/Reports/Employee/styles';
import { UserAstronaut } from '@styled-icons/fa-solid/UserAstronaut';
import { Colors } from '~/constants/styles';
import { getReportsByOrders } from '~/modules/report/actions';
import { formatNumberWithZero } from '~/utils/helper';
import { ClipboardPencil } from '@styled-icons/foundation/ClipboardPencil';
import { PackageIcon } from '@styled-icons/boxicons-regular/PackageIcon';
import { ROUTE } from '~/constants/routes';
import { redirectTo } from '~/utils/navigations';

function OrdersReports() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { reportsOrdersData = {} } = useSelector(state => state.reports);
    const {
        totalOrders,
        ordersByStatusReport = []
    } = reportsOrdersData;

    useEffect(() => {
        dispatch(getReportsByOrders())
    }, []);

    const renderOrdersReportByStatus = () => {
        return ordersByStatusReport.map(report => {
            return (
                <ReportItem onClick={() => handleViewDetail(report)}>
                    <LeftContent>
                        <span>{`${t('common.orders.name')} ${report?.name}`}</span>
                        <h3>{formatNumberWithZero(report?.total)}</h3>
                    </LeftContent>
                    <RightContent>
                        <PackageIcon color={report?.color} width={40} />
                    </RightContent>
                </ReportItem>
            )
        })
    }

    const handleViewDetail = (data) => {
        let route = '';
        if (!data) {
            route = `${ROUTE.ORDERS.LIST}`;
        } else {
            route = `${ROUTE.ORDERS.LIST}?orderStatusId=${data?.id}`;
        }
        window.open(route);
    }

    return (
        <ReportContainer>
            <HeadingTitle
                type="large"
                label={t('common.orders.name')}
            />
            <ReportList columns={3}>
                <ReportItem onClick={() => handleViewDetail(null)}>
                    <LeftContent>
                        <span>{t('report.orders.total')}</span>
                        <h3>{formatNumberWithZero(totalOrders)}</h3>
                    </LeftContent>
                    <RightContent>
                        <PackageIcon color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
                {renderOrdersReportByStatus()}
            </ReportList>
        </ReportContainer>
    )
}

export default OrdersReports
