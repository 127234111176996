import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ACTION_TYPE } from '~/constants/actionTypes';
import httpRequest from '~/services/api';
import { handleGetApiPath } from '~/utils/api';
import { API } from '~/services/app.routes';
import {
    getReportsByEmployeeSuccess,
    getReportsByCustomerSuccess,
    getReportsByOrdersSuccess,
    getTotalReportsSuccess
} from '~/modules/report/actions';

function* handleGetReportByEmployee() {
    const path = handleGetApiPath(API.REPORTS.EMPLOYEE);
    const result = yield call(httpRequest.get, path);
    const { data } = result;
    yield put(getReportsByEmployeeSuccess(data));
}

function* handleGetReportByCustomer() {
    const path = handleGetApiPath(API.REPORTS.CUSTOMER);
    const result = yield call(httpRequest.get, path);
    const { data } = result;
    yield put(getReportsByCustomerSuccess(data));
}

function* handleGetReportByOrders() {
    const path = handleGetApiPath(API.REPORTS.ORDERS);
    const result = yield call(httpRequest.get, path);
    const { data } = result;
    yield put(getReportsByOrdersSuccess(data));
}

function* handleGetTotalReport() {
    const path = handleGetApiPath(API.REPORTS.TOTAL);
    const result = yield call(httpRequest.get, path);
    const { data } = result;
    yield put(getTotalReportsSuccess(data));
}

export default all([
    takeLatest(ACTION_TYPE.REPORTS.GET_BY_EMPLOYEE, handleGetReportByEmployee),
    takeLatest(ACTION_TYPE.REPORTS.GET_BY_CUSTOMER, handleGetReportByCustomer),
    takeLatest(ACTION_TYPE.REPORTS.GET_BY_ORDERS, handleGetReportByOrders),
    takeLatest(ACTION_TYPE.REPORTS.GET_TOTAL, handleGetTotalReport),
]);
