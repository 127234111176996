import React from 'react';
import { Container } from '~/components/Icon/styles';

export default function Icon(props) {
    return (
        <Container style={props.styles}>
            <box-icon {...props} color={props.color ? props.color : '#6F7399'} />
        </Container>
    )
}
