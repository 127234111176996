import React from 'react';
import ReactDOM from 'react-dom';
import App from '~/App';
import * as serviceWorker from '~/serviceWorker';
import "../node_modules/animate.css/animate.min.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import '../node_modules/flatpickr/dist/flatpickr.css';
import '../node_modules/react-html5-camera-photo/build/css/index.css';
import '../node_modules/suneditor/dist/css/suneditor.min.css';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
