import React, { useEffect, useState } from 'react';
import {
    AddIcon,
    AvLabel,
    Container,
    HeaderWrapper,
    DragAndDropItemWrapper,
    DragAndDropWrapper,
    FormWrapper,
    NoteWrapper,
    OrdersStatusWrapper,
    OrdersStatusFormWrapper,
    SelectColorWrapper,
    ColorDashboardWrapper
} from '~/components/OrdersStatus/styles';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HeadingTitle from '~/components/HeadingTitle';
import Icon from '~/components/Icon';
import { useTranslation } from 'react-i18next';
import DialogContent from '~/components/DialogContent';
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import {
    handleAddOrdersStatus,
    handleGetOrdersStatuses,
    handleDeleteOrdersStatus,
    handleGetOrdersStatusById,
    handleUpdateOrdersStatusById,
    handleUpdateSortIndexOrdersStatuses
} from '~/modules/settings/actions';
import { ACTION } from '~/constants/common';
import { SketchPicker } from 'react-color';
import { Edit2Outline } from '@styled-icons/evaicons-outline/Edit2Outline';
import { Trash } from '@styled-icons/heroicons-outline/Trash';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { ChatHelp } from '@styled-icons/fluentui-system-regular/ChatHelp';
import { Colors } from '~/constants/styles';

export default function OrdersStatus() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [dadItems, setOrdersStatuses] = useState([]);
    const [isAction, setIsAction] = useState(ACTION.ADD);
    const [color, setColor] = useState('#ffffff');
    const [displayColor, setDisplayColor] = useState(false);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [ordersStatusSelected, setOrdersStatusSelected] = useState(false);

    const {
        ordersStatuses = [],
        ordersStatusById = {}
    } = useSelector(state => {
        return state.settings;
    });

    useEffect(() => {
        dispatch(handleGetOrdersStatuses());
    }, [])

    useEffect(() => {
        setOrdersStatuses(ordersStatuses);
    }, [ordersStatuses])

    useEffect(() => {
        setOrdersStatusSelected(ordersStatusById);
        console.log('ordersStatusById', ordersStatusById);
    }, [ordersStatusById])

    const renderItems = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: 15,
        margin: '10px 0px',
        borderRadius: 5,
        border: '1px solid #e0e0e0',
        background: isDragging && "#e0e0e0e0",
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver) => ({
        padding: '0px 10px',
        width: '100%',
        border: '1px solid #e0e0e0',
        borderRadius: 5
    });

    const onDragEnd = (item) => {
        if (!item.destination) {
            return;
        }

        const items = renderItems(
            dadItems,
            item.source.index,
            item.destination.index
        );

        // Reset sort index
        let itemsResults = items.length && items.map((item, index) => {
            return { ...item, sortIndex: index };
        });

        dispatch(handleUpdateSortIndexOrdersStatuses(itemsResults));
        setOrdersStatuses(items);
    }

    const handleSaveDialog = (values) => {
        const { name, takePicture } = values;
        const data = {
            name,
            requiredTakePicture: takePicture !== false ? true : takePicture,
            color
        }
        setIsOpenDialog(false);
        if (isAction === ACTION.ADD) {
            dispatch(handleAddOrdersStatus(data));
        } else {
            const { id: ordersStatusId } = ordersStatusById || {};
            const dataUpdate = { ...data, ordersStatusId };
            dispatch(handleUpdateOrdersStatusById(dataUpdate));
        }
    }

    const handleCancelDialog = () => {
        setIsOpenDialog(false);
        dispatch(handleGetOrdersStatuses());
    }

    const handleOpenDialogAdd = () => {
        setIsOpenDialog(true);
        setColor('#ffffff');
        setIsAction(ACTION.ADD);
    }

    const handleEdit = (item) => {
        const { id } = item;
        setIsOpenDialog(true);
        setIsAction(ACTION.EDIT);
        setOrdersStatusSelected(item);
        dispatch(handleGetOrdersStatusById({ ordersStatusId: id }));
    }

    const handleDelete = (item) => {
        const { id } = item;
        dispatch(handleDeleteOrdersStatus({ ordersStatusId: id }));
    }

    const handleChangeColor = (color) => {
        if (isAction === ACTION.EDIT) {
            let ordersStatusTemp = [...ordersStatuses];
            const { id } = ordersStatusSelected;

            ordersStatuses.forEach((item, index) => {
                if (item.id === id) {
                    ordersStatusTemp[index].color = color.hex;
                }
                setOrdersStatusSelected(ordersStatusTemp[index]);
            });
            setColor(color.hex);
            setOrdersStatuses(ordersStatusTemp);
        }
        setColor(color.hex);
    }

    const handleSelectColor = () => {
        setDisplayColor(!displayColor);
    };

    const handleCloseSelectColor = () => {
        setDisplayColor(false);
    };

    const renderAddOrdersStatusContent = () => {
        const { name, requiredTakePicture = false, color: backgroundColor } = ordersStatusSelected || {};
        return (
            <div>
                <AvField
                    name="name"
                    label={t('dialog.orders_status.name')}
                    placeholder={t('dialog.orders_status.placeholder')}
                    value={(isAction === ACTION.EDIT && ordersStatusSelected) && name}
                    type="text"
                    validate={{
                        required: { value: true, errorMessage: t('dialog.orders_status.required.name') },
                    }}
                />
                <AvLabel>{t('common.color')}</AvLabel>
                <OrdersStatusFormWrapper>
                    <SelectColorWrapper
                        onClick={handleSelectColor}
                    >
                        <div style={{
                            background: (isAction === ACTION.EDIT && ordersStatusSelected) ? backgroundColor : color,
                        }} />
                    </SelectColorWrapper>
                    {displayColor ? <ColorDashboardWrapper>
                        <div style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }} onClick={handleCloseSelectColor} />
                        <SketchPicker color={color} onChange={handleChangeColor} />
                    </ColorDashboardWrapper> : null}
                </OrdersStatusFormWrapper>
                <AvGroup>
                    <AvLabel>
                        <AvInput
                            type="checkbox"
                            trueValue="true"
                            defaultChecked={isAction === ACTION.EDIT && requiredTakePicture}
                            checked={isAction === ACTION.EDIT && requiredTakePicture}
                            name="takePicture" />
                        {t('dialog.orders_status.require_take_picture')}
                    </AvLabel>
                </AvGroup>
            </div>
        )
    }

    return (
        <Container>
            <HeaderWrapper>
                <HeadingTitle
                    type="normal"
                    label={t('dashboard.sidebar.submenu.orders_status')}
                />
                <AddIcon
                    onClick={() => handleOpenDialogAdd()}>
                    <Plus
                        color={Colors.dark}
                        style={{ cursor: 'pointer' }}
                        width={22}
                    />
                    <span>{t('common.create')}</span>
                </AddIcon>
            </HeaderWrapper>
            <FormWrapper>
                <NoteWrapper>
                    <ChatHelp
                        color={Colors.dark}
                        style={{ cursor: 'pointer' }}
                        width={22}
                    />
                    <span>{t('dialog.orders_status.note')}</span>
                </NoteWrapper>
                <DragAndDropWrapper>
                    <DragDropContext onDragEnd={(item) => onDragEnd(item)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {dadItems.map((item, index) => {
                                        const { required } = item;
                                        return (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <DragAndDropItemWrapper>
                                                            <OrdersStatusWrapper>
                                                                <div style={{ backgroundColor: item.color }}></div>
                                                                <span>{item.name}</span>
                                                            </OrdersStatusWrapper>
                                                            {!required && (
                                                                <div>
                                                                    <Edit2Outline
                                                                        color={Colors.dark}
                                                                        onClick={() => handleEdit(item)}
                                                                        style={{ cursor: 'pointer' }}
                                                                        width={25}
                                                                    />
                                                                    <Trash
                                                                        color={Colors.dark}
                                                                        onClick={() => handleDelete(item)}
                                                                        style={{ cursor: 'pointer' }}
                                                                        width={25}
                                                                    />
                                                                </div>
                                                            )}
                                                        </DragAndDropItemWrapper>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </DragAndDropWrapper>
            </FormWrapper>
            <DialogContent
                title={t('dialog.orders_status.title')}
                isOpen={isOpenDialog}
                centered
                onCancelAction={() => handleCancelDialog()}
                onSaveAction={(value) => handleSaveDialog(value)}>
                {renderAddOrdersStatusContent()}
            </DialogContent>
        </Container>
    )
}
