import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
    BoxImage,
    OrdersItem,
    OrdersInfo,
    OrdersInfoItem,
    OrdersInfoContainer,
    TotalValue,
    ButtonUpdate,
    BoxImageHidden,
    MoreInfoContainer
} from './styles';
// icons
import boxIcon from '~/assets/images/box_ic.png';
import boxIcon2 from '~/assets/images/box_ic.svg';
import successIcon from '~/assets/images/success_ic_dialog.svg';
import arrowDownIcon from '~/assets/images/arrow_down_ic.svg';
import { formatAddressString, VNDCurrencyFormatting } from '~/utils/helper';

function OrdersItemForEmployee(props) {

    const { t } = useTranslation();
    const { item, activeTab } = props;
    const {
        recipientName,
        recipientPhone,
        recipientAddress,
        recipientProvinceInfo,
        recipientDistrictInfo,
        recipientWardInfo,
        recipientAmountPayment,
        code,
        pickupName,
        pickupPhone,
        pickupAddress,
        pickupProvinceInfo,
        pickupDistrictInfo,
        pickupWardInfo,
        shipperInfo,
        statusInfo,
        id: ordersId
    } = item;
    const { fullName: shipperName } = shipperInfo;
    const { name: statusName, id: ordersStatusId } = statusInfo;

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const width = window.innerWidth;
        if (width > 600) {
            setIsOpen(true);
        }
    }, []);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <OrdersItem>
            <OrdersInfoContainer onClick={toggle}>
                <BoxImage src={boxIcon} />
                <OrdersInfo>
                    <OrdersInfoItem>
                        <div>{t('orders.orders_code')}:</div>
                        <div>{code}</div>
                        {activeTab === '2' && <div><img src={successIcon} /></div>}
                    </OrdersInfoItem>
                    <OrdersInfoItem>
                        <div>{t('orders.receiver')}:</div>
                        <div>{recipientName}</div>
                    </OrdersInfoItem>
                    <OrdersInfoItem>
                        <div>{t('orders.sdt')}:</div>
                        <a href={`tel:${recipientPhone}`}>{recipientPhone}</a>
                    </OrdersInfoItem>
                    <OrdersInfoItem>
                        <div>{t('common.address')}:</div>
                        <div>{formatAddressString(recipientProvinceInfo, recipientDistrictInfo, recipientWardInfo, recipientAddress)}</div>
                    </OrdersInfoItem>
                    <OrdersInfoItem>
                        <div>{t('orders.status')}:</div>
                        <div>{statusName}</div>
                    </OrdersInfoItem>
                </OrdersInfo>
                <img src={arrowDownIcon} />
            </OrdersInfoContainer>
            <TotalValue>
                <span>{t('orders.total_value')}: </span><span>{VNDCurrencyFormatting(recipientAmountPayment)}</span>
            </TotalValue>
            <Collapse isOpen={isOpen}>
                <MoreInfoContainer>
                    <OrdersInfoContainer onClick={toggle}>
                        <BoxImageHidden src={boxIcon2} />
                        <OrdersInfo>
                            <OrdersInfoItem>
                                <div>{t('orders.sender')}:</div>
                                <div>{pickupName}</div>
                            </OrdersInfoItem>
                            <OrdersInfoItem>
                                <div>{t('orders.sdt')}:</div>
                                <a href={`tel:${pickupPhone}`}>{pickupPhone}</a>
                            </OrdersInfoItem>
                            <OrdersInfoItem>
                                <div>{t('common.address')}:</div>
                                <div>{formatAddressString(pickupProvinceInfo, pickupDistrictInfo, pickupWardInfo, pickupAddress)}</div>
                            </OrdersInfoItem>
                            <OrdersInfoItem>
                                <div>{t('orders.shipper')}:</div>
                                <div>{shipperName}</div>
                            </OrdersInfoItem>
                        </OrdersInfo>
                    </OrdersInfoContainer>
                    <ButtonUpdate onClick={() => props?.handleUpdate({ ordersId, ordersStatusId })}>
                        <span>{t('common.update')}</span>
                    </ButtonUpdate>
                </MoreInfoContainer>
            </Collapse>
        </OrdersItem>
    )
}

export default OrdersItemForEmployee
