import React, { memo } from 'react';
import Flatpickr from 'react-flatpickr';
import { DateContainer, DateLabel, Image } from './styles';
import calendarIcon from '~/assets/images/calendar_ic.svg';
import RemakeAvField from '../RemakeAvField';
import { MessageError } from '../RemakeSelect/styles';

function DatePicker(props) {
    const { name, value, validate, label, isSubmited, disabled } = props;
    const { required: { value: isRequired, errorMessage } = {} } = validate || {};
    return (
        <DateContainer isError={isSubmited && !value} disabled={disabled}>
            {label && <DateLabel>
                {label}
                {isRequired && <span>*</span>}
            </DateLabel>
            }
            <Flatpickr
                {...props}
                value={value ? new Date(value) : value}
                onChange={props?.onChange}
            />
            <Image src={calendarIcon}/>
            <RemakeAvField
                height={'35px'}
                {...props}
                name={name || 'unName'}
                style={{
                    opacity: 0,
                    width: "100%",
                    height: 0,
                    position: "absolute",
                    zIndex: -1,
                }}
            />
            {isSubmited && !value && <MessageError>{errorMessage}</MessageError>}
        </DateContainer>
    )
}

export default memo(DatePicker);
