import produce from 'immer';
import { ACTION_TYPE } from '~/constants/actionTypes';

const INITIAL_STATE = {
    users: [],
    total: 0,
    isShowDialog: true,
    createUserData: {},
    userData: {},
    isUpdateUserSuccess: false,
};

const customerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPE.USER.GET_USER_SUCCESS:
            return produce(state, draft => {
                const { users = [], total = 0 } = action;
                draft.users = users;
                draft.total = total;
            });

        case ACTION_TYPE.USER.DELETE_USER_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                if (Object.keys(data).length === 0) {
                    draft.isShowDialog = false;
                }
            });
        case ACTION_TYPE.USER.DELETE_USER_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                draft.createUserData = data;
            });
        case ACTION_TYPE.USER.GET_USER_BY_ID_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                draft.userData = data;
            });
        case ACTION_TYPE.USER.CLEAR_GET_EMPLOYEE_ID:
            return produce(state, draft => {
                draft.userData = {};
            });
        case ACTION_TYPE.USER.UPDATE_USER_SUCCESS:
            return produce(state, draft => {
                draft.isUpdateUserSuccess = action.data;
            });

        default:
            return state;
    }
}

export default customerReducer;
