import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const Container = styled.div`
    margin: 10px 0px;
    overflow-y: scroll;
`;

export const Columns = styled.div`
    border: 2px solid #9598AE;
    width: 100%;
    display: flex;
    padding: 8px;
    border-bottom: 0px;
    border-radius: 10px 10px 0px 0px;
    background-color: #eeeeee;
    min-width: ${(props) => props.hasMinWidth ? '1200px' : 'auto'};
`;

export const ColumnItem = styled.div`
    span {
        font-weight: bold;
    }
`;

export const DataSource = styled.div`
    border: 2px solid #9598AE;
    width: 100%;
    display: flex;
    padding: 8px;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
    min-width: ${(props) => props.hasMinWidth ? '1200px' : 'auto'};
`;

export const ActionButtonWrapper = styled.div`
    height: 35px;
    display: flex;
    align-items: center;
    align-content: center;
`;

export const RowContentWrapper = styled.div`
    height: 35px;
    display: flex;
    align-items: center;
    align-content: center;
    border: 1px solid #eeeeee;
    border-width: 0px 0px 1px 0px;
`;

export const EmptyData = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    img {
        height: 70px;
        width: 70px;
    }

    span {
        margin-top: 15px;
    }
`;

export const Pagination = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
`;

export const Total = styled.div`
    position: absolute;
    left: 0px;
    display: flex;
    align-items: center;
    align-content: center;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 10px;
    position: relative;
`;

export const Arrow = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 28px;
    border: 1px solid #DFE0E8;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
`;

export const PaginationItem = styled.div`
    width: 30px;
    height: 24px;
    border-radius: 8px;
    border: 1px solid #DFE0E8;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    margin: 0px 3px;
`;

export const PaginationItemSelected = styled(PaginationItem)`
    background-color: ${Colors.primary};

    span {
        color: #ffffff;
    }
`;

