import React from 'react';
// components
import Container from '~/components/Container';
import Dashboard from '~/components/Dashboard';
import MyProfileComponent from '~/components/MyProfile';

export default function MyProfile() {
    return (
        <Container>
            <Dashboard>
                <MyProfileComponent />
            </Dashboard>
        </Container>
    );
}
