import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AvForm } from 'availity-reactstrap-validation';
import { useDispatch, useSelector } from 'react-redux';
// components
import Card from '~/components/Card';
import HeadingTitle from '~/components/HeadingTitle';
import PasswordInput from '~/components/PasswordInput';
import RemakeButton from '~/components/RemakeButton';
// styles
import { ButtonsContainer, RowContainer } from '~/components/ProfileInfo/styles';
import { FormContainer } from './styles';
// utils
import { history } from '~/utils/navigations';
import { isEmpty } from '~/utils/helper';
import { hashPassword } from '~/utils/securities';
import { handleGetCookies } from '~/utils/cookies';
// constant
import { COOKIE_KEY } from '~/constants/common';
// actions
import { changePassword } from '~/modules/auth/actions';

function ChangePassword(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userInfo = handleGetCookies(COOKIE_KEY.LOGIN);
    const { cpData } = useSelector(state => state.auth);
    const { id } = userInfo || {};
    let form = null;

    useEffect(() => {
            form && form.reset();
    }, [cpData])

    const handleSubmitForm = (event, errors, values) => {
        if (isEmpty(errors)) {
            const { oldPassword, newPassword } = values;
            const body = {
                oldPassword: hashPassword(oldPassword),
                password: hashPassword(newPassword),
                userId: id
            }
            dispatch(changePassword(body));
        }
    }

    return (
        <Card style={{ marginBottom: 20, padding: 25 }}>
            <HeadingTitle
                type="large"
                label={t('auth.change_password')}
            />
            <FormContainer>
                <AvForm onSubmit={handleSubmitForm} ref={c => (form = c)}>
                    <RowContainer>
                        <PasswordInput
                            height='35px'
                            name="oldPassword"
                            label={t('auth.old_password')}
                            placeholder={t('auth.old_password_required')}
                            validate={{
                                required: { value: true, errorMessage: t('auth.old_password_required') },
                                minLength: {
                                    value: 6,
                                    errorMessage: t('auth.sign_up.password_six_char'),
                                },
                            }}
                        />
                        <PasswordInput
                            height='35px'
                            name="newPassword"
                            label={t('auth.forgot_password.new_password')}
                            placeholder={t('auth.login.password_required_message')}
                            validate={{
                                required: { value: true, errorMessage: t('auth.login.password_required_message') },
                                minLength: {
                                    value: 6,
                                    errorMessage: t('auth.sign_up.password_six_char'),
                                },
                            }}
                        />
                        <PasswordInput
                            height='35px'
                            name="newPasswordConfirm"
                            label={t('auth.forgot_password.new_password_confirm')}
                            placeholder={t('auth.login.password_required_message')}
                            validate={{
                                required: { value: true, errorMessage: t('auth.login.password_required_message') },
                                minLength: {
                                    value: 6,
                                    errorMessage: t('auth.sign_up.password_six_char'),
                                },
                                match: {
                                    value: "newPassword",
                                    errorMessage: t('auth.sign_up.password_confirm_incorrect'),
                                },
                            }}
                        />
                    </RowContainer>
                    <ButtonsContainer>
                        <RemakeButton
                            label={t('common.go_back')}
                            type='button'
                            buttonStyles={{ marginRight: 10, background: '#C4C4C4' }}
                            onClick={() => history.goBack()}
                        />
                        <RemakeButton label={t('common.update')} type='primary' />
                    </ButtonsContainer>
                </AvForm>
            </FormContainer>
        </Card>
    )
}

export default ChangePassword
