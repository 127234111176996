import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
// styles
import { FormTitle, ParcelInfoContainer } from '~/components/Orders/OrdersInfoForm/styles';
import { RowContainer } from '~/components/Orders/PickupInfoForm/styles';
import { SelectValueContainer, RecipientAmountPayment, Title, NoteMessage } from './styles';
// components
import RemakeAvField from '~/components/RemakeAvField';
import RemakeAvRadioInput from '~/components/RemakeAvRadioInput';
// constant
import { SHIPPING_FEE_PAYMENT } from '~/constants/common';
// utils
import { isEmpty, VNDCurrencyFormatting } from '~/utils/helper';

function ServiceInfo(props) {

    const { t } = useTranslation();
    const { shippingFee, ordersInfoForm =  {}, isEdit } = props;
    const {
        shippingFeePayment,
        isCOD,
        isGuaranteed,
    } = ordersInfoForm;

    const [recipientAmountPayment, setRecipientAmountPayment] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [shippingFeeState, setShippingFeeState] = useState(0);
    const [isSenderPayment, setIsSenderPayment] = useState(true);

    const shippingFeePaymentOptions = [
        {
            label: t('orders.sender_pay'),
            value: SHIPPING_FEE_PAYMENT.SENDER_PAY
        },
        {
            label: t('orders.receiver_pay'),
            value: SHIPPING_FEE_PAYMENT.RECEIVER_PAY
        }
    ]

    const isCODOptions = [
        {
            label: t('orders.yes'),
            value: true
        },
        {
            label: t('orders.no'),
            value: false
        }
    ]

    useEffect(() => {
        if (!isEmpty(ordersInfoForm)) {
            const {
                totalValue,
                recipientAmountPayment
            } = ordersInfoForm;
            setTotalValue(totalValue);
            setRecipientAmountPayment(recipientAmountPayment);
        }
    }, [ordersInfoForm]);

    useEffect(() => {
        const recipientAmountPaymentCaculate = isSenderPayment ? parseFloat(totalValue) : (parseFloat(totalValue) + parseFloat(shippingFee));
        setRecipientAmountPayment(recipientAmountPaymentCaculate);
        setShippingFeeState(shippingFee);
    }, [shippingFee]);

    useEffect(() => {
        const recipientAmountPaymentCaculate = isSenderPayment ? parseFloat(totalValue) : (parseFloat(totalValue) + parseFloat(shippingFee));
        setRecipientAmountPayment(recipientAmountPaymentCaculate);
    }, [isSenderPayment]);

    const onChangeTotalValue = (event) => {
        const value = event.target?.value || 0;
        const recipientAmountPaymentCaculate = isSenderPayment ? parseFloat(value) : (parseFloat(value) + parseFloat(shippingFeeState));
        setRecipientAmountPayment(recipientAmountPaymentCaculate);
        setTotalValue(value);
    }

    const handleChangeShippingPaymentType = (event) => {
        const value = event.target?.value;
        if (!value) return;
        if (value === SHIPPING_FEE_PAYMENT.SENDER_PAY) {
            setIsSenderPayment(true);
        } else {
            setIsSenderPayment(false);
        }
    }

    return (
        <ParcelInfoContainer>
            <FormTitle>{t('orders.service_info')}</FormTitle>
            <RowContainer columns={'1.26fr 1fr'} marginTop={'0px'}>
                <SelectValueContainer>
                    <RemakeAvRadioInput
                        label={t('orders.shipping_fee_payment')}
                        name='shippingFeePayment'
                        labelStyle={{ width: 140 }}
                        options={shippingFeePaymentOptions}
                        defaultValue={SHIPPING_FEE_PAYMENT.SENDER_PAY}
                        value={shippingFeePayment}
                        disabled={isEdit}
                        onChange={handleChangeShippingPaymentType}
                    />
                    <RemakeAvRadioInput
                        label={t('orders.cod')}
                        labelStyle={{ width: 140 }}
                        name='isCOD'
                        options={isCODOptions}
                        defaultValue={true}
                        value={isCOD}
                        disabled={isEdit}
                    />
                    <RemakeAvRadioInput
                        label={t('orders.guaranteed')}
                        labelStyle={{ width: 140 }}
                        name='isGuaranteed'
                        options={isCODOptions}
                        defaultValue={true}
                        value={isGuaranteed}
                        disabled={isEdit}
                    />
                </SelectValueContainer>
                <RecipientAmountPayment>
                    <Title>{`${t('orders.recipient_amount_payment')}: `} <b>{VNDCurrencyFormatting(recipientAmountPayment || 0)}</b></Title>
                    <NoteMessage>{t('orders.service_info_message')}</NoteMessage>
                    <RemakeAvField
                        height={'35px'}
                        name='recipientAmountPayment'
                        value={recipientAmountPayment}
                        style={{
                            opacity: 0,
                            width: "100%",
                            height: 0,
                            position: "absolute"
                        }}
                    />
                </RecipientAmountPayment>
            </RowContainer>
            <RowContainer columns={'0.97fr 2fr'}>
                <RemakeAvField
                    labelStyle={{ fontWeight: '700', textTransform: 'uppercase' }}
                    height={'35px'}
                    name="totalValue"
                    label={t('orders.total_value')}
                    placeholder={t('orders.required.total_value')}
                    type="number"
                    value={totalValue}
                    onBlur={onChangeTotalValue}
                    disabled={isEdit}
                    isCurrency={true}
                    validate={{
                        required: { value: true, errorMessage: t('orders.required.total_value') },
                    }}
                />
            </RowContainer>
        </ParcelInfoContainer>
    )
}

export default memo(ServiceInfo)
