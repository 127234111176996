import React from 'react';
import {
    Container, Link404,
} from '~/components/PageNotFound/styles';
import NotFoundImage from '~/assets/images/404.svg';
import { useTranslation } from 'react-i18next';

export default function PageNotFound() {
    const { t } = useTranslation();
    return (
        <Container>
            <h1>404</h1>
            <img src={NotFoundImage} alt />
            <div>
                <h3>
                    {t('common.not_found.title')}
                </h3>
                <p>{t('common.not_found.description')}</p>
                <Link404 href="/" >{t('common.back_home')}</Link404>
            </div>
        </Container>
    )
}
