import React from 'react'
import { useTranslation } from 'react-i18next';
import { SETTINGS_KEY } from '~/constants/common';
import { ROUTE } from '~/constants/routes';
import { redirectTo } from '~/utils/navigations';
import { FooterContainer } from './styles'

function FooterComponent() {

    const { t } = useTranslation();

    const onRedireactToStaticPage = (key) => {
        redirectTo(`${ROUTE.SETTINGS.STATIC_PAGE_FOR_USER}/${key}`)
    }

    return (
        <FooterContainer>
            <div>{t('home.footer.text1')}</div>
            <div>
                <div onClick={() => onRedireactToStaticPage(SETTINGS_KEY.POLICY)}>{t('home.footer.policy')}</div>&nbsp;
                <div onClick={() => onRedireactToStaticPage(SETTINGS_KEY.TERMS_AND_CONDITIONS)}>{t('home.footer.term_and_conditions')}</div>&nbsp;
                <div onClick={() => onRedireactToStaticPage(SETTINGS_KEY.SECURITY_AND_PRIVACY)}>{t('home.footer.security_and_privacy')}</div>&nbsp;
                <div onClick={() => onRedireactToStaticPage(SETTINGS_KEY.SUPPORT)}>{t('home.footer.support')}</div>
            </div>
        </FooterContainer>
    )
}

export default FooterComponent
