import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// components
import ProfileInfoComponent from '~/components/ProfileInfo';

import { COOKIE_KEY } from '~/constants/common';
import { clearDataGetUserById, handleGetUserById, handleUpdateUser } from '~/modules/user/actions';
import { handleGetCookies } from '~/utils/cookies';

export default function MyProfileComponent() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userData = {}, isUpdateUserSuccess = false } = useSelector(state => state.user);
    const userInfo = handleGetCookies(COOKIE_KEY.LOGIN);
    const { id } = userInfo || {};

    useEffect(() => {
        if (id) {
            const params = {
                userId: id
            }
            dispatch(handleGetUserById(params))
        }
        return function cleanup() {
            dispatch(clearDataGetUserById());
        };
    }, []);

    const handleSubmit = (data) => {
        let body = {
            data,
            isProfile: true
        }
        if (id) {
            body.params = {
                userId: id
            }
            dispatch(handleUpdateUser(body));
        }
    }

    return (
        <div>
            <ProfileInfoComponent
                pageTitle={t('common.my_profile_user')}
                handleSubmit={handleSubmit}
                values={userData}
                isMyProfile={true}
                isUpdateUserSuccess={isUpdateUserSuccess}
            />
        </div>
    );
}
