import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const Container = styled.div`
    
`;

export const CreateButton = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    @media ${DEVICE_RESPONSIVE.mobile} {
        justify-content: flex-start;
        margin-bottom: 20px;
    }
`;

export const SearchContainer = styled.div`
    form {
        display: flex;
        align-items: center;
    }

    @media ${DEVICE_RESPONSIVE.mobile} {
        form {
            display: block;
        }
    }
`;

export const SearchButton = styled.div`
    margin-top: 25px;
    margin-left: 10px;
`;

export const RowSearchContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    color: ${Colors.dark};
    width: 91%;

    @media ${DEVICE_RESPONSIVE.mobile} {
        grid-template-columns: repeat(1, 1fr);
    }
`;
