import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FooterComponent from '~/components/Home/Footer';
import MobileTopHeader from '~/components/Home/MobileTopHeader';
import TopHeaderComponent from '~/components/Home/TopHeader';
import { StaticPageContainer, StaticPageTitle, StaticPageContent, StaticPageEmptyContent } from './styles';
import { clearSetting, getSettings } from '~/modules/settings/actions';
import { isEmpty } from '~/utils/helper';
import { EmptyData } from '~/components/Table/styles';
import EmptyDataIcon from '~/assets/images/empty_data.svg';
import { SETTINGS_KEY_FORMAT } from '~/constants/common';

function StaticPageForUser(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { match } = props;
    const { params: { key: settingKey } = {} } = match || {};
    const { settings = {} } = useSelector(state => state.settings);

    useEffect(() => {
        dispatch(getSettings());
        return function cleanup() {
            dispatch(clearSetting());
        };
    }, []);

    const getSettingByKey = () => {
        const settingSelected = settings.find(setting => setting?.key === SETTINGS_KEY_FORMAT[settingKey]);
        return settingSelected?.value;
    }

    const content = getSettingByKey(settingKey);

    return (
        <>
            <TopHeaderComponent />
            <MobileTopHeader />
            <StaticPageContainer>
                <StaticPageTitle>
                    {t(`static_page.${settingKey}`)}
                </StaticPageTitle>
                <StaticPageContent>
                    {!isEmpty(content) ? (
                        <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    ) : (
                        <StaticPageEmptyContent>
                            <EmptyData>
                                <img src={EmptyDataIcon} alt="" />
                                <span>{t('common.no_data')}</span>
                            </EmptyData>
                        </StaticPageEmptyContent>
                    )}

                </StaticPageContent>
            </StaticPageContainer>
            <FooterComponent />
        </>
    )
}

export default StaticPageForUser;
