import { ACTION_TYPE } from '~/constants/actionTypes';

const createOrders = (body) => {
    return {
        type: ACTION_TYPE.ORDERS.CREATE_ORDERS,
        body,
    };
}

const getOrders = (params) => {
    return {
        type: ACTION_TYPE.ORDERS.GET_ORDERS,
        params,
    };
}

const getOrdersSuccess = (items, total) => {
    return {
        type: ACTION_TYPE.ORDERS.GET_ORDERS_SUCCESS,
        items,
        total
    }
}

const createOrdersWithOther = (body) => {
    return {
        type: ACTION_TYPE.ORDERS.CREATE_WITH_OTHER,
        body,
    };
}

const createOrdersWithOtherSuccess = (data) => {
    return {
        type: ACTION_TYPE.ORDERS.CREATE_WITH_OTHER_SUCCESS,
        data,
    };
}

const getOrdersById = (params) => {
    return {
        type: ACTION_TYPE.ORDERS.GET_BY_ID,
        params,
    };
}

const getOrdersByIdSuccess = (data) => {
    return {
        type: ACTION_TYPE.ORDERS.GET_BY_ID_SUCCESS,
        data,
    };
}

const createOrdersEvent = (body) => {
    return {
        type: ACTION_TYPE.ORDERS.CREATE_EVENT,
        body,
    };
}

const createOrdersEventSuccess = (data) => {
    return {
        type: ACTION_TYPE.ORDERS.CREATE_EVENT_SUCCESS,
        data,
    };
}

const clearCreateOrdersEvent = () => {
    return {
        type: ACTION_TYPE.ORDERS.CLEAR_CREATE_EVENT,
    };
}

const clearOrdersById = () => {
    return {
        type: ACTION_TYPE.ORDERS.CLEAR_BY_ID,
    };
}

const clearCreateOrdersOther = () => {
    return {
        type: ACTION_TYPE.ORDERS.CLEAR_CREATE_WITH_OTHER,
    };
}

const updateOrders = (data) => {
    return {
        type: ACTION_TYPE.ORDERS.UPDATE,
        data,
    };
}

const deleteOrders = (params) => {
    return {
        type: ACTION_TYPE.ORDERS.DELETE,
        params,
    };
}

const getOrdersEventByOrdersCode = (params) => {
    return {
        type: ACTION_TYPE.ORDERS.GET_ORDERS_EVENT_BY_CODE,
        params,
    }
}

const getOrdersEventByOrdersCodeSuccess = (data, total) => {
    return {
        type: ACTION_TYPE.ORDERS.GET_ORDERS_EVENT_BY_CODE_SUCCESS,
        data,
        total
    }
}

const getShippingFee = (params) => {
    return {
        type: ACTION_TYPE.ORDERS.GET_SHIPPING_FEE,
        params,
    };
}

const getShippingFeeSuccess = (data) => {
    return {
        type: ACTION_TYPE.ORDERS.GET_SHIPPING_FEE_SUCCESS,
        data,
    };
}

const clearShippingFee = () => {
    return {
        type: ACTION_TYPE.ORDERS.CLEAR_SHIPPING_FEE,
    };
}

const clearOrdersEventsByCode = () => {
    return {
        type: ACTION_TYPE.ORDERS.CLEAR_ORDERS_EVENT_BY_CODE,
    };
}

export {
    createOrders,
    getOrders,
    getOrdersSuccess,
    getOrdersById,
    getOrdersByIdSuccess,
    clearOrdersById,
    updateOrders,
    deleteOrders,
    createOrdersWithOther,
    createOrdersWithOtherSuccess,
    clearCreateOrdersOther,
    createOrdersEvent,
    createOrdersEventSuccess,
    clearCreateOrdersEvent,
    getOrdersEventByOrdersCode,
    getOrdersEventByOrdersCodeSuccess,
    clearOrdersEventsByCode,
    getShippingFee,
    getShippingFeeSuccess,
    clearShippingFee
}
