
export function isEmpty(obj) {
    if (obj === undefined || obj === null) {
        return true;
    }
    let empty = Object.keys(obj);
    return empty.length === 0;
}

export function formatAddressData(data = []) {
    return Array.isArray(data) && data.map(item => {
        return {
            value: item?.id,
            label: item?.name
        }
    })
}

export function formatUsersData(data = []) {
    return Array.isArray(data) && data.map(item => {
        return {
            value: item?.id,
            label: item?.fullName
        }
    })
}

export function VNDCurrencyFormatting(value = 0) {
    return value.toLocaleString('it-IT', { style: 'currency', currency: 'VND' });
}

export function formatAddressString(province, district, ward, address) {
    const { name: provinceName = '' } = province || {};
    const { name: districtName = '' } = district || {};
    const { name: wardName = '' } = ward || {};
    return `
        ${address ? `${address}` : ''}${wardName ? `, ${wardName}` : ''}${districtName ? `, ${districtName}` : ''}${provinceName ? `, ${provinceName}` : ''}
    `;
}

export function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}

export const formatNumberWithZero = (value) => {
    const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const hasZero = arr.includes(value);
    return hasZero ? `0${value}` : value;
}
