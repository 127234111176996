import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const SearchContainer = styled.div`
    height: 113px;
    background:${Colors.primary};
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    background: #FFFFFF;
    box-shadow: 0px 1.5px 0px 2px rgba(205, 205, 205, 0.25);
    border-radius: 10px;
    width: 900px;
    position: absolute;
    bottom: -55px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    @media ${DEVICE_RESPONSIVE.desktop} {
        width: 768px;
    }
    @media ${DEVICE_RESPONSIVE.tablet} {
        width: 700px;
    }

    @media ${DEVICE_RESPONSIVE.mobile} {
        width: 90%;
        height: 70px;
        bottom: -35px;
        padding: 0px 12px;
    }

`;

export const SearchInput = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
`;

export const FormContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    #code {
        border: none;
        width: 350px;
        margin-top: 7px;

        &:focus {
            box-shadow: none;
        }
        &::placeholder {
            color: ${Colors.dark};
            font-size: 18px;
        }

        @media ${DEVICE_RESPONSIVE.mobile} {
            width: 120%;
            &::placeholder {
                font-size: 12px;
            }
        }
    }
`;

export const SearchElemnt = styled.div`
    @media ${DEVICE_RESPONSIVE.mobile} {
        display: none;
        button {
            height: 40px !important;
            width: 100px !important;
            span {
                font-size: 14px !important;
            }
        }
    }
`;

export const SearchElemntIcon = styled.div`
    display: none;
    @media ${DEVICE_RESPONSIVE.mobile} {
        display: block;
    }
`;

export const SearchResult = styled.div`
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
    top: 125px;
    border-radius: 4px;
    width: 100%;
    position: absolute;
    height: auto;
    left: 0;
    right: 0;
    padding: 24px;
    z-index: 9999999;
    img {
        float: right;
        cursor: pointer;
    }
    @media ${DEVICE_RESPONSIVE.mobile} {
        top: 80px;
    }
`;

export const OrdersCode = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: ${Colors.dark};
`;

export const OrdersStatus = styled.div`
    background: ${(props) => props.background ? props.background : '#EEEEEE'};
    border-radius: 30px;
    padding: 0px 10px;
    line-height: 21px;
    color: white;
    margin-left: 10px;
    font-weight: 500;
    @media ${DEVICE_RESPONSIVE.mobile} {
        width: fit-content;
        margin: 0 auto;
        margin-top: 5px;
    }
`;

export const ResultHeader = styled.div`
    display: flex;
    @media ${DEVICE_RESPONSIVE.mobile} {
        display: block;
    }
`;

export const OrdersProcessList = styled.div`
    margin-top: 16px;
    div:nth-child(1) .item-desc:before {
        background: #1CC461;
        box-shadow: rgba(27, 196, 97, 0.3) 0px 0px 0px 2px;
        border: 0 none;
        left: -16px;
    }
    div:nth-child(1) .item-desc, div:nth-child(1) .item-date {
        color: #352D97;
    }
    div:last-child .item-desc:after {
        display: none;
    }

`;

export const OrdersProcessItem = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 20px;
    color: #959ba4;
`;

export const ItemDate = styled.div`
    margin-right: 16px;
    width: 25%;
    text-transform: capitalize;
    text-align: left;
    @media ${DEVICE_RESPONSIVE.mobile} {
        margin-right: 10px;
        width: 80px;
    }
`;

export const ItemDes = styled.div`
    position: relative;
    margin-left: 16px;
    text-align: start;
    width: 75%;
    line-height: 18px;

    &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        border: 2px solid #B5BBC6;
        top: 6px;
        left: -16px;
        border-radius: 50%;
        background: #FFF;
    }
    &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        top: 23px;
        left: -11px;
        border-radius: 2px;
        background: #B5BBC6;
    }
    div:nth-child(1) {
        margin-left: 16px;
    }
`;

export const EmptyResult = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
`;

export const SearchIcon = styled.div`
    @media ${DEVICE_RESPONSIVE.mobile} {
        display: none;
    }
`;
