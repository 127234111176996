import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';

export const Title = styled.span`
    
`;

export const HeadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${DEVICE_RESPONSIVE.mobile} {
        display: ${(props) => props.isBlock ? 'block' : 'flex'};
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    @media ${DEVICE_RESPONSIVE.mobile} {
        display: block;
    }
`;