import React from 'react';
import { Container } from '~/pages/Customer/List/styles';
import Dashboard from '~/components/Dashboard';
import CustomerAdd from '~/components/Customer/Add';

export default function CustomerAddPage(props) {

    return (
        <Container>
            <Dashboard>
                <CustomerAdd {...props}/>
            </Dashboard>
        </Container>
    );
}
