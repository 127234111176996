import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ScrollAnimation from 'react-animate-on-scroll';
import * as dayjs from 'dayjs';
import vi from 'dayjs/locale/vi';
import { useDispatch, useSelector } from 'react-redux';
import {
    SearchContainer,
    FormContainer,
    SearchElemnt,
    SearchElemntIcon,
    SearchResult,
    SearchInput,
    OrdersCode,
    OrdersStatus,
    ResultHeader,
    OrdersProcessList,
    OrdersProcessItem,
    ItemDate,
    ItemDes,
    EmptyResult,
    SearchIcon
} from './styles'
// componetns
import RemakeButton from '~/components/RemakeButton';
import Icon from '~/components/Icon';
// icons
import closeIcon from '~/assets/images/close_ic.svg';
import emptySearchIcon from '~/assets/images/empty_search_ic.svg';
// actions
import { clearOrdersEventsByCode, getOrdersEventByOrdersCode } from '~/modules/orders/actions';
import { DATE_FORMAT } from '~/constants/common';
import { formatAddressString, isEmpty } from '~/utils/helper';
import { Colors } from '~/constants/styles';

function SearchComponent() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { ordersEvents = [] } = useSelector(state => state.orders);

    const [codeSearch, setCodeSearch] = useState('');
    const [isshowSearch, setIsShowSearch] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (ordersEvents) {
            setIsShowSearch(true);
        } else {
            setIsShowSearch(false);
        }
    }, [ordersEvents]);

    const handleClickOutside = (event) => {
        if (wrapperRef?.current && !wrapperRef?.current.contains(event.target)) {
            handleClose();
        }
    }

    const handleSearch = (event, errors, values) => {
        const { code } = values;
        if (!code) return;
        const params = {
            code
        }
        setCodeSearch(code);
        dispatch(getOrdersEventByOrdersCode(params));
    }

    const handleClose = () => {
        setCodeSearch('');
        setIsShowSearch(false);
        dispatch(clearOrdersEventsByCode());
    }

    const renderOrdersProcessItem = () => {
        return ordersEvents.map(item => {
            const { statusInfo, provinceInfo, districtInfo, wardInfo, address, createdAt } = item;
            const { name: statusName } = statusInfo;

            return (
                <OrdersProcessItem>
                    <ItemDate className='item-date'>{dayjs(createdAt).locale(vi).format(DATE_FORMAT.FULL_DATE_TIME)}</ItemDate>
                    <ItemDes className='item-desc'>
                        <div>{`[${statusName}] - ${formatAddressString(provinceInfo, districtInfo, wardInfo, address)}`}</div>
                    </ItemDes>
                </OrdersProcessItem>
            )
        })
    }

    const renderIcon = (color) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                    fill={color}
                    d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"
                >
                </path>
            </svg>
        )
    }

    return (
        <ScrollAnimation animateIn="animate__zoomIn" duration={1} animateOnce>
            <AvForm onSubmit={handleSearch}>
                <SearchContainer>
                    <SearchInput>
                        <FormContainer>
                            <SearchIcon>
                                {renderIcon(Colors.dark)}
                            </SearchIcon>
                            <AvField name="code" placeholder={t('home.search.input_order_info')} type="text" />
                        </FormContainer>
                        <SearchElemnt>
                            <RemakeButton
                                type="primary"
                                label={t('common.search')}
                                labelStyles={{ fontSize: 20, fontWeight: 600 }}
                                buttonStyles={{ height: 60, width: 170, borderRadius: 10 }}
                            />
                        </SearchElemnt>
                        <SearchElemntIcon>
                            <RemakeButton
                                type="primary"
                                label={renderIcon('#ffffff')}
                                labelStyles={{ fontSize: 20, fontWeight: 600 }}
                                buttonStyles={{ height: 50, width: 50, borderRadius: 10 }}
                            />
                        </SearchElemntIcon>
                    </SearchInput>
                    {isshowSearch && (
                        <SearchResult ref={wrapperRef}>
                            <img src={closeIcon} alt='' onClick={handleClose} />
                            <ResultHeader>
                                <OrdersCode>{`${t('orders.orders_code')}: ${codeSearch}`}</OrdersCode>
                                {ordersEvents?.[0]?.statusInfo?.name && <OrdersStatus background={ordersEvents?.[0]?.statusInfo?.color}>{ordersEvents?.[0]?.statusInfo?.name}</OrdersStatus>}
                            </ResultHeader>
                            {!isEmpty(ordersEvents) ?
                                (
                                    <OrdersProcessList>
                                        {renderOrdersProcessItem()}
                                    </OrdersProcessList>
                                ) : (
                                    <EmptyResult>
                                        <img src={emptySearchIcon} alt='' />
                                        <p>{t('home.empty_result')}</p>
                                    </EmptyResult>
                                )}
                        </SearchResult>
                    )}
                </SearchContainer>
            </AvForm>
        </ScrollAnimation>
    )
}

export default SearchComponent
