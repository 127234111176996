import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
// styles
import { DialogContainer, DialogContent } from './styles';
import { isEmpty } from '~/utils/helper';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';

function DialogMessage(props) {
    const { t } = useTranslation();
    const {
        width = 350,
        leftLabel = t('common.close'),
        rightLabel = t('common.save'),
        isOpen,
        onCancelAction,
        onSaveAction,
        title,
        children,
        centered
    } = props;

    const handleSave = (event, errors, values) => {
        if (isEmpty(errors)) {
            onSaveAction && onSaveAction(values);
        }
    }

    return (
        <DialogContainer>
            <Modal
                isOpen={isOpen}
                style={{ width }}
                className='dialogComponent'
                centered={centered}
            >
                <AvForm onSubmit={handleSave}>
                    <DialogContent>
                        <ModalHeader>
                            {title}
                        </ModalHeader>
                        <ModalBody style={{ marginTop: 0 }}>
                            {children}
                        </ModalBody>
                        {(onCancelAction && onSaveAction) && (
                            <ModalFooter>
                                <Button onClick={onCancelAction} type="button" className='leftButton'>{leftLabel}</Button>
                                <Button className='rightButton'>{rightLabel}</Button>
                            </ModalFooter>
                        )}
                    </DialogContent>
                </AvForm>
            </Modal>
        </DialogContainer>
    )
}

export default DialogMessage
