import React, { useState, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import Barcode from 'react-barcode';
import QRCode from "react-qr-code";
import DialogComponent from '~/components/DialogComponent';
import {
    Container,
    RowOne,
    ColumnOne,
    Image,
    ColumnTwo,
    ColumnThree,
    RowTwo,
    RowTwoColumnOne,
    RowThree,
    RowThreeColumnOne,
    RowThreeColumnTwo,
    RowThreeColumnThree,
    RowTwoColumnOneRowOne,
    RowTwoColumnOneRowTwo,
    RowTwoColumnOneRowTwoColumnOne,
    RowTwoColumnOneRowTwoColumnTwo,
    RowTwoColumnOneRowTwoColumnThree,
    RowTwoColumnOneRowOneColumnOne,
    RowTwoColumnOneRowOneColumnTwo,
    RowTwoColumnOneRowOneColumnThree,
    Phone, Date,
    SenderText,
    SenderName,
    SenderAddress,
    ContentText,
    AmountText,
    DateYear
} from './styles';
import logo_auth from '~/assets/images/auth/logo_auth.svg';
import RemakeButtonNotSubmit from '~/components/RemakeButtonNotSubmit';
import { addressFormat } from '~/utils/formats';
import { VNDCurrencyFormatting } from '~/utils/helper';

function OrdersPrint(props) {

    const { ordersData, isOpenDialog, handleCloseDialog } = props;

    const {
        pickupName,
        recipientName,
        pickupProvince,
        pickupDistrict,
        pickupWard,
        pickupAddress,
        recipientProvince,
        recipientDistrict,
        recipientWard,
        recipientAddress,
        recipientAmountPayment,
        code,
        ordersName
    } = ordersData;

    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation();

    const componentRef = useRef();

    useEffect(() => {
        setIsOpen(isOpenDialog);
    }, [isOpenDialog]);


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const toggle = () => {
        setIsOpen(!isOpen);
        handleCloseDialog();
    }

    return (
        <DialogComponent width={900} isOpen={isOpen} toggle={toggle}>
            <Container ref={componentRef}>
                <RowOne>
                    <ColumnOne>
                        <Image src={logo_auth}></Image>
                    </ColumnOne>
                    <ColumnTwo>
                        <Barcode height={80} value={code}/>
                    </ColumnTwo>
                    <ColumnThree>
                        {/* <Image src={logo_auth}></Image> */}
                    </ColumnThree>
                </RowOne>
                <RowTwo>
                    <RowTwoColumnOne>

                        <RowTwoColumnOneRowOne>
                            <RowTwoColumnOneRowOneColumnOne>
                                <SenderText>{t('orders.sender')}</SenderText>
                                <SenderName>{pickupName}</SenderName>
                                <SenderAddress>{addressFormat(pickupProvince?.label, pickupDistrict?.label, pickupWard?.label, pickupAddress)}</SenderAddress>
                            </RowTwoColumnOneRowOneColumnOne>

                            <RowTwoColumnOneRowOneColumnTwo>
                                <SenderText>{t('orders.receiver')}</SenderText>
                                <SenderName>{recipientName}</SenderName>
                                <SenderAddress>{addressFormat(recipientProvince?.label, recipientDistrict?.label, recipientWard?.label, recipientAddress)}</SenderAddress>
                            </RowTwoColumnOneRowOneColumnTwo>

                            <RowTwoColumnOneRowOneColumnThree>
                                <Phone>{t('print.phone')}</Phone>
                                <Date>{t('print.date')}</Date>
                            </RowTwoColumnOneRowOneColumnThree>
                        </RowTwoColumnOneRowOne>

                        <RowTwoColumnOneRowTwo>
                            <RowTwoColumnOneRowTwoColumnOne>
                                <SenderText>{t('static_page.content')}</SenderText>
                                <ContentText>{ordersName}</ContentText>
                            </RowTwoColumnOneRowTwoColumnOne>

                            <RowTwoColumnOneRowTwoColumnTwo>
                                <SenderText>{t('print.amount')}</SenderText>
                                <AmountText>{VNDCurrencyFormatting(recipientAmountPayment)}</AmountText>
                            </RowTwoColumnOneRowTwoColumnTwo>

                            <RowTwoColumnOneRowTwoColumnThree />

                        </RowTwoColumnOneRowTwo>

                    </RowTwoColumnOne>
                </RowTwo>
                <RowThree>
                    <RowThreeColumnOne>
                        <SenderText>{t('print.instruction')}</SenderText>
                    </RowThreeColumnOne>
                    <RowThreeColumnTwo>
                        <DateYear>{t('print.date_full')}</DateYear>
                        <SenderAddress style={{ marginTop: 8 }}>{t('orders.receiver')}</SenderAddress>
                    </RowThreeColumnTwo>
                    <RowThreeColumnThree>
                        <QRCode value="https://vivuship.vn" size={128} />
                    </RowThreeColumnThree>
                </RowThree>
            </Container>
            <RemakeButtonNotSubmit
                label={t('common.print')}
                type='primary'
                onClick={handlePrint}
                buttonStyles={{ float: 'right', marginTop: 10 }}
            />
        </DialogComponent>
    )
}

export default OrdersPrint;
