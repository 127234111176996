import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';

export const Container = styled.div`
    width: ${(props) => props?.width ? props?.width : '90%' };
    padding: 20px;
    background-color: #EEEEEE;

    @media only screen and (max-width: 1500px) {
        width: 84%;
    }

    @media only screen and (max-width: 1400px) {
        width: 83%;
    }

    @media only screen and (max-width: 1300px) {
        width: 82%;
    }

    @media only screen and (max-width: 1200px) {
        width: 80%;
    }

    @media ${DEVICE_RESPONSIVE.mobile} {
        width: 100%;
    }
`;