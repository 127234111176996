import React from 'react';
import { Container } from '~/components/RemakeButton/styles';
import { Colors } from '~/constants/styles';

export default function RemakeButton(props) {

    const { buttonStyles = {}, labelStyles = {}, onClick, type } = props;

    const handleGetPropertiesStyleByType = () => {
        switch (props.type) {
            case 'primary':
                return {
                    backgroundColor: Colors.primary,
                }

            case 'dark':
                return {
                    backgroundColor: '#C4C4C4',
                }
            case 'light':
                return {
                    backgroundColor: '#FFFFFF',
                }

            default:
                return {
                    backgroundColor: Colors.primary,
                }
        }
    }

    return (
        <Container type={type} onClick={onClick} style={{...handleGetPropertiesStyleByType(), ...buttonStyles}}>
            <span style={labelStyles}>
                {props.label}
            </span>
        </Container>
    )
}
