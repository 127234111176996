import React, { useEffect, memo, useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// components
import PickupInfoForm from '../PickupInfoForm';
import OrdersInfoForm from '../OrdersInfoForm';
import RemakeButton from '~/components/RemakeButton';
import HeadingTitle from '~/components/HeadingTitle';
// styles
import { ButtonsContainer } from '~/components/ProfileInfo/styles';
// utils
import { isEmpty } from '~/utils/helper';
// actions
import { createOrdersWithOther, getShippingFee } from '~/modules/orders/actions';
import { Colors } from '~/constants/styles';

function AddOrdersForMobile(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { shippingFeeData = {} } = useSelector(state => state.orders);
    const { isCreateOrdersWithoutAuth = false } = props;

    var [pickupProvinceSelected, setPickupProvinceSelected] = useState({});
    var [pickupDistrictSelected, setPickupDistrictSelected] = useState({});
    var [recipientProvinceSelected, setRecipientProvinceSelected] = useState({});
    var [recipientDistrictSelected, setRecipientDistrictSelected] = useState({});
    var [weightSelected, setWeightSelected] = useState(0);
    var [isSubmited, setIsSubmited] = useState(false);
    const [step, setStep] = useState(1);

    useEffect(() => {
        handleCallApiGetShippingFee();
    }, [
        pickupProvinceSelected,
        pickupDistrictSelected,
        recipientProvinceSelected,
        recipientDistrictSelected,
        weightSelected
    ]);

    const handleSubmitForm = (event, errors, values) => {
        setIsSubmited(true);
        handleRedirectToFieldsError(errors);
        if (isEmpty(errors)) {
            const payloadBody = {
                ...values,
                isPartner: false
            }
            dispatch(createOrdersWithOther(payloadBody));
        }
    }

    const handleRedirectToFieldsError = (errors) => {
        const step1Form = ["pickupName", "pickupPhone", "pickupPostalCode", "pickupProvince", "pickupDistrict", "pickupWard", "email", "pickupAddress", "pickupDate"];
        const step2Form = ["recipientName", "recipientPhone", "recipientPostalCode", "recipientProvince", "pickupDistrict", "recipientDistrict", "recipientWard", "recipientAddress"];
        const step3Form = ["weight", "ordersName", "ordersQuantity"];
        errors.every(element => {
            if (step1Form.includes(element)) {
                setStep(1);
                return;
            }
            if (step2Form.includes(element)) {
                setStep(2);
                return;
            }
            if (step3Form.includes(element)) {
                setStep(3);
                return;
            }
        });
    }

    const handleCallApiGetShippingFee = () => {
        if (
            !isEmpty(pickupProvinceSelected) &&
            !isEmpty(pickupDistrictSelected) &&
            !isEmpty(recipientProvinceSelected) &&
            !isEmpty(recipientDistrictSelected)
        ) {
            const { label: pickupProvinceName } = pickupProvinceSelected;
            const { label: pickupDistrictName } = pickupDistrictSelected;
            const { label: recipientProvinceName } = recipientProvinceSelected;
            const { label: recipientDistrictName } = recipientDistrictSelected;
            const params = {
                pick_province: pickupProvinceName,
                pick_district: pickupDistrictName,
                province: recipientProvinceName,
                district: recipientDistrictName,
                weight: weightSelected,
                transport: 'road'
            }
            dispatch(getShippingFee(params));
        }
    }

    const onChangePickupProvince = (province) => {
        setPickupProvinceSelected(province);
    }

    const onChangePickupDistrict = (district) => {
        setPickupDistrictSelected(district)
    }

    const onChangeRecipientProvince = (province) => {
        setRecipientProvinceSelected(province);
    }

    const onChangeRecipientDistrict = (district) => {
        setRecipientDistrictSelected(district)
    }

    const onBlurWeight = (event) => {
        const weight = event?.target?.value;
        const weightParseToGam = (parseFloat(weight) * 1000);
        setWeightSelected(weightParseToGam);
    }

    const handleBackStep = () => {
        setStep(step - 1);
    }

    const handleNextStep = () => {
        setStep(step + 1);
    }

    return (
        <div>
            <HeadingTitle
                type="large"
                label={t('orders.create')}
            >
            </HeadingTitle>
            <AvForm onSubmit={handleSubmitForm}>
                <PickupInfoForm
                    isCreateOrdersWithoutAuth={isCreateOrdersWithoutAuth}
                    onChangePickupProvince={onChangePickupProvince}
                    onChangePickupDistrict={onChangePickupDistrict}
                    isSubmited={isSubmited}
                    step={step}
                    isFullDataSelect={true}
                />
                <OrdersInfoForm
                    shippingFeeData={shippingFeeData}
                    onChangeRecipientProvince={onChangeRecipientProvince}
                    onChangeRecipientDistrict={onChangeRecipientDistrict}
                    onBlurWeight={onBlurWeight}
                    isSubmited={isSubmited && step === 2}
                    step={step}
                    isFullDataSelect={true}
                    isCreateOrdersWithoutAuth={isCreateOrdersWithoutAuth}
                />
                <ButtonsContainer isShow={step === 4}>
                    <RemakeButton
                        label={t('common.back')}
                        type='button'
                        buttonStyles={{ marginRight: 10, background: '#C4C4C4' }}
                        onClick={handleBackStep}
                    />
                    <RemakeButton
                        label={t('common.create_orders')}
                        type='primary'
                    />
                </ButtonsContainer>
                <ButtonsContainer isShow={step < 4}>
                    {step > 1 && (
                        <RemakeButton
                            label={t('common.back')}
                            type='button'
                            buttonStyles={{ marginRight: 10, background: '#C4C4C4' }}
                            onClick={handleBackStep}
                        />
                    )}
                    <RemakeButton
                        label={t('common.next')}
                        type='button'
                        buttonStyles={{ background: Colors.primary }}
                        onClick={handleNextStep}
                    />
                </ButtonsContainer>
            </AvForm>
        </div>
    )
}

export default memo(AddOrdersForMobile);
