import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const CreateOrderContainer = styled.div`
    height: 250px;
    background:${Colors.primary};
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const OrderTitle = styled.div`
    color: #ffffff;
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 32px;

    @media ${DEVICE_RESPONSIVE.mobile} {
        font-size: 22px;
    }
`;