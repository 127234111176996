import React, { useState } from 'react';
import {
    Container,
    Columns,
    ColumnItem,
    DataSource,
    ActionButtonWrapper,
    RowContentWrapper,
    EmptyData,
    Footer,
    Pagination,
    Total,
    Arrow,
    PaginationItem,
    PaginationItemSelected
} from '~/components/Table/styles';
import { useTranslation } from 'react-i18next';
import ActionButton from '~/components/ActionButton';
import EmptyDataIcon from '~/assets/images/empty_data.svg';
import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort';
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';

export default function Table(props) {
    const { t } = useTranslation();
    let [paginationSelectedIndex, setPaginationSelectedIndex] = useState(1);

    const { columns = [], dataSource = [], totalRecord = 0, hasMinWidth = true } = props;
    let columnsTemp = [...columns];
    let dataSourceTemp = [...dataSource];

    // let paginationNumber = (totalRecord / (props.recordNumberOnPage && props.recordNumberOnPage))
    //     + ((totalRecord !== 1 && props.recordNumberOnPage !== 1 && totalRecord !== props.recordNumberOnPage) && totalRecord % 2);

    // let paginationNumberTemp = paginationNumber;
    // var paginationNumberResult = parseInt(paginationNumberTemp);
    // paginationNumberTemp -= paginationNumberResult;

    // if (paginationNumberTemp < 0.5) {
    //     paginationNumber = Math.floor(paginationNumber);
    // } else {
    //     paginationNumber = Math.ceil(paginationNumber);
    // }
    let paginationNumber = Math.ceil((totalRecord / (props.recordNumberOnPage && props.recordNumberOnPage)));

    const renderColumns = () => {
        return columnsTemp.map(item => {
            return (
                <ColumnItem style={{ width: item.width }}>
                    <span>{item.title}</span>
                </ColumnItem>
            )
        })
    }

    const renderDataSource = () => {
        if (dataSourceTemp.length) {
            let dataSourceFormat = [];
            let keys = columnsTemp.map(columnsTempItem => {
                return columnsTempItem['dataIndex'];
            })

            dataSourceTemp.map((dataItem, i) => {
                if (keys.length && dataSourceFormat.length === 0) {
                    keys.forEach(element => {
                        dataSourceFormat.push([]);
                    });
                }
                keys.map((keyItem, index) => {
                    dataSourceFormat[index].push(dataItem[keyItem]);
                })
            })

            return columnsTemp.map((columnsTempItem, columnsTempItemIndex) => {
                return (
                    <div style={{ width: columnsTempItem.width }}>
                        {dataSourceFormat[columnsTempItemIndex].map((dataSourceFormatItem, dataSourceFormatItemIndex) => {
                            if (columnsTempItem.key === 'action') {
                                return (
                                    <ActionButtonWrapper>
                                        <ActionButton
                                            hasDelete={props.onClickDelete}
                                            onClickEdit={() => props.onClickEdit(dataSource[dataSourceFormatItemIndex])}
                                            onClickDelete={() => props.onClickDelete(dataSource[dataSourceFormatItemIndex])}
                                        />
                                    </ActionButtonWrapper>
                                )
                            } else if (columnsTempItem.key === 'no') {
                                return (
                                    <RowContentWrapper>
                                        <span>
                                            {dataSourceFormatItemIndex + 1}
                                        </span>
                                    </RowContentWrapper>
                                )
                            } else {
                                return (
                                    <RowContentWrapper>
                                        <span>
                                            {dataSourceFormatItem ? dataSourceFormatItem : t('common.no_data')}
                                        </span>
                                    </RowContentWrapper>
                                )
                            }
                        })}
                    </div>
                )
            })
        } else {
            return (
                <EmptyData>
                    <img src={EmptyDataIcon} alt="" />
                    <span>{t('common.no_data')}</span>
                </EmptyData>
            )
        }

    }

    const handleClickPagination = (item) => {
        props.onClickPagination && props.onClickPagination({ recordNumberOnPage: props.recordNumberOnPage, paginationSelectedIndex: item });
        setPaginationSelectedIndex(item);
    }

    const handleClickNextPrevPagination = (type) => {
        if (type === 'prev') {
            if (paginationSelectedIndex > 1) {
                props.onClickPagination && props.onClickPagination({ recordNumberOnPage: props.recordNumberOnPage, paginationSelectedIndex: paginationSelectedIndex - 1 });
                setPaginationSelectedIndex(paginationSelectedIndex - 1);
            }
        } else {
            if (paginationSelectedIndex < paginationNumber - 1) {
                props.onClickPagination && props.onClickPagination({ recordNumberOnPage: props.recordNumberOnPage, paginationSelectedIndex: paginationSelectedIndex + 1 });
                setPaginationSelectedIndex(paginationSelectedIndex + 1);
            }
        }
    }

    const renderPaginationItem = () => {
        let items = [];
        for (let index = 1; index <= paginationNumber; index++) {
            items.push(index);
        }

        return items.length ? items.map(item => {

            return item === paginationSelectedIndex ? (
                <PaginationItemSelected onClick={() => handleClickPagination(item)}>
                    <span>{item}</span>
                </PaginationItemSelected>
            ) : (
                <PaginationItem onClick={() => handleClickPagination(item)}>
                    <span>{item}</span>
                </PaginationItem>
            )
        }) : undefined;
    }

    return (
        <Container>
            <Columns hasMinWidth={hasMinWidth}>
                {renderColumns()}
            </Columns>
            <DataSource hasMinWidth={hasMinWidth}>
                {renderDataSource()}
            </DataSource>
            <Footer>
                <Total>
                    <span>{t('common.total')}:</span>
                    <span>{totalRecord}</span>
                </Total>
                <Pagination>
                    <Arrow onClick={() => handleClickNextPrevPagination('prev')}>
                        <ArrowLeftShort color={'#DFE0E8'} />
                    </Arrow>
                    {renderPaginationItem()}
                    <Arrow onClick={() => handleClickNextPrevPagination('next')}>
                        <ArrowRightShort color={'#DFE0E8'} />
                    </Arrow>
                </Pagination>
            </Footer>
        </Container>
    )
}
