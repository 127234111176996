import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import HeadingTitle from '~/components/HeadingTitle';
import { ReportContainer, ReportItem, ReportList, LeftContent, RightContent } from '~/components/Reports/Employee/styles';
import { UserAstronaut } from '@styled-icons/fa-solid/UserAstronaut';
import { Colors } from '~/constants/styles';
import { getReportsByEmployee } from '~/modules/report/actions';
import { formatNumberWithZero } from '~/utils/helper';
import { USER_STATUS_TYPE } from '~/constants/common';
import { ROUTE } from '~/constants/routes';

function EmployeeReport() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { reportsEmployeeData = {} } = useSelector(state => state.reports);
    const { totalEmployee, totalActiveEmployee, totalInactiveEmployee, totalWaitingVerifyEmployee } = reportsEmployeeData;

    useEffect(() => {
        dispatch(getReportsByEmployee())
    }, []);

    const handleViewDetail = (status) => {
        let route = '';
        if (!status) {
            route = `${ROUTE.EMPLOYEE.LIST}`;
        } else {
            route = `${ROUTE.EMPLOYEE.LIST}?status=${status}`;
        }
        window.open(route);
    }

    return (
        <ReportContainer>
            <HeadingTitle
                type="large"
                label={t('common.employee.name')}
            />
            <ReportList columns={3}>
                <ReportItem onClick={() => handleViewDetail(null)}>
                    <LeftContent>
                        <span>{t('report.employee.total')}</span>
                        <h3>{formatNumberWithZero(totalEmployee)}</h3>
                    </LeftContent>
                    <RightContent>
                        <UserAstronaut color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
                <ReportItem onClick={() => handleViewDetail(USER_STATUS_TYPE.ACTIVE)}>
                    <LeftContent>
                        <span>{t('report.employee.toltal_active')}</span>
                        <h3>{formatNumberWithZero(totalActiveEmployee)}</h3>
                    </LeftContent>
                    <RightContent>
                        <UserAstronaut color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
                <ReportItem onClick={() => handleViewDetail(USER_STATUS_TYPE.WAITING_VERIFY)}>
                    <LeftContent>
                        <span>{t('report.employee.toltal_waiting_verify')}</span>
                        <h3>{formatNumberWithZero(totalWaitingVerifyEmployee)}</h3>
                    </LeftContent>
                    <RightContent>
                        <UserAstronaut color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
                <ReportItem onClick={() => handleViewDetail(USER_STATUS_TYPE.INACTIVE)}>
                    <LeftContent>
                        <span>{t('report.employee.toltal_inactive')}</span>
                        <h3>{formatNumberWithZero(totalInactiveEmployee)}</h3>
                    </LeftContent>
                    <RightContent>
                        <UserAstronaut color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
            </ReportList>
        </ReportContainer>
    )
}

export default EmployeeReport
