import React from 'react';
import { StatusContainer } from './styles';

function StatusComponent(props) {
    if (!props.name) return null;
    return (
        <StatusContainer
            background={props?.background}
            borderColor={props?.background}
            marginLeft={props?.marginLeft}
        >
            <span>{props?.name}</span>
        </StatusContainer>
    )
}

export default StatusComponent;
