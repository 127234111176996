import React from 'react';
import {
    Container,
} from '~/components/ActionButton/styles';
import { Edit2Outline } from '@styled-icons/evaicons-outline/Edit2Outline';
import { Trash } from '@styled-icons/heroicons-outline/Trash';
import { Colors } from '~/constants/styles';

export default function ActionButton(props) {

    const handleClickEdit = () => {
        props.onClickEdit && props.onClickEdit();
    }

    const handleClickDelete = () => {
        props.onClickDelete && props.onClickDelete();
    }

    return (
        <Container>
            <Edit2Outline
                color={Colors.dark}
                onClick={() => handleClickEdit()}
                style={{ cursor: 'pointer' }}
            />
            {props.hasDelete && (
                <Trash
                    color={Colors.dark}
                    onClick={() => handleClickDelete()}
                    style={{ marginBottom: 2, cursor: 'pointer' }}
                />
            )}
        </Container>
    )
}
