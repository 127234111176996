import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const MobileTopHeaderContainer = styled.div`
    height: 50px;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
    background: ${Colors.primary};
    @media ${DEVICE_RESPONSIVE.mobile} {
        display: block;
    }
    .nav-bar {
        position: fixed;
        background-color: #ffffff;
        top: 0;
        left: -50rem;
        height: 100vh;
        width: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: 0.5s ease-out;
        z-index: 999999;
        flex-direction: column;
    }
    .toggle {
        left: 0;
        box-shadow: 1px 0 15px 2px rgba(0, 0, 0, 0.4);
    }
    .toggle-menu {
        background-color: ${Colors.primary};
        position: fixed;
        top: 5px;
        left: 8px;
        width: 50px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0.2rem 0.5rem;
        border-radius: 0.5rem;
        cursor: pointer;
    }
    .line {
        width: 100%;
        height: 4px;
        border-radius:5px;
        background-color: #fff;
        transition: 0.2s ease-out;
    }
    .toggle .line1 {
        background-color: ${Colors.primary};
        transform: scale(0.9) rotateZ(-45deg) translate(-6px, 6px);
    }
    .toggle .line2 {
        display: none;
    }
    .toggle .line3 {
        background-color: ${Colors.primary};
        transform: scale(0.9) rotateZ(45deg) translate(-6px, -6px);
    }

    .toggle .toggle-menu {
        background-color: white;
    }
`;

export const ListMenu = styled.ul`
    list-style: none;
    padding-left: 0px;
`;

export const ItemMenu = styled.li`
    text-align: center;
    padding: 0.7rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${Colors.dark};
    cursor: pointer;

    a {

        font-size: 18px;
        text-decoration: none;
        position: relative;
        padding-bottom: 0.3rem;
    }
    a::before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #fff;
        transform: scaleX(0);
        transition: 0.4s ease-in-out;
        transform-origin: left;
    }
    a:hover::before {
        transform: scaleX(1);
    }
`;

export const Logo = styled.img`
    width: 60%;
    margin-top: 65px;
    margin-bottom: 30px;
    cursor: pointer;
`;

export const Mask = styled.div`
    position: fixed;
    background-color: #1c1b1b61;
    top: 0;
    right: ${props => props.isToggle ? '0px' : '-10rem'};;
    height: 100vh;
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.5s ease-out;
    z-index: 999999;
    flex-direction: column;
`;