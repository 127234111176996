import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const Container = styled.div`

`;

export const SubMenuMenuItem = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    cursor: pointer;
    margin: 5px 0px;

    div:last-child {
        margin-left: 5px;
    }
`;

export const SettingWrapper = styled.div`
    display: flex;
    margin-top: 10px;

    @media ${DEVICE_RESPONSIVE.mobile} {
        display: block
    }
`;
