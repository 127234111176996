import React from 'react';
import { Container } from '~/pages/Customer/List/styles';
import Dashboard from '~/components/Dashboard';
import EmployeeAdd from '~/components/Employee/Add';

export default function EmployeeListPage(props) {

    return (
        <Container>
            <Dashboard>
                <EmployeeAdd {...props}/>
            </Dashboard>
        </Container>
    );
}
