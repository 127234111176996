const METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

const API_SERVICE_TYPE = {
    API_URL_FIRST_SERVER: 'API_URL_FIRST_SERVER',
    API_URL_SECOND_SERVER: 'API_URL_SECOND_SERVER',
    API_URL_GHTK: 'API_URL_GHTK',
}

const TOKEN_API_GHTK = '99d88d500abA136dAF22Bfa7c6B3762B337f3e39';

export {
    METHOD,
    API_SERVICE_TYPE,
    TOKEN_API_GHTK
}