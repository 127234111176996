import React from 'react';
import {
    MenuWrapper,
    MenuItem,
} from '~/components/HeaderMenu/styles';
import { useTranslation } from 'react-i18next';
import { Colors } from '~/constants/styles';
import { Cogs } from '@styled-icons/fa-solid/Cogs';
import { SupportAgent } from '@styled-icons/material/SupportAgent';
import { redirectTo } from '~/utils/navigations';
import { ROUTE } from '~/constants/routes';
import { SETTINGS_KEY } from '~/constants/common';

export default function HeaderMenu() {
    const { t } = useTranslation();

    const onRedireactToStaticPage = (key) => {
        redirectTo(`${ROUTE.SETTINGS.STATIC_PAGE_FOR_USER}/${key}`)
    }

    return (
        <MenuWrapper>
            <MenuItem onClick={() => onRedireactToStaticPage(SETTINGS_KEY.TRANSPORT)}>
                <Cogs color={Colors.dark} width={30} />
                <span>{t('common.delivery')}</span>
            </MenuItem>
            <MenuItem onClick={() => onRedireactToStaticPage(SETTINGS_KEY.SUPPORT)}>
                <SupportAgent color={Colors.dark} width={30} />
                <span>{t('common.support')}</span>
            </MenuItem>
        </MenuWrapper>
    )
}
