import { all } from 'redux-saga/effects';
import devto from '~/modules/devto/sagas';
import github from '~/modules/github/sagas';
import loading from '~/modules/loading/sagas';
import themeMode from '~/modules/theme/sagas';
import auth from '~/modules/auth/sagas';
import user from '~/modules/user/sagas';
import settings from '~/modules/settings/sagas';
import address from '~/modules/address/sagas';
import exports from '~/modules/export/sagas';
import orders from '~/modules/orders/sagas';
import uploadFiles from '~/modules/uploadFile/sagas';
import reports from '~/modules/report/sagas';

export default function* rootSaga() {
    return yield all([
        devto,
        github,
        loading,
        themeMode,
        auth,
        user,
        settings,
        address,
        exports,
        address,
        orders,
        uploadFiles,
        reports
    ]);
}
