import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AvForm } from 'availity-reactstrap-validation';
import { useSelector, useDispatch } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import {
    OrdersEmployeeContainer,
    HeaderTitle,
    OrdersListHeader,
    OrdersList,
    UpdateFormContainer,
    ButtonSubmitWrapper,
    FormTitle,
    TabsContainer
} from './styles';
// components
import OrdersItemForEmployee from '~/components/Orders/OrdersItemForEmployee';
import DialogComponent from '~/components/DialogComponent';
import RemakeSelect from '~/components/RemakeSelect';
import RemakeAvField from '~/components/RemakeAvField';
import RemakeButton from '~/components/RemakeButton';
import TakePictureComponent from '~/components/Orders/TakePicture';
// utils
import { convertBase64, DataURIToBlob, formatAddressData, getBase64, isEmpty } from '~/utils/helper';
// actions
import { clearCreateOrdersEvent, createOrdersEvent, getOrders } from '~/modules/orders/actions';
import { handleGetOrdersStatuses } from '~/modules/settings/actions';
import { getDistricts, getProvinces, getWards } from '~/modules/address/actions';
import Header from '~/components/Header';
import { clearUploadFiles, handleUploadFile } from '~/modules/uploadFile/actions';
import { CATEGORY_TYPE_FILE, ORDERS_STATUS } from '~/constants/common';

function OrdersForEmployee() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        orders = [],
        createOrdersEventData
    } = useSelector(state => {
        return state.orders;
    });

    const {
        ordersStatuses = [],
    } = useSelector(state => {
        return state.settings;
    });

    const {
        files = [],
    } = useSelector(state => {
        return state.uploadFiles;
    });
    const { provinces = [], districts = [], wards = [] } = useSelector(state => state.address);
    const [activeTab, setActiveTab] = useState('1');
    let handler = null;

    const [isOpenDialog, setIsOpenDialog] = useState(false);
    var [provinceSelect, setProvinceSelect] = useState(null);
    var [districtSelect, setDistrictSelect] = useState(null);
    var [wardSelect, setWardSelect] = useState(null);
    var [ordersStatus, setOrdersStatus] = useState(null);
    var [ordersId, setOrdersId] = useState(null);
    const [isSubmited, setIsSubmited] = useState(false);
    var [images, setImages] = useState([]);
    var [valuesInput, setValuesInput] = useState({});

    useEffect(() => {
        const params = {
            ordersStatus: getOrdersStatusByTabIndex()
        }
        dispatch(handleGetOrdersStatuses());
        dispatch(getOrders(params));
        dispatch(getProvinces());
    }, []);

    useEffect(() => {
        if (isEmpty(ordersStatuses)) return;
        const params = {
            ordersStatus: getOrdersStatusByTabIndex()
        }
        dispatch(getOrders(params));
    }, [ordersStatuses]);

    useEffect(() => {
        if (!isEmpty(createOrdersEventData)) {
            const params = {
                ordersStatus: getOrdersStatusByTabIndex(activeTab)
            }
            setIsOpenDialog(false);
            dispatch(getOrders(params));
            setProvinceSelect(null);
            setDistrictSelect(null);
            setWardSelect(null);
            setImages([]);
            setIsSubmited(false);
            dispatch(clearCreateOrdersEvent());
            dispatch(clearUploadFiles());
        }
    }, [createOrdersEventData])

    useEffect(() => {
        if (!isEmpty(files)) {
            const body = {
                ...valuesInput,
                ordersId,
                images: filterFiles(files)
            }
            handleCreateOrdersEvent(body);
        }
    }, [files]);

    useEffect(() => {
        const params = {
            ordersStatus: getOrdersStatusByTabIndex()
        }
        dispatch(handleGetOrdersStatuses());
        dispatch(getOrders(params));
        dispatch(getProvinces());
    }, []);

    const toggle = () => {
        setIsOpenDialog(!isOpenDialog);
    }

    const filterFiles = (files) => {
        return files.map(item => {
            return {
                targetId: ordersId,
                fileId: item?.id,
                type: item?.type
            }
        });
    }

    const getOrdersStatusByTabIndex = (index) => {
        const data = [...ordersStatuses].filter(item => {
            if (index === '2') {
                return item.key === ORDERS_STATUS.SUCCESS
            } else {
                return item.key !== ORDERS_STATUS.SUCCESS
            }
        }).map(item => item.id);
        return data;
    }

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
        const params = {
            ordersStatus: getOrdersStatusByTabIndex(tab)
        }
        dispatch(getOrders(params));
    }

    const handleFilterOrdersStatusForEmployee = (ordersStatuses) => {
        return ordersStatuses.filter(os => os.allowEmployee);
    }

    const ordersStatusFormating = (data) => {
        return data.map(item => {
            return {
                label: item?.name,
                value: item?.id,
                requiredTakePicture: item?.requiredTakePicture
            }
        })
    }

    const handleSubmitForm = (event, errors, values) => {
        setIsSubmited(true);
        if (isEmpty(errors)) {
            if (!isEmpty(images)) {
                handleUploadFiles();
                setValuesInput(values);
            } else {
                const body = {
                    ...values,
                    ordersId
                }
                handleCreateOrdersEvent(body);
            }
        }
    }

    const handleCreateOrdersEvent = (body) => {
        dispatch(createOrdersEvent(body));
    }

    const getDistrictsAPI = (value) => {
        const params = {
            provinceId: value
        }
        dispatch(getDistricts(params));
    }

    const getWardsAPI = (value) => {
        const params = {
            districtId: value
        }
        dispatch(getWards(params));
    }

    const onChangeProvince = (itemSelected) => {
        const { value } = itemSelected;
        getDistrictsAPI(value);
        setProvinceSelect(itemSelected);
        setDistrictSelect(null);
        setWardSelect(null);
    }

    const onChangeDistrict = (itemSelected) => {
        const { value } = itemSelected;
        getWardsAPI(value);
        setDistrictSelect(itemSelected);
        setWardSelect(null);
    }

    const onChangeWard = (itemSelected) => {
        setWardSelect(itemSelected);
    }

    const onChangeOrdersStatus = (itemSelected) => {
        setOrdersStatus(itemSelected);
    }

    const handleShowDialogUpdate = ({ ordersId, ordersStatusId }) => {
        const ordersStatusData = ordersStatusFormating(ordersStatuses);
        const defaultSelected = ordersStatusData.find(item => item?.value === ordersStatusId);
        setOrdersId(ordersId);
        setIsOpenDialog(true);
    }

    const handleSearch = (event) => {
        const value = event?.target?.value;
        clearTimeout(handler);
        const params = {
            ordersStatus: getOrdersStatusByTabIndex(activeTab),
            searchText: value
        }
        handler = setTimeout(() => {
            dispatch(getOrders(params));
        }, 500);
    }

    const handleUploadFiles = () => {
        let formData = new FormData();
        images.forEach(image => {
            formData.append('uploadFiles', image);

        });
        const payload = {
            params: {
                categoryType: CATEGORY_TYPE_FILE.ORDERS,
                targetId: ordersId
            },
            body: formData
        }
        dispatch(handleUploadFile(payload));
    }

    const handleTakePhoto = async (image) => {
        const file = await DataURIToBlob(image);
        setImages([...images, file]);
    }

    const renderUpdateForm = () => {
        const isTakePicture = !isEmpty(ordersStatus) && ordersStatus?.requiredTakePicture;
        const ordersStatusForEmployeee = handleFilterOrdersStatusForEmployee(ordersStatuses)
        return (
            <AvForm onSubmit={handleSubmitForm}>
                <FormTitle>{t('orders.update_status')}</FormTitle>
                <UpdateFormContainer>
                    <RemakeSelect
                        name='ordersStatusId'
                        label={t('orders.status')}
                        placeholder={t('orders.required.status')}
                        options={ordersStatusFormating(ordersStatusForEmployeee)}
                        value={ordersStatus}
                        onChange={onChangeOrdersStatus}
                        isSubmited={isSubmited}
                        validate={{
                            required: { value: true, errorMessage: t('orders.required.status_input') },
                        }}
                    />
                    <RemakeSelect
                        name='provinceId'
                        label={t('common.province')}
                        placeholder={t('common.required.province')}
                        options={formatAddressData(provinces)}
                        value={provinceSelect}
                        onChange={onChangeProvince}
                        isSubmited={isSubmited}
                        validate={{
                            required: { value: true, errorMessage: t('common.required.province') },
                        }}
                    />
                    <RemakeSelect
                        name='districtId'
                        label={t('common.district')}
                        placeholder={t('common.required.district')}
                        options={formatAddressData(districts)}
                        value={districtSelect}
                        onChange={onChangeDistrict}
                        isSubmited={isSubmited}
                        validate={{
                            required: { value: true, errorMessage: t('common.required.district') },
                        }}
                    />
                    <RemakeSelect
                        name='wardId'
                        label={t('common.ward')}
                        placeholder={t('common.required.ward')}
                        options={formatAddressData(wards)}
                        value={wardSelect}
                        onChange={onChangeWard}
                        isSubmited={isSubmited}
                        validate={{
                            required: { value: true, errorMessage: t('common.required.ward') },
                        }}
                    />
                    <RemakeAvField
                        height={'35px'}
                        name="address"
                        label={t('common.address')}
                        placeholder={t('common.required.address')}
                        type="text"
                        validate={{
                            required: { value: true, errorMessage: t('common.required.address') },
                        }}
                    />
                    <RemakeAvField
                        height={'80px'}
                        name="notes"
                        label={t('common.note')}
                        placeholder={t('common.add_note')}
                        type="text"
                    />
                    {isTakePicture ? <TakePictureComponent handleTakePhoto={handleTakePhoto} /> : null}
                    <ButtonSubmitWrapper>
                        <RemakeButton
                            label={t('common.update')}
                            type='primary'
                            buttonStyles={{ height: 35 }}
                        />
                    </ButtonSubmitWrapper>
                </UpdateFormContainer>
            </AvForm>
        )
    }

    const renderOrdersItem = () => {
        return orders.map((item, index) => {
            return (
                <OrdersItemForEmployee activeTab={activeTab} key={index} item={item} handleUpdate={handleShowDialogUpdate} />
            )
        })
    }

    const renderTabs = () => {
        return (
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={`${activeTab === '1' && 'active'}`}
                        onClick={() => { toggleTab('1'); }}
                    >
                        {t('orders.my_orders')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === '2' && 'active'}`}
                        onClick={() => { toggleTab('2'); }}
                    >
                        {t('orders.delivered')}
                    </NavLink>
                </NavItem>
            </Nav>
        )
    }

    return (
        <OrdersEmployeeContainer>
            <Header />
            <OrdersListHeader>
                <HeaderTitle>
                    <span>{t('orders.orders_list')}</span>
                </HeaderTitle>
                <Input name="searchText" placeholder={t('orders.search')} onChange={handleSearch} />
            </OrdersListHeader>
            <TabsContainer>
                {renderTabs()}
            </TabsContainer>
            <OrdersList>
                {renderOrdersItem()}
            </OrdersList>
            <DialogComponent isOpen={isOpenDialog} toggle={toggle}>
                {renderUpdateForm()}
            </DialogComponent>
        </OrdersEmployeeContainer>
    )
}

export default OrdersForEmployee
