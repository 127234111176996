import { call, all, takeLatest, put } from 'redux-saga/effects';
import { ACTION_TYPE } from '~/constants/actionTypes';
import httpRequest from '~/services/api';
import { handleGetApiPath } from '~/utils/api';
import { API } from '~/services/app.routes';
import * as notification from '~/utils/notifications';
import { NOTIFICATION_TYPE } from '~/constants/common';
import i18n from '~/utils/i18n';
import { StatusCodes } from 'http-status-codes';
import { handleUploadFileSuccess } from './actions';

function* handleUploadFiles({ payload }) {
    const { body, params } = payload;
    const path = handleGetApiPath(API.UPLOAD_FILES, params);
    const result = yield call(httpRequest.postFormData, path, body);
    const { status, items } = result;
    if (status === StatusCodes.OK) {
        yield put(handleUploadFileSuccess(items))
    }
}

export default all([
    takeLatest(ACTION_TYPE.UPLOAD_FILE.UPLOAD_FILES, handleUploadFiles),
]);
