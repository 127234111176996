import produce from 'immer';
import { ACTION_TYPE } from '~/constants/actionTypes';

const INITIAL_STATE = {
    orders: [],
    ordersData: {},
    createOrdersWithOtherData: {},
    createOrdersEventData: {},
    ordersEvents: null,
    shippingFeeData: {},
    ordersEventTotal: 0,
};

const ordersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPE.ORDERS.GET_BY_ID_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                draft.ordersData = data;
            });
        case ACTION_TYPE.ORDERS.GET_ORDERS_SUCCESS:
            return produce(state, draft => {
                const { items = [], total } = action;
                draft.orders = items;
                draft.total = total;
            });
        case ACTION_TYPE.ORDERS.CLEAR_BY_ID:
            return produce(state, draft => {
                draft.ordersData = {};
            });
        case ACTION_TYPE.ORDERS.CREATE_WITH_OTHER_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                draft.createOrdersWithOtherData = data;
            });
        case ACTION_TYPE.ORDERS.CLEAR_CREATE_WITH_OTHER:
            return produce(state, draft => {
                draft.createOrdersWithOtherData = {};
            });
        case ACTION_TYPE.ORDERS.CREATE_EVENT_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                draft.createOrdersEventData = data;
            });
        case ACTION_TYPE.ORDERS.CLEAR_CREATE_EVENT:
            return produce(state, draft => {
                draft.createOrdersEventData = {};
            });
        case ACTION_TYPE.ORDERS.GET_ORDERS_EVENT_BY_CODE_SUCCESS:
            return produce(state, draft => {
                const { data, total: ordersEventTotal } = action;
                draft.ordersEvents = data;
                draft.ordersEventTotal = ordersEventTotal;
            });
        case ACTION_TYPE.ORDERS.CLEAR_ORDERS_EVENT_BY_CODE:
            return produce(state, draft => {
                draft.ordersEvents = null;
            });
        case ACTION_TYPE.ORDERS.GET_SHIPPING_FEE_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                draft.shippingFeeData = data;
            });
        case ACTION_TYPE.ORDERS.CLEAR_SHIPPING_FEE:
            return produce(state, draft => {
                draft.shippingFeeData = {};
            });

        default:
            return state;
    }
}

export default ordersReducer;
