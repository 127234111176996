import React from 'react';
// components
import Container from '~/components/Container';
import Dashboard from '~/components/Dashboard';
import ChangePasswordComponent from '~/components/Auth/ChangePassword';

function ChangePassword() {
    return (
        <Container>
            <Dashboard>
                <ChangePasswordComponent />
            </Dashboard>
        </Container>
    )
}

export default ChangePassword
