import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const Container = styled.div`

`;

export const StaticPageLabel = styled.div`
    margin: 10px 0px;
    color: ${Colors.dark};
`;

export const ButtonEditing = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;
