import styled from 'styled-components';

export const AttachmentFileContainer = styled.label`
    border-style: dotted;
    border-width: 2px;
    width: 150px;
    height: 150px;
    margin-top: 10px;
    border-radius: 10px;
    input {
        display: none;
    }
     div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        font-size: 28px;
        font-weight: 800;
        cursor: pointer;
        border-radius: 4px;
        width: 150px;
        height: 150px;
    }
`;

export const Container = styled.div`
    display: block;
`;

export const PreviewContainer = styled.div`
    margin-right: 10px;
    display: block;
`;

export const FileItem = styled.div`
    margin-left: 5px;
    position: relative;
    /* width: ${(props) => props.width || '106px'};
    height:  ${(props) => props.height || '106px'};
    max-width: 500px;
    max-height: 500px; */
    width: auto;
    height: auto;
    margin-top: 10px;
    img:nth-child(1) {
        width: auto;
        height: auto;
        max-width: ${(props) => props.maxWidth ? props.maxWidth : '25%'};
    }
    &:hover img:nth-child(2) {
        background-color: #ebe5e5;
        border-radius: 50%;
        opacity: 1;
        transition: 0.3s;
    }
`;

export const DeleteIcon = styled.img`
    display: flex;
    position: absolute;
    top: 0;
    /* right: 0; */
    width: 24px;
    height: 24px;
    cursor: pointer;
    opacity: 0;
`;
