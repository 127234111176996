import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import HeadingTitle from '../HeadingTitle';
import Table from '../Table';
import { getSettings } from '~/modules/settings/actions';
import { SETTINGS_KEY, SETTINGS_KEY_FORMAT } from '~/constants/common';
import { ROUTE } from '~/constants/routes';
import { redirectTo } from '~/utils/navigations';

function StaticPage() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        settings: { settings = {} },
    } = useSelector(state => {
        return state;
    });

    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'no',
            width: '10%',
        },
        {
            title: t('static_page.name'),
            dataIndex: 'name',
            key: 'name',
            width: '85%',
        },
        {
            title: 'Thao tác',
            dataIndex: '',
            key: 'action',
            width: '5%',
        }
    ]

    useEffect(() => {
        dispatch(getSettings());
    },[]);

    const SettingsDataFormating = (data) => {
        return data.filter(settingFilter => (
            settingFilter.key !== SETTINGS_KEY.BRAND_IMAGE &&
            settingFilter.key !== SETTINGS_KEY.BANNER_IMAGES &&
            settingFilter.key !== SETTINGS_KEY.AUTH_IMAGE
        )).map(settingMap => {
            return {
                ...settingMap,
                name: t(`static_page.${SETTINGS_KEY[settingMap.key]}`)
            }
        })
    }

    const handleClickEdit = (item) => {
        const { key } = item;
        redirectTo(`${ROUTE.SETTINGS.STATIC_PAGE}/${SETTINGS_KEY[key]}`);
    }

    return (
        <div>
            <HeadingTitle
                type="normal"
                label={t('dashboard.sidebar.submenu.page_static')}
            />
            <Table
                columns={columns}
                dataSource={SettingsDataFormating(settings)}
                onClickEdit={(item) => handleClickEdit(item)}
                hasMinWidth={true}
            />
        </div>
    )
}

export default StaticPage;
