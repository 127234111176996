import { ACTION_TYPE } from '~/constants/actionTypes';
const handleSelectedMenuSidebar = (params) => {
    return { type: ACTION_TYPE.SETTINGS.SELECTED_MENU_SIDEBAR_SUCCESS, params };
}

const handleGetOrdersStatuses = () => {
    return { type: ACTION_TYPE.SETTINGS.GET_ORDERS_STATUSES };
}

const handleGetOrdersStatusesSuccess = (items) => {
    return { type: ACTION_TYPE.SETTINGS.GET_ORDERS_STATUSES_SUCCESS, items };
}

const handleAddOrdersStatus = (data) => {
    return { type: ACTION_TYPE.SETTINGS.ADD_ORDERS_STATUS, data };
}

const handleAddOrdersStatusSuccess = (data) => {
    return { type: ACTION_TYPE.SETTINGS.ADD_ORDERS_STATUS_SUCCESS, data };
}

const handleDeleteOrdersStatus = (params) => {
    return { type: ACTION_TYPE.SETTINGS.DELETE_ORDERS_STATUS, params };
}

const handleDeleteOrdersStatusSuccess = () => {
    return { type: ACTION_TYPE.SETTINGS.DELETE_ORDERS_STATUS_SUCCESS };
}

const handleGetOrdersStatusById = (params) => {
    return { type: ACTION_TYPE.SETTINGS.GET_ORDERS_STATUS_BY_ID, params };
}

const handleGetOrdersStatusByIdSuccess = (data) => {
    return { type: ACTION_TYPE.SETTINGS.GET_ORDERS_STATUS_BY_ID_SUCCESS, data };
}

const handleUpdateOrdersStatusById = (data) => {
    return { type: ACTION_TYPE.SETTINGS.UPDATE_ORDERS_STATUS_BY_ID, data };
}

const handleUpdateOrdersStatusByIdSuccess = (data) => {
    return { type: ACTION_TYPE.SETTINGS.UPDATE_ORDERS_STATUS_BY_ID_SUCCESS, data };
}

const handleUpdateSortIndexOrdersStatuses = (data) => {
    return { type: ACTION_TYPE.SETTINGS.UPDATE_SORT_INDEX_ORDERS_STATUSES, data };
}

const handleUpdateSortIndexOrdersStatusByIdSuccess = (data) => {
    return { type: ACTION_TYPE.SETTINGS.UPDATE_SORT_INDEX_ORDERS_STATUSES_SUCCESS, data };
}

// appearance
const getSettingByKey = (params) => {
    return {
        type: ACTION_TYPE.SETTINGS.GET_BY_KEY,
        params
    };
}

const getSettingByKeySuccess = (data) => {
    return {
        type: ACTION_TYPE.SETTINGS.GET_BY_KEY_SUCCESS,
        data
    };
}

const clearSetting = () => {
    return {
        type: ACTION_TYPE.SETTINGS.CLEAR,
    };
}

const updateSettingByKey = (body) => {
    return {
        type: ACTION_TYPE.SETTINGS.UPDATE_BY_KEY,
        body
    };
}

const getSettings = () => {
    return {
        type: ACTION_TYPE.SETTINGS.GET_ALL,
    };
}

const getSettingsSuccess = (items) => {
    return {
        type: ACTION_TYPE.SETTINGS.GET_ALL_SUCCESS,
        items
    };
}

export {
    handleSelectedMenuSidebar,
    handleGetOrdersStatuses,
    handleGetOrdersStatusesSuccess,
    handleAddOrdersStatus,
    handleAddOrdersStatusSuccess,
    handleDeleteOrdersStatus,
    handleDeleteOrdersStatusSuccess,
    handleGetOrdersStatusById,
    handleGetOrdersStatusByIdSuccess,
    handleUpdateOrdersStatusById,
    handleUpdateOrdersStatusByIdSuccess,
    handleUpdateSortIndexOrdersStatuses,
    handleUpdateSortIndexOrdersStatusByIdSuccess,
    getSettingByKey,
    getSettingByKeySuccess,
    updateSettingByKey,
    clearSetting,
    getSettings,
    getSettingsSuccess
}
