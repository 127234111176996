import React, { memo } from 'react';
import Select from 'react-select';
import RemakeAvField from '../RemakeAvField';
import { SelectContainer, SelectLabel, MessageError } from './styles';

function RemakeSelect(props) {

    const { value: selectValue, name, validate, label, isSubmited, disabled } = props;
    const { required: { value: isRequired, errorMessage } = {} } = validate || {};

    const onChangeSelect = (itemSelcted) => {
        props.onChange && props.onChange(itemSelcted);
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: 35,
            height: 35,
            borderWidth: 1,
            fontSize: 16,
            backgroundColor: disabled ? '#e9ecef' : '#ffffff',
            borderColor: isSubmited && !selectValue?.value ? '#dc3545' : '#ced4da'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: 7
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#DFE0E8',
            fontSize: 14,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#212529',
        }),
    }

    return (
        <SelectContainer>
            {label && <SelectLabel>
                {label}
                {isRequired && <span>*</span>}
            </SelectLabel>
            }
            <Select isDisabled={disabled} {...props} styles={customStyles} onChange={onChangeSelect} />
            <RemakeAvField
                height={'35px'}
                {...props}
                name={name || 'unName'}
                value={selectValue?.value}
                style={{
                    opacity: 0,
                    // width: "100%",
                    height: 0,
                    position: "absolute"
                }}
            />
            {isSubmited && !selectValue?.value && <MessageError>{errorMessage}</MessageError>}
        </SelectContainer>
    )
}

export default memo(RemakeSelect)
