import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const PickupAddressFormContainer = styled.div`
    display: ${(props) => props?.isShow ? 'block' : 'none'};
`;

export const RowContainer = styled.div`
    display: grid;
    grid-gap: 24px;
    grid-template-columns: ${(props) => props.columns ? props.columns : 'repeat(3, 1fr)'};
    color: ${Colors.dark};
    margin-top: ${(props) => props.marginTop ? props.marginTop : '12px'};

    @media ${DEVICE_RESPONSIVE.mobile} {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
`;

export const AddressContainer = styled.div`
    grid-column: ${(props) => props.column ? `${props.column}/3` : '1/3'};
`;