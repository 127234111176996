import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const FieldContainer = styled.div`
    .form-group {
        input {
            height: ${props => props.height ? props.height : '50px'};
            &::placeholder {
                color: #DFE0E8;
                font-size: 14px;
            }
        }
    }
`;

export const LabelFiled = styled.div`
    height: 16px;
    font-weight: 500;
    color: ${Colors.dark};
    span {
        color: red;
        margin-left: 2px;
    }
`;

export const CurrencyText = styled.div`
    margin-left: 5px;
    margin-top: 5px;
    color: black;
`;