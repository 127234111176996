import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const OrdersStatusContainer = styled.div`
    width: 200px;
`;

export const UpdateButton = styled.div`
    width: 200px;
    height: 35px;
    border: 2px solid ${Colors.primary};
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    cursor: pointer;
    span {
        color: ${Colors.primary};
        font-size: 12px;
        font-weight: 600;
        text-align: center;
    }
`;

export const RowContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    color: ${Colors.dark};

    @media ${DEVICE_RESPONSIVE.mobile} {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const FieldContainer = styled.div`
    margin-top: 1rem;
`;