import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '~/pages/Customer/List/styles';
import { useTranslation } from 'react-i18next';
import { AvForm } from 'availity-reactstrap-validation';
// components
import Dashboard from '~/components/Dashboard';
import Card from '~/components/Card';
import RemakeButton from '~/components/RemakeButton';
import HeadingTitle from '~/components/HeadingTitle';
import Table from '~/components/Table';
import DialogMessage from '~/components/DialogMessage';
import RemakeAvField from '~/components/RemakeAvField';
import RemakeSelect from '~/components/RemakeSelect';
// actions
import { handleDeleteUser, handleGetUsers } from '~/modules/user/actions';
// utils
import { customerFormat, statusFormat } from '~/utils/formats';
import { redirectTo } from '~/utils/navigations';
import { isEmpty } from '~/utils/helper';
// constant
import { EXPORT_TYPE, ROLES, STATUS_OPTIONS } from '~/constants/common';
import { ROUTE } from '~/constants/routes';
// styles
import { CreateButton, RowSearchContainer, SearchContainer, SearchButton } from './styles';
import { HeadingWrapper } from '~/components/HeadingTitle/styles';
import Exports from '~/components/Exports';
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CustomerListPage() {
    let query = useQuery();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const paginationParams = { pageSize: 10 };
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [customerSelected, setCustomerSelected] = useState(null);
    const [statusSelected, setStatusSelected] = useState(STATUS_OPTIONS[0]);
    const [page, setPage] = useState(1);
    const [searchParams, setSearchParams] = useState({});

    const { users = [], total = 0 } = useSelector(state => {
        return state.user;
    });

    useEffect(() => {
        handleGetUsersByParams();
    }, []);

    const handleClickEdit = (employee) => {
        const { id } = employee;
        redirectTo(`${ROUTE.EMPLOYEE.EDIT}/${id}`);
    }

    const handleGetUsersByParams = () => {
        const queryParams = query.get('status');
        const statusItem = getStatusById(queryParams);
        setStatusSelected(statusItem);
        const params = {
            page,
            pageSize: paginationParams.pageSize,
            roleType: ROLES.EMPLOYEE,
            status: statusItem?.value !== 'All' ? statusItem?.value : undefined,
        }
        dispatch(handleGetUsers(params));
    }

    const getStatusById = (value) => {
        return STATUS_OPTIONS.find(item => item?.value === value)
    }

    const handleClickDelete = (item) => {
        setCustomerSelected(item);
        setIsOpenDialog(true);
    }

    const handleClickPagination = (item) => {
        const { paginationSelectedIndex = 0 } = item;
        setPage(paginationSelectedIndex);
        dispatch(handleGetUsers({ page: paginationSelectedIndex, pageSize: paginationParams.pageSize, roleType: ROLES.EMPLOYEE }));
    }

    const handleOkeDialog = () => {
        const { id } = customerSelected;
        dispatch(handleDeleteUser({ userId: id, page, pageSize: paginationParams.pageSize, roleType: ROLES.EMPLOYEE }));
        setIsOpenDialog(false);
    }

    const handleSearch = (event, errors, values) => {
        if (isEmpty(errors)) {
            const { code, fullName, phoneNumber } = values;
            const params = {
                page: 1,
                pageSize: paginationParams.pageSize,
                roleType: ROLES.EMPLOYEE,
                code: code || undefined,
                fullName: fullName || undefined,
                phoneNumber: phoneNumber || undefined,
                status: statusSelected?.value !== 'All' ? statusSelected?.value : undefined,
            }
            setSearchParams(params);
            dispatch(handleGetUsers(params));
        }
    }

    const onChangeStatus = (item) => {
        setStatusSelected(item);
    }

    // Process data source
    const usersResults = users.map(user => {
        const { Customer = {}, status = '' } = user;
        const { CustomerType: { name } = {} } = Customer || {};
        return { ...user, customerType: t(customerFormat(name)), status: t(statusFormat(status)) };
    })

    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'no',
            width: '2%',
        },
        {
            title: 'Mã KH',
            dataIndex: 'code',
            key: 'code',
            width: '10%',
        },
        {
            title: 'Tên Nhân viên',
            dataIndex: 'fullName',
            key: 'fullName',
            width: '29%',
        },
        {
            title: 'SĐT',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '15%',
        },
        {
            title: 'SĐT khẩn cấp',
            dataIndex: 'emergencyPhone',
            key: 'emergencyPhone',
            width: '15%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
        },
        {
            title: '',
            dataIndex: '',
            key: 'action',
            width: '4%',
        }
    ]

    return (
        <Container>
            <Dashboard>
                <Card style={{ marginBottom: 20, padding: 25 }}>
                    <HeadingWrapper>
                        <HeadingTitle
                            type="large"
                            label={t('common.employee.heading_title')}
                        />
                        <Exports
                            exportType={EXPORT_TYPE.USERS}
                            roleType={ROLES.EMPLOYEE}
                            searchParams={searchParams}
                        />
                    </HeadingWrapper>
                    <CreateButton>
                        <RemakeButton
                            type='primary'
                            label={t('employee.create')}
                            onClick={() => redirectTo(ROUTE.EMPLOYEE.ADD)}
                        />
                    </CreateButton>
                    <SearchContainer>
                        <AvForm onSubmit={handleSearch}>
                            <RowSearchContainer>
                                <RemakeAvField
                                    height={'35px'}
                                    name="code"
                                    label={t('employee.code')}
                                    placeholder={t('employee.required.code')}
                                    type="text"
                                />
                                <RemakeAvField
                                    height={'35px'}
                                    name="fullName"
                                    label={t('employee.name')}
                                    placeholder={t('employee.required.name')}
                                    type="text"
                                />
                                <RemakeAvField
                                    height={'35px'}
                                    name="phoneNumber"
                                    label={t('common.phone_number')}
                                    placeholder={t('common.required.phone_number')}
                                    type="text"
                                />
                                <RemakeSelect
                                    label={t('employee.status')}
                                    placeholder={t('employee.status')}
                                    options={STATUS_OPTIONS}
                                    value={statusSelected}
                                    onChange={onChangeStatus}
                                    hasAll
                                />
                            </RowSearchContainer>
                            <SearchButton>
                                <RemakeButton type='primary' label={t('common.search')} />
                            </SearchButton>
                        </AvForm>
                    </SearchContainer>
                </Card>
                <Card>
                    <Table
                        columns={columns}
                        dataSource={usersResults}
                        recordNumberOnPage={paginationParams.pageSize}
                        totalRecord={total}
                        onClickEdit={(item) => handleClickEdit(item)}
                        onClickDelete={(item) => handleClickDelete(item)}
                        onClickPagination={(item) => handleClickPagination(item)}
                    />
                </Card>
            </Dashboard>
            <DialogMessage
                type="error"
                title={t('common.delete_data')}
                message={t('common.delete_data_confirm')}
                isOpen={isOpenDialog}
                leftAction={() => setIsOpenDialog(false)}
                rightAction={() => handleOkeDialog()}
            />
        </Container>
    );
}
