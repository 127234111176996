import React from 'react';
import { Title, HeadingWrapper, TitleContainer } from '~/components/HeadingTitle/styles';

export default function HeadingTitle(props) {
    const handleGetPropertiesStyleByType = () => {
        switch (props.type) {
            case 'normal':
                return {
                    fontSize: 17,
                    fontWeight: 600
                }

            case 'small':
                return {
                    fontSize: 14,
                    fontWeight: 500
                }

            default:
                return {
                    fontSize: 20,
                    fontWeight: 600
                }
        }
    }

    return (
        <HeadingWrapper isBlock={props.isBlock}>
            <TitleContainer>
                <Title style={handleGetPropertiesStyleByType()}>{props.label}</Title>
                {props?.status}
            </TitleContainer>
            {props?.children}
        </HeadingWrapper>
    )
}
