import React from 'react';
import 'boxicons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// styles
import {
    HeaderWrapper,
    MenuWrapper,
    AuthButtonWrapper,
    LeftContentWrapper,
    MenuItem,
    LoginButton,
    SignupButton,
    Logo,
    Line
} from '~/components/Home/TopHeader/styles';
// icons
import logo from '~/assets/images/home/home-logo.svg';
import person from '~/assets/images/home/person.svg';
import arrowDownIcon from '~/assets/images/arrow_down_ic.svg';
// colors
import { Colors } from '~/constants/styles';
// components
import Icon from '~/components/Icon';
import UserProfile from '~/components/UserProfile';
// utils
import { redirectTo } from '~/utils/navigations';
import { handleGetCookies } from '~/utils/cookies';
import { isEmpty } from '~/utils/helper';
// constant
import { ROUTE } from '~/constants/routes';
import { COOKIE_KEY, DRIVER_IMAGE_URL, SETTINGS_KEY } from '~/constants/common';

function TopHeaderComponent() {

    const { t } = useTranslation();
    const userInfo = handleGetCookies(COOKIE_KEY.LOGIN);

    const {
        settings: { settings = {} },
    } = useSelector(state => {
        return state;
    });

    const onRedireactToStaticPage = (key) => {
        redirectTo(`${ROUTE.SETTINGS.STATIC_PAGE_FOR_USER}/${key}`)
    }

    const renderLogo = () => {
        const logoSetting = settings.find(setting => setting.key === SETTINGS_KEY.BRAND_IMAGE);
        if (logoSetting?.value) {
            return `${DRIVER_IMAGE_URL}${logoSetting.value}`
        } else {
            return '';
        }
    }

    return (
        <HeaderWrapper>
            <LeftContentWrapper>
                {renderLogo() && <Logo src={renderLogo()} onClick={() => { redirectTo(ROUTE.HOME) }} />}
                <MenuWrapper>
                    <MenuItem onClick={() => onRedireactToStaticPage(SETTINGS_KEY.ABOUT_US)}>
                        <span>{t('home.about_us')}</span>
                        {/* <img src={arrowDownIcon} alt=''/> */}
                        {/* <ul class="dropdown_menu">
                            <li class="dropdown_item-1">Item 1</li>
                            <li class="dropdown_item-2">Item 2</li>
                            <li class="dropdown_item-3">Item 3</li>
                        </ul> */}
                    </MenuItem>
                    <MenuItem onClick={() => onRedireactToStaticPage(SETTINGS_KEY.SERVICES)}>
                        <span>{t('home.service')}</span>
                        {/* <img src={arrowDownIcon} alt=''/> */}
                        {/* <ul class="dropdown_menu">
                            <li class="dropdown_item-1">Item 1</li>
                            <li class="dropdown_item-2">Item 2</li>
                            <li class="dropdown_item-3">Item 3</li>
                        </ul> */}
                    </MenuItem>
                    <MenuItem onClick={() => onRedireactToStaticPage(SETTINGS_KEY.CONTACT)}>
                        <span>{t('home.contact')}</span>
                        {/* <img src={arrowDownIcon} alt=''/> */}
                        {/* <ul class="dropdown_menu">
                            <li class="dropdown_item-1">Item 1</li>
                            <li class="dropdown_item-2">Item 2</li>
                            <li class="dropdown_item-3">Item 3</li>
                        </ul> */}
                    </MenuItem>
                </MenuWrapper>
            </LeftContentWrapper>
            {isEmpty(userInfo) ? (
                <AuthButtonWrapper>
                    <SignupButton onClick={() => redirectTo(ROUTE.AUTH.SIGN_UP)}>
                        {t('common.sign_up')}
                    </SignupButton>
                    <Line>|</Line>
                    <LoginButton onClick={() => redirectTo(ROUTE.AUTH.LOGIN)}>
                        <div>{t('common.sign_in')}</div>
                        <img src={person} alt="" />
                    </LoginButton>
                </AuthButtonWrapper>
            ) : (
                <UserProfile />
            )}
        </HeaderWrapper>
    )
}

export default TopHeaderComponent
