import { call, put, all, takeLatest } from 'redux-saga/effects';
import httpRequest from '~/services/api';
import { API } from '~/services/app.routes';
// constant
import { ACTION_TYPE } from '~/constants/actionTypes';
import { ROUTE } from '~/constants/routes';
import { NOTIFICATION_TYPE } from '~/constants/common';
// utils
import * as notification from '~/utils/notifications';
import i18n from '~/utils/i18n';
import { handleGetApiPath } from '~/utils/api';
import { redirectTo } from '~/utils/navigations';
import { isEmpty } from '~/utils/helper';
// actions
import { StatusCodes } from 'http-status-codes';
import {
    getOrdersByIdSuccess,
    createOrdersWithOtherSuccess,
    getOrdersSuccess,
    createOrdersEventSuccess,
    getOrdersEventByOrdersCodeSuccess,
    getShippingFeeSuccess
} from './actions';
import { API_SERVICE_TYPE } from '~/constants/api';

function* handleCreateOrders({ body }) {
    const path = handleGetApiPath(API.ORDERS.CREATE_BY_PARTNER);
    const result = yield call(httpRequest.post, path, body);
    const { status, error } = result;
    if (status === 201) {
        notification.custom(i18n.t('common.success'), i18n.t('orders.create_success'), NOTIFICATION_TYPE.SUCCESS);
        redirectTo(ROUTE.ORDERS.LIST);
    } else {
        const { message } = error || {};
        notification.custom(i18n.t('common.fail'), i18n.t(`auth.sign_up.${message}`), NOTIFICATION_TYPE.ERROR);
    }
}

function* handleCreateOrdersWithOther({ body }) {
    const path = handleGetApiPath(API.ORDERS.CREATE_WITH_OTHER);
    const result = yield call(httpRequest.post, path, body);
    const { status, error } = result;
    if (status === 201) {
        yield put(createOrdersWithOtherSuccess(result));
    } else {
        const { message } = error || {};
        notification.custom(i18n.t('common.fail'), i18n.t(`auth.sign_up.${message}`), NOTIFICATION_TYPE.ERROR);
    }
}

function* handleGetOrdersById({ params }) {
    const path = handleGetApiPath(API.ORDERS.GET_BY_ID, params);
    const result = yield call(httpRequest.get, path);
    const { data } = result;
    if (!isEmpty(data)) {
        yield put(getOrdersByIdSuccess(data));
    }
}

function* handleGetOrders({ params }) {
    const path = handleGetApiPath(API.ORDERS.GET_ORDERS, params);
    const result = yield call(httpRequest.get, path);
    const { items = [], meta: { total = 0 } = {} } = result;
    yield put(getOrdersSuccess(items, total));
}

function* handleUpdateOrders({ data }) {
    const { params, body } = data;
    const path = handleGetApiPath(API.ORDERS.UPDATE, params);
    const result = yield call(httpRequest.put, path, body);
    const { status, error } = result;
    if (status === 200) {
        notification.custom(i18n.t('common.success'), i18n.t('orders.update_success'), NOTIFICATION_TYPE.SUCCESS);
        redirectTo(ROUTE.ORDERS.LIST);
    } else {
        const { message } = error || {};
        notification.custom(i18n.t('common.fail'), i18n.t(`common.${message}`), NOTIFICATION_TYPE.ERROR);
    }
}

function* handleDeleteOrders({ params }) {
    const path = handleGetApiPath(API.ORDERS.UPDATE, params);
    const result = yield call(httpRequest.delete, path);
    const { status, error } = result;
    if (status === StatusCodes.OK) {
        notification.custom(i18n.t('common.success'), i18n.t('orders.delete_success'), NOTIFICATION_TYPE.SUCCESS);
        const path = handleGetApiPath(API.ORDERS.GET_ORDERS, params);
        const ordersResults = yield call(httpRequest.get, path);
        const { items = [], meta: { total = 0 } = {} } = ordersResults;
        yield put(getOrdersSuccess(items, total));
    } else {
        const { message } = error || {};
        notification.custom(i18n.t('common.fail'), i18n.t(`common.${message}`), NOTIFICATION_TYPE.ERROR);
    }
}

function* handleCreateOrdersEvent({ body }) {
    const path = handleGetApiPath(API.ORDERS.CREATE_EVENT);
    const result = yield call(httpRequest.post, path, body);
    const { status, error } = result;
    if (status === 201) {
        notification.custom(i18n.t('common.success'), i18n.t('dialog.orders_status.update_success'), NOTIFICATION_TYPE.SUCCESS);
        const { code, isGetList } = body;
        const params = {
            code
        }
        if (isGetList) {
            const getOrdersEventPath = handleGetApiPath(API.ORDERS.GET_EVENT_BY_CODE, params);
            const ordersEventResult = yield call(httpRequest.get, getOrdersEventPath, false);
            const { items = [], status, meta: { count = 0 } = {} } = ordersEventResult;
            if (status === StatusCodes.OK) {
                yield put(getOrdersEventByOrdersCodeSuccess(items, count));
            }
        }
        yield put(createOrdersEventSuccess(result));
    } else {
        const { message } = error || {};
        notification.custom(i18n.t('common.fail'), i18n.t(`orders.${message}`), NOTIFICATION_TYPE.ERROR);
    }
}

function* handleGetOrdersEventByCode({ params }) {
    const path = handleGetApiPath(API.ORDERS.GET_EVENT_BY_CODE, params);
    const result = yield call(httpRequest.get, path, false);
    const { items = [], status, meta: { count = 0 } = {} } = result;
    if (status === StatusCodes.OK) {
        yield put(getOrdersEventByOrdersCodeSuccess(items, count));
    }
}

function* handleGetShippingFee({ params }) {
    const path = handleGetApiPath(API.ORDERS.GET_SHIPPING_FEE, params);
    const result = yield call(httpRequest.get, path, true);
    const { data } = result;
    const { fee } = data || {};
    if (!isEmpty(fee)) {
        yield put(getShippingFeeSuccess(fee));
    }
}

export default all([
    takeLatest(ACTION_TYPE.ORDERS.CREATE_ORDERS, handleCreateOrders),
    takeLatest(ACTION_TYPE.ORDERS.CREATE_WITH_OTHER, handleCreateOrdersWithOther),
    takeLatest(ACTION_TYPE.ORDERS.GET_BY_ID, handleGetOrdersById),
    takeLatest(ACTION_TYPE.ORDERS.GET_ORDERS, handleGetOrders),
    takeLatest(ACTION_TYPE.ORDERS.UPDATE, handleUpdateOrders),
    takeLatest(ACTION_TYPE.ORDERS.DELETE, handleDeleteOrders),
    takeLatest(ACTION_TYPE.ORDERS.CREATE_EVENT, handleCreateOrdersEvent),
    takeLatest(ACTION_TYPE.ORDERS.GET_ORDERS_EVENT_BY_CODE, handleGetOrdersEventByCode),
    takeLatest(ACTION_TYPE.ORDERS.GET_SHIPPING_FEE, handleGetShippingFee),
]);
