import { ACTION_TYPE } from '~/constants/actionTypes';

const login = (user) => {
    return {
        type: ACTION_TYPE.AUTH.LOGIN,
        user
    };
}

const loginSuccess = (userData) => {
    return {
        type: ACTION_TYPE.AUTH.LOGIN_SUCCESS,
        userData
    };
}

const singup = (singupData) => {
    return {
        type: ACTION_TYPE.AUTH.SIGN_UP,
        singupData
    };
}

const signupSuccess = (signupData) => {
    return {
        type: ACTION_TYPE.AUTH.SIGN_UP_SUCCESS,
        signupData
    };
}

const logout = () => {
    return {
        type: ACTION_TYPE.AUTH.LOGOUT,
    };
}

const forgotPassword = (userData) => {
    return {
        type: ACTION_TYPE.AUTH.FORGOT_PASSWORD,
        userData
    };
}

const forgotPasswordSuccess = (fpData) => {
    return {
        type: ACTION_TYPE.AUTH.FORGOT_PASSWORD_SUCCESS,
        fpData
    };
}

const resetPassword = (userData) => {
    return {
        type: ACTION_TYPE.AUTH.RESET_PASSWORD,
        userData
    };
}

const resetPasswordSuccess = (rpData) => {
    return {
        type: ACTION_TYPE.AUTH.RESET_PASSWORD_SUCCESS,
        rpData
    };
}

const clearSignupData = () => {
    return {
        type: ACTION_TYPE.AUTH.CLEAR_SIGN_UP_DATA,
    };
}

const changePassword = (body) => {
    return {
        type: ACTION_TYPE.AUTH.CHANGE_PASSWORD,
        body
    };
}

const changePasswordSuccess = (cpData) => {
    return {
        type: ACTION_TYPE.AUTH.CHANGE_PASSWORD_SUCCESS,
        cpData
    };
}

export {
    login,
    loginSuccess,
    singup,
    signupSuccess,
    logout,
    forgotPassword,
    forgotPasswordSuccess,
    resetPassword,
    resetPasswordSuccess,
    clearSignupData,
    changePassword,
    changePasswordSuccess
}