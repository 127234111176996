import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { AvForm } from 'availity-reactstrap-validation';
import * as dayjs from 'dayjs';
// components
import Card from '~/components/Card';
import HeadingTitle from '~/components/HeadingTitle';
import RemakeAvField from '../RemakeAvField';
import RemakeButton from '../RemakeButton';
// styles
import { PageTitle, ProfileContainer, ProfileFormContainer, FieldContainer, RowContainer, ButtonsContainer, Title } from './styles';
// constant
import { COOKIE_KEY, DATE_FORMAT, GENDERS, ROLES } from '~/constants/common';
import RemakeSelect from '../RemakeSelect';
import DatePicker from '../DatePicker';
// actions
import { getDistricts, getProvinces, getWards, resetDistricts, resetWards } from '~/modules/address/actions';
import { formatAddressData, isEmpty } from '~/utils/helper';
import { history } from '~/utils/navigations';
import { handleGetCookies } from '~/utils/cookies';
import RemakeButtonNotSubmit from '../RemakeButtonNotSubmit';
import { clearUpdateUserSuccess } from '~/modules/user/actions';

export default function ProfileInfoComponent(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { provinces = [], districts = [], wards = [] } = useSelector(state => state.address);
    const userInfo = handleGetCookies(COOKIE_KEY.LOGIN);
    const { UserRole: { RoleType: { name: roleName = '' } = {} } = {} } = userInfo;

    const [formValues, setFormValue] = useState({});
    const [provinceSelect, setProvinceSelect] = useState(null);
    const [districtSelect, setDistrictSelect] = useState(null);
    const [wardSelect, setWardSelect] = useState(null);
    const [genderSelect, setGenderSelect] = useState(GENDERS?.[0]);
    const [birthday, setBirthday] = useState(undefined);
    const [userBanks, setUserBanks] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const {
        values,
        pageTitle,
        handleSubmit,
        isMyProfile,
        isUpdateUserSuccess
    } = props;
    const {
        fullName,
        phoneNumber,
        emergencyPhone,
        email,
        address,
    } = formValues || {};

    useEffect(() => {
        dispatch(getProvinces());
        return function cleanup() {
            dispatch(resetDistricts());
            dispatch(resetWards());
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(values)) {
            const {
                provinceId,
                districtId,
                birthday: birthdayAPI,
                UserBanks = []
            } = values;
            if (provinceId) {
                getDistrictsAPI(provinceId);
            }
            if (districtId) {
                getWardsAPI(districtId);
            }
            setBirthday(birthdayAPI);
            setFormValue(values);
            setUserBanks(UserBanks);
        }
    }, [values]);

    useEffect(() => {
        if (!isEmpty(values)) {
            const { provinceId } = values;
            if (provinceId) {
                const provinceItem = findAddressById(provinces, provinceId) || null;
                setProvinceSelect(provinceItem);
            }
        }
    }, [provinces, values]);

    useEffect(() => {
        if (!isEmpty(values)) {
            const { districtId } = values;
            if (districtId) {
                const districtItem = findAddressById(districts, districtId) || null;
                setDistrictSelect(districtItem);
            }
        }
    }, [districts]);

    useEffect(() => {
        if (!isEmpty(values)) {
            const { wardId } = values;
            if (wardId) {
                const wardItem = findAddressById(wards, wardId) || null;
                setWardSelect(wardItem);
            }
        }
    }, [wards]);

    useEffect(() => {
        if (isMyProfile) {
            setIsDisabled(true);
        }
    }, [isMyProfile]);

    useEffect(() => {
        if (isUpdateUserSuccess) {
            setIsDisabled(true);
            clearUpdateUserSuccess(false);
        }
    }, [isUpdateUserSuccess]);

    const findAddressById = (addressArr = [], id) => {
        return formatAddressData(addressArr).find(item => item?.value === id);
    }

    const userBankDefault = userBanks.length && userBanks.find(item => {
        return item.isDefault;
    })

    const userBankOther = userBanks.length && userBanks.find(item => {
        return !item.isDefault;
    })

    const {
        holderName: bankNameDefault,
        number: bankNumberDefault,
        branchName: bankBranchDefault,
    } = userBankDefault;

    const {
        holderName: bankNameOther,
        number: bankNumberOther,
        branchName: bankBranchOther,
    } = userBankOther;

    const getDistrictsAPI = (value) => {
        const params = {
            provinceId: value
        }
        dispatch(getDistricts(params));
    }

    const getWardsAPI = (value) => {
        const params = {
            districtId: value
        }
        dispatch(getWards(params));
    }

    const onChangeProvince = (itemSelected) => {
        const { value } = itemSelected;
        getDistrictsAPI(value);
        setProvinceSelect(itemSelected);
        setDistrictSelect(null);
        setWardSelect(null);
    }

    const onChangeDistrict = (itemSelected) => {
        const { value } = itemSelected;
        getWardsAPI(value);
        setDistrictSelect(itemSelected);
        setWardSelect(null);
    }

    const onChangeWard = (itemSelected) => {
        setWardSelect(itemSelected);
    }

    const onChangeGender = (item) => {
        setGenderSelect(item);
    }

    const onChangeDayOfBirth = (date) => {
        setBirthday(dayjs(date).format(DATE_FORMAT.YYYY_MM_DD));
    }

    const getValues = () => {
        const { value: provinceId } = provinceSelect || {};
        const { value: districtId } = districtSelect || {};
        const { value: wardId } = wardSelect || {};
        const { value: gender } = genderSelect || {};
        return {
            provinceId,
            districtId,
            wardId,
            gender,
            birthday
        }
    }

    const handleSubmitForm = (event, errors, values) => {
        if (isEmpty(errors)) {
            const {
                bankNameDefault,
                bankNumberDefault,
                bankBranchDefault,
                bankNameOther,
                bankNumberOther,
                bankBranchOther,
            } = values;
            let valuesResults = { ...values };
            if (roleName === ROLES.CUSTOMER) {
                valuesResults.userBanks = [
                    {
                        holderName: bankNameDefault,
                        number: bankNumberDefault,
                        branchName: bankBranchDefault,
                        isDefault: true,
                    },
                    {
                        holderName: bankNameOther,
                        number: bankNumberOther,
                        branchName: bankBranchOther
                    }
                ]
            }
            const formValuesData = {
                ...getValues(),
                ...valuesResults
            }

            handleSubmit && handleSubmit(formValuesData);
        }
    }

    return (
        <ProfileContainer>
            <Card>
                <HeadingTitle
                    type="large"
                    label={pageTitle}
                />
                <ProfileFormContainer>
                    <AvForm onSubmit={handleSubmitForm}>
                        <PageTitle>{t('common.my_profile')}</PageTitle>
                        <RowContainer>
                            <RemakeAvField
                                height={'35px'}
                                name="fullName"
                                label={t('common.full_name')}
                                placeholder={t('common.required.full_name')}
                                type="text"
                                value={fullName}
                                validate={{
                                    required: { value: true, errorMessage: t('common.required.full_name') },
                                }}
                                disabled={isDisabled}
                            />
                            <DatePicker
                                name='dayOfBirth'
                                label={t('common.day_of_birth')}
                                placeholder={t('common.required.day_of_birth')}
                                value={birthday}
                                onChange={onChangeDayOfBirth}
                                options={
                                    {
                                        // maxDate: dayjs().format(DATE_FORMAT.YYYY_MM_DD),
                                        dateFormat: DATE_FORMAT.DD_MM_YYYY,
                                    }
                                }
                                format={DATE_FORMAT.DD_MM_YYYY}
                                disabled={isDisabled}
                            />
                            <RemakeSelect
                                name='grender'
                                label={t('common.gender')}
                                placeholder={t('common.required.gender')}
                                options={GENDERS}
                                value={genderSelect}
                                onChange={onChangeGender}
                                disabled={isDisabled}
                            />
                            <RemakeAvField
                                height={'35px'}
                                name="phoneNumber"
                                label={t('common.phone_number')}
                                placeholder={t('common.required.phone_number')}
                                type="number"
                                value={phoneNumber}
                                validate={{
                                    required: { value: true, errorMessage: t('common.required.phone_number') },
                                    minLength: { value: 10, errorMessage: t('auth.sign_up.phone_number_incorrect_format') },
                                    maxLength: { value: 16, errorMessage: t('auth.sign_up.phone_number_incorrect_format') },
                                }}
                                disabled={isDisabled}
                            />
                            <RemakeAvField
                                height={'35px'}
                                name="emergencyPhone"
                                label={t('common.emergency_phone')}
                                placeholder={t('common.required.emergency_phone')}
                                type="number"
                                value={emergencyPhone}
                                validate={{
                                    required: { value: true, errorMessage: t('common.required.emergency_phone') },
                                    minLength: { value: 10, errorMessage: t('auth.sign_up.phone_number_incorrect_format') },
                                    maxLength: { value: 16, errorMessage: t('auth.sign_up.phone_number_incorrect_format') },
                                }}
                                disabled={isDisabled}
                            />
                            <RemakeAvField
                                height={'35px'}
                                name="email"
                                label={t('auth.login.email')}
                                placeholder={t('auth.login.email_required_message')}
                                type="email"
                                value={email}
                                validate={{
                                    required: { value: true, errorMessage: t('auth.login.email_required_message') },
                                    email: { value: true, errorMessage: t('auth.login.email_format_message') }
                                }}
                                disabled={isDisabled}
                            />
                            <RemakeSelect
                                label={t('common.province')}
                                placeholder={t('common.required.province')}
                                options={formatAddressData(provinces)}
                                value={provinceSelect}
                                onChange={onChangeProvince}
                                disabled={isDisabled}
                            />
                            <RemakeSelect
                                label={t('common.district')}
                                placeholder={t('common.required.district')}
                                options={formatAddressData(districts)}
                                value={districtSelect}
                                onChange={onChangeDistrict}
                                disabled={isDisabled}
                            />
                            <RemakeSelect
                                label={t('common.ward')}
                                placeholder={t('common.required.ward')}
                                options={formatAddressData(wards)}
                                value={wardSelect}
                                onChange={onChangeWard}
                                disabled={isDisabled}
                            />
                        </RowContainer>
                        <FieldContainer>
                            <RemakeAvField
                                height={'35px'}
                                name="address"
                                label={t('common.address')}
                                placeholder={t('common.required.address')}
                                type="text"
                                value={address}
                                validate={{
                                }}
                                disabled={isDisabled}
                            />
                        </FieldContainer>
                        {roleName === ROLES.CUSTOMER && (
                            <>
                                <PageTitle>{t('common.my_bank')}</PageTitle>
                                <Title>{t('common.address_default')}</Title>
                                <RowContainer>
                                    <RemakeAvField
                                        height={'35px'}
                                        name="bankNameDefault"
                                        label={t('common.bank_name')}
                                        placeholder={t('common.required.bank_name')}
                                        type="text"
                                        value={bankNameDefault}
                                        validate={{
                                            required: { value: true, errorMessage: t('common.required.bank_name') },
                                        }}
                                        disabled={isDisabled}
                                    />
                                    <RemakeAvField
                                        height={'35px'}
                                        name="bankNumberDefault"
                                        label={t('common.bank_number')}
                                        placeholder={t('common.required.bank_number')}
                                        type="text"
                                        value={bankNumberDefault}
                                        validate={{
                                            required: { value: true, errorMessage: t('common.required.bank_number') },
                                        }}
                                        disabled={isDisabled}
                                    />
                                    <RemakeAvField
                                        height={'35px'}
                                        name="bankBranchDefault"
                                        label={t('common.bank_branch')}
                                        placeholder={t('common.required.bank_branch')}
                                        type="text"
                                        value={bankBranchDefault}
                                        validate={{
                                            required: { value: true, errorMessage: t('common.required.bank_branch') },
                                        }}
                                        disabled={isDisabled}
                                    />
                                </RowContainer>
                                <Title>{t('common.other')}</Title>
                                <RowContainer>
                                    <RemakeAvField
                                        height={'35px'}
                                        name="bankNameOther"
                                        label={t('common.bank_name')}
                                        placeholder={t('common.required.bank_name')}
                                        type="text"
                                        value={bankNameOther}
                                        disabled={isDisabled}
                                    />
                                    <RemakeAvField
                                        height={'35px'}
                                        name="bankNumberOther"
                                        label={t('common.bank_number')}
                                        placeholder={t('common.required.bank_number')}
                                        type="text"
                                        value={bankNumberOther}
                                        disabled={isDisabled}
                                    />
                                    <RemakeAvField
                                        height={'35px'}
                                        name="bankBranchOther"
                                        label={t('common.bank_branch')}
                                        placeholder={t('common.required.bank_branch')}
                                        type="text"
                                        value={bankBranchOther}
                                        disabled={isDisabled}
                                    />
                                </RowContainer>
                            </>
                        )}
                        <ButtonsContainer>
                            <RemakeButton
                                label={t('common.go_back')}
                                type='button'
                                buttonStyles={{ marginRight: 10, background: '#C4C4C4' }}
                                onClick={() => history.goBack()}
                            />
                            {isDisabled ? (
                                <RemakeButtonNotSubmit
                                    label={t('common.editing')}
                                    type='primary'
                                    onClick={() => setIsDisabled(false)}
                                />
                            ) : (
                                <RemakeButton label={t('common.save')} type='primary' />
                            )}
                        </ButtonsContainer>
                    </AvForm>
                </ProfileFormContainer>
            </Card>
        </ProfileContainer>
    );
}
