import React, { memo } from 'react';
import { AvField } from 'availity-reactstrap-validation';
import { FieldContainer, LabelFiled, CurrencyText } from './styles';
import { VNDCurrencyFormatting } from '~/utils/helper';

function RemakeAvField(props) {
    const { label, validate, labelStyle, isCurrency, value} = props;
    const { required = false } = validate || {};
    return (
        <FieldContainer style={props?.style} height={props?.height}>
            {label && <LabelFiled style={labelStyle}>
                {label}
                {required && <span>*</span>}
            </LabelFiled>
            }
            <AvField
                {...props}
                label={undefined}
            />
            {(value && value !== 0 && isCurrency) ? <CurrencyText>{`(${VNDCurrencyFormatting(parseFloat(value))})`}</CurrencyText> : null}
        </FieldContainer>
    )
}

export default memo(RemakeAvField)
