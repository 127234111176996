import React from 'react';
import { Route } from 'react-router-dom';
import { checkUserAuthenticated } from '~/utils/auths';
import { ROUTE } from '~/constants/routes';
import { ROLES } from './constants/common';

// const HomePage = React.lazy(() => import('~/pages/Home'));
// const OrdersListPage = React.lazy(() => import('~/pages/Orders/List'));
// const CustomerListPage = React.lazy(() => import('~/pages/Customer/List'));
// const EmployeeListPage = React.lazy(() => import('~/pages/Employee/List'));

import HomePage from '~/pages/Home';
import OrdersListPage from '~/pages/Orders/List';
import CustomerListPage from '~/pages/Customer/List';
import CustomerAddPage from '~/pages/Customer/Add';
import EmployeeListPage from '~/pages/Employee/List';
import EmployeeAddPage from '~/pages/Employee/Add';
import MyProfilePage from '~/pages/MyProfile';
import ChangePasswordPage from '~/pages/ChangePassword';
import SettingsPage from '~/pages/Settings';
import AddOrdersPage from '~/pages/Orders/Add';
import OrdersForEmployeePage from '~/pages/Orders/Employee';
import StaticPageEditing from '~/pages/StaticPageEditing';
import StaticPageForUser from '~/pages/StaticPageForUser';
import ReportsPageForUser from '~/pages/Reports';

const LoginPage = React.lazy(() => import('~/pages/Login'));
const SignUpPage = React.lazy(() => import('~/pages/SignUp'));
const ForgotPasswordPage = React.lazy(() => import('~/pages/ForgotPassword'));
const ResetPasswordPage = React.lazy(() => import('~/pages/ResetPassword'));
const NoPermissionPage = React.lazy(() => import('~/pages/NoPermission'));
const PrivateRoute = ({ component: Component, roles, auth, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            checkUserAuthenticated(auth, roles);
            return <Component {...props} />;
        }}
    />
);

const flattenRoutes = (routes) => {
    let flatRoutes = [];
    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);
        if (typeof item.children !== "undefined") {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// Route List
const homeRoute = {
    path: ROUTE.HOME,
    exact: true,
    component: HomePage,
    route: PrivateRoute
};

const loginRoute = {
    path: ROUTE.AUTH.LOGIN,
    exact: true,
    component: LoginPage,
    route: PrivateRoute
};

const signUpRoute = {
    path: ROUTE.AUTH.SIGN_UP,
    exact: true,
    component: SignUpPage,
    route: PrivateRoute
};

const forgotPasswordRoute = {
    path: ROUTE.AUTH.FORGOT_PASSWORD,
    exact: true,
    component: ForgotPasswordPage,
    route: PrivateRoute
};

const resetPasswordRoute = {
    path: ROUTE.AUTH.RESET_PASSWORD,
    exact: true,
    component: ResetPasswordPage,
    route: PrivateRoute
};

const ordersListPageRoute = {
    path: ROUTE.ORDERS.LIST,
    exact: true,
    roles: [ROLES.ADMIN, ROLES.CUSTOMER],
    auth: true,
    component: OrdersListPage,
    route: PrivateRoute
};

const addOrdersPageRoute = {
    path: ROUTE.ORDERS.ADD,
    exact: true,
    roles: [ROLES.ADMIN, ROLES.CUSTOMER],
    auth: true,
    component: AddOrdersPage,
    route: PrivateRoute
};

const editOrdersRoute = {
    path: ROUTE.ORDERS.EDIT_ID,
    exact: true,
    roles: [ROLES.ADMIN, ROLES.CUSTOMER],
    auth: true,
    component: AddOrdersPage,
    route: PrivateRoute
};

const ordersForEmployeeRoute = {
    path: ROUTE.ORDERS.EMPLOYEE,
    exact: true,
    roles: [ROLES.EMPLOYEE],
    auth: true,
    component: OrdersForEmployeePage,
    route: PrivateRoute
};

const customerListPageRoute = {
    path: ROUTE.CUSTOMER.LIST,
    exact: true,
    roles: [ROLES.ADMIN],
    auth: true,
    component: CustomerListPage,
    route: PrivateRoute
};

const customerAddRoute = {
    path: ROUTE.CUSTOMER.EDIT_ID,
    exact: true,
    roles: [ROLES.ADMIN],
    auth: true,
    component: CustomerAddPage,
    route: PrivateRoute
};

const employeeListPageRoute = {
    path: ROUTE.EMPLOYEE.LIST,
    exact: true,
    roles: [ROLES.ADMIN],
    auth: true,
    component: EmployeeListPage,
    route: PrivateRoute
};

const employeeAddRoute = {
    path: ROUTE.EMPLOYEE.ADD,
    exact: true,
    roles: [ROLES.ADMIN],
    auth: true,
    component: EmployeeAddPage,
    route: PrivateRoute
};

const employeeEditRoute = {
    path: ROUTE.EMPLOYEE.EDIT_ID,
    exact: true,
    auth: true,
    component: EmployeeAddPage,
    route: PrivateRoute
};

const myProfileRoute = {
    path: ROUTE.MY_PROFILE,
    exact: true,
    auth: true,
    component: MyProfilePage,
    route: PrivateRoute
};

const changePasswordRoute = {
    path: ROUTE.AUTH.CHANGE_PASSWORD,
    exact: true,
    auth: true,
    component: ChangePasswordPage,
    route: PrivateRoute
};

const noPermissionRoute = {
    path: ROUTE.NO_PERMISSION,
    exact: true,
    component: NoPermissionPage,
    route: PrivateRoute
};

const settingsRoute = {
    path: ROUTE.SETTINGS.MAIN,
    exact: true,
    roles: [ROLES.ADMIN],
    auth: true,
    component: SettingsPage,
    route: PrivateRoute
};

const staticPageEditingRoute = {
    path: ROUTE.SETTINGS.STATIC_PAGE_EDITING,
    exact: true,
    roles: [ROLES.ADMIN],
    auth: true,
    component: StaticPageEditing,
    route: PrivateRoute
};

const staticPageForUserRoute = {
    path: ROUTE.SETTINGS.STATIC_PAGE_FOR_USER_KEY,
    exact: true,
    component: StaticPageForUser,
    route: PrivateRoute
};

const reportsPageEditingRoute = {
    path: ROUTE.REPORTS.MAIN,
    exact: true,
    roles: [ROLES.ADMIN],
    auth: true,
    component: ReportsPageForUser,
    route: PrivateRoute
};

// Total
const allRoutes = [
    homeRoute,
    ordersListPageRoute,
    customerListPageRoute,
    customerAddRoute,
    employeeListPageRoute,
    employeeAddRoute,
    loginRoute,
    signUpRoute,
    forgotPasswordRoute,
    resetPasswordRoute,
    employeeEditRoute,
    myProfileRoute,
    changePasswordRoute,
    noPermissionRoute,
    settingsRoute,
    addOrdersPageRoute,
    editOrdersRoute,
    ordersForEmployeeRoute,
    staticPageEditingRoute,
    staticPageForUserRoute,
    reportsPageEditingRoute
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allFlattenRoutes };