import produce from 'immer';
import { ACTION_TYPE } from '~/constants/actionTypes';
import { MENUS_SIDEBAR as menusSidebar } from '~/constants/common';

const INITIAL_STATE = {
    reportsEmployeeData: {},
    reportsCustomerData: {},
    reportsOrdersData: {},
    totalReportsData: {}
};

const reportsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPE.REPORTS.GET_BY_EMPLOYEE_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                draft.reportsEmployeeData = data;
            });
        case ACTION_TYPE.REPORTS.GET_BY_CUSTOMER_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                draft.reportsCustomerData = data;
            });
        case ACTION_TYPE.REPORTS.GET_BY_ORDERS_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                draft.reportsOrdersData = data;
            });
        case ACTION_TYPE.REPORTS.GET_TOTAL_SUCCESS:
            return produce(state, draft => {
                const { data } = action;
                draft.totalReportsData = data;
            });

        default:
            return state;
    }
}

export default reportsReducer;
