import React, { useState, useEffect, memo } from 'react';
import { AttachmentFileContainer, Container, PreviewContainer, FileItem, DeleteIcon } from './styles';
import closeIcon from '~/assets/images/close_ic.svg';
import { isEmpty } from '~/utils/helper';
import { DRIVER_IMAGE_URL } from '~/constants/common';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragAndDropWrapper } from '~/components/OrdersStatus/styles';

function AttachmentFile(props) {

    const { files, limitFile = 1, width, height } = props;
    const [imagesBase64, setImagesBase64] = useState([]);
    const [filesState, setFilesState] = useState([]);

    useEffect(() => {
        handleShowFiles(files);
        setFilesState(files);
    }, [files]);

    const onChangeFile = (event) => {
        const file = event.target?.files?.[0];
        props.onChange(file);
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleShowFiles = async (files) => {
        const imagesBase64Temp = [];
        for (let i = 0; i < files.length; i++) {
            if (typeof files[i] !== "string") {
                const result = await toBase64(files[i]);
                imagesBase64Temp.push(result);
            } else {
                imagesBase64Temp.push(`${DRIVER_IMAGE_URL}${files[i]}`);
            }
        }
        setImagesBase64(imagesBase64Temp);
    };

    const renderFiles = () => {
        return imagesBase64.map((file, index) => {
            return (
                <FileItem key={index} width={width} height={height}>
                    <img alt='' src={file} />
                    <DeleteIcon onClick={() => handleDeleteFile(index)} src={closeIcon} />
                </FileItem>
            )
        })
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: 15,
        margin: '10px 0px',
        borderRadius: 5,
        border: '1px solid #e0e0e0',
        background: isDragging && "#e0e0e0e0",
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver) => ({
        padding: '0px 10px',
        width: '100%',
        border: '1px solid #e0e0e0',
        borderRadius: 5
    });

    const onDragEnd = (item) => {
        if (!item.destination) {
            return;
        }
        props.onDragEnd(item);
    }

    const renderFilesDrag = () => {
        return (
            <DragAndDropWrapper>
                <DragDropContext onDragEnd={(item) => onDragEnd(item)}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {imagesBase64.map((file, index) => {
                                    return (
                                        <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <FileItem maxWidth='50%' key={index} width={width} height={height}>
                                                        <img alt='' src={file} />
                                                        <DeleteIcon onClick={() => handleDeleteFile(index)} src={closeIcon} />
                                                    </FileItem>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </DragAndDropWrapper>
        )
    }

    const handleDeleteFile = (index) => {
        const newFiles = filesState.filter(
            (file, indexFile) => indexFile !== index
        );
        props.handleDeleteFile(newFiles);
    };

    return (
        <Container>
            <PreviewContainer>
                {props.hasDrag ? renderFilesDrag() : renderFiles()}
            </PreviewContainer>
            {files.length < limitFile && (
                <AttachmentFileContainer>
                    <input type="file" onChange={onChangeFile} accept=".jpg, .jpeg, .png" />
                    <div>
                        +
                    </div>
                </AttachmentFileContainer>
            )}
        </Container>
    )
}

export default memo(AttachmentFile);
