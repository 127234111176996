import React from 'react';
// components
import { Container } from '~/pages/Customer/List/styles';
import Dashboard from '~/components/Dashboard';
import Card from '~/components/Card';
import AddOrdersComponent from '~/components/Orders/Add';

export default function AddOrdersPage(props) {

    return (
        <Container>
            <Dashboard>
                <Card>
                    <AddOrdersComponent {...props} />
                </Card>
            </Dashboard>
        </Container>
    );
}
