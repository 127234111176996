import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const SelectValueContainer = styled.div`
`;

export const RecipientAmountPayment = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`

export const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
`
export const NoteMessage = styled.div`
    font-size: 13px;
    margin-top: 5px;
`
