export default {
    translation: {
        common: {
            create_orders: 'Tạo đơn',
            delivery: 'Vận chuyển',
            support: 'Hỗ trợ',
            no_data: 'Không có dữ liệu',
            total: 'Tổng',
            sign_in: 'Đăng nhập',
            sign_up: 'Đăng ký',
            search: 'Tìm kiếm',
            close: 'Đóng',
            agree: 'Đồng ý',
            success: 'Thành công',
            fail: 'Thất bại',
            delete_data: 'Xóa dữ liệu',
            delete_data_confirm: 'Bạn có chắc chắn muốn xóa dữ liệu này không? Việc xóa dữ liệu sẽ không thể khôi phục lại.',
            admin_page: 'Trang quản trị',
            full_name: 'Họ và tên',
            day_of_birth: 'Ngày sinh',
            gender: 'Giới tính',
            phone_number: 'Số điện thoại',
            email: 'Email',
            emergency_phone: 'Số điện thoại khẩn cấp',
            province: 'Tỉnh/Thành',
            district: 'Quận/Huyện',
            ward: 'Phường/Xã',
            address: 'Địa chỉ',
            address_default: 'Địa chỉ mặc định',
            note: 'Ghi chú',
            go_back: 'Quay lại',
            reset_input: 'Nhập lại',
            save: 'Lưu',
            my_profile: 'Thông tin cá nhân',
            my_bank: 'Thông tin thanh toán',
            my_profile_user: 'Hồ sơ của tôi',
            update: 'Cập nhật',
            edit_my_profile_success: 'Chỉnh sửa thông tin thành công',
            back_home: 'Quay về trang chủ',
            create: 'Tạo mới',
            currency_vnd: 'VND',
            fromDate: 'Từ ngày',
            toDate: 'Đến ngày',
            cancel: 'Hủy bỏ',
            bank_name: 'Tên chủ thẻ',
            bank_number: 'Số thẻ',
            bank_branch: 'Chi nhánh',
            other: 'Khác',
            add_note: 'Thêm ghi chú',
            note: 'Ghi chú',
            color: 'Màu sắc',
            orders_management: 'Q.lý đơn hàng',
            next: 'Tiếp theo',
            back: 'Quay lại',
            update_success: 'Chỉnh sửa thành công',
            show_more_fileds: 'TÌM KIẾM NÂNG CAO',
            hide_more_fileds: 'ẨN TÌM KIẾM NÂNG CAO',
            editing: 'Chỉnh sửa',
            print: 'In đơn',
            not_found: {
                title: 'Trang này không tồn tại',
                description: 'Trang bạn đang tìm kiếm không có sẵn, vui lòng kiểm tra lại!',
            },
            no_permission: {
                title: 'Quyền truy cập bị từ chối',
                description: 'Bạn không có quyền xem trang thông tin này',
            },
            required: {
                full_name: 'Vui lòng nhập họ tên',
                day_of_birth: 'Vui lòng chọn ngày sinh',
                gender: 'Vui lòng chọn giới tính',
                phone_number: 'Vui lòng nhập số điện thoại',
                email: 'Vui lòng nhập địa chỉ email',
                emergency_phone: 'Vui lòng nhập số điện thoại khẩn cấp',
                province: 'Vui lòng chọn Tỉnh/Thành',
                district: 'Vui lòng chọn Quận/Huyện',
                ward: 'Vui lòng chọn Phường/Xã',
                address: 'Vui lòng nhập địa chỉ',
                note: 'Vui lòng nhập ghi chú',
                customer_type: 'Vui lòng chọn loại khách hàng',
                bank_name: 'Vui lòng nhập tên chủ thẻ',
                bank_number: 'Vui lòng nhập số thẻ',
                bank_branch: 'Vui lòng nhập chi nhánh',
                date: 'Vui lòng chọn thời gian',
                orders_status: 'Vui lòng chọn trạng thái đơn hàng'
            },
            province: 'Chọn Tỉnh/Thành',
            district: 'Chọn Quận/Huyện',
            ward: 'Chọn Phường/Xã',
            orders: {
                heading_title: 'Quản lý đơn hàng',
                code: 'Mã đơn hàng',
                status: 'Trạng thái đơn hàng',
                name: 'Đơn hàng'
            },
            customer: {
                heading_title: 'Quản lý khách hàng',
                name: 'Khách hàng'
            },
            employee: {
                heading_title: 'Quản lý nhân viên',
                name: 'Nhân viên'
            },
            settings: {
                heading_title: 'Cài đặt',
                logo_image: 'Hình ảnh thương hiệu',
                slider_image: 'Hình ảnh trang bìa quảng cáo',
                login_image: 'Hình ảnh trang đăng nhập',
                appearance: {
                    '1': '(Nên chọn ảnh có kích thước 300x90)',
                    '2': '(Nên chọn ảnh có kích thước 1366x587)',
                    '3': '(Nên chọn ảnh có kích thước 320x420)'
                }
            },
            account_status: {
                active: 'Hoạt động',
                waiting_verify: 'Chưa kích hoạt',
                inactive: 'Ngừng hoạt động'
            },
            customer_type: {
                partner: 'Đối tác',
                other: 'Khách vãng lai'
            },
            expired_token: 'Hết hạn phiên đăng nhập',
            login_again: 'Vui lòng đăng nhập lại'
        },
        dashboard: {
            sidebar: {
                menu: {
                    orders_management: 'Quản lý đơn hàng',
                    customer_management: 'Quản lý khách hàng',
                    employee_management: 'Quản lý nhân viên',
                    settings: 'Cài đặt',
                    reports: 'Thống kê'
                },
                submenu: {
                    orders_status: 'Trạng thái đơn hàng',
                    appearance: 'Giao diện',
                    page_static: 'Trang tĩnh'
                }
            }
        },
        home: {
            about_us: 'Về chúng tôi',
            service: 'Dịch vụ',
            contact: 'Liên hệ',
            content_title: 'Đối tác giao hàng tin cậy',
            content: {
                content1: {
                    title: 'Giao hàng tốc hành, tỷ lệ hoàn hàng thấp',
                    message: 'Giao hàng nhanh, giảm thiểu rủi ro đình trệ và hoàn hàng.'
                },
                content2: {
                    title: 'Giao hàng an toàn, tiết kiệm chi phí',
                    message: 'Với tiêu chuẩn vận chuyển nghiêm ngặt, VivuShip đảm bảo đơn hàng được giao với chi phí hợp lý nhất.'
                },
                content3: {
                    title: 'Đối soát trả tiền nhanh chóng và chính xác',
                    message: 'Hỗ trợ giao hàng thu tiền hộ, thời gian hoàn tiền về người bán ngắn, mức độ tin cậy cao.'
                },
                content4: {
                    title: 'Đội ngũ nhân viên chuyên nghiệp, tận tâm',
                    message: 'Các nhân viên của VivuShip luôn nỗ lực cố gắng để đáp ứng kịp thời các nhu cầu từ khách hàng.'
                }
            },
            order: {
                create_order_with_us: 'Tạo đơn ngay với VivuShip',
                create_order: 'Tạo đơn ngay'
            },
            search: {
                input_order_info: 'Nhập mã đơn hàng bạn cần tra cứu'
            },
            banner: {
                slogan: 'An toàn, nhanh chóng, đáng tin cậy.',
                start_create_order: 'Bắt đầu tạo đơn',
                message: 'Tạo đơn hàng dễ dàng và nhanh chóng, không cần thủ tục rờm rà'
            },
            footer: {
                text1: '©️2022 Bản quyền thuộc về Công ty TNHH Chuyển Phát Nhanh VIVUSHIP',
                text2: 'Chính sách | Điều khoản & Điều kiện | Bảo mật & Quyền riêng tư | Hỗ trợ | Sitemap',
                policy: 'Chính sách |',
                term_and_conditions: 'Điều khoản & Điều kiện |',
                security_and_privacy: 'Bảo mật & Quyền riêng tư |',
                support: 'Hỗ trợ'
            },
            empty_result: 'Không có kết quả phù hợp'
        },
        auth: {
            auth_login_message: 'Đăng nhập để sử dụng và theo dõi quản lý đơn hàng chi tiết, chính xác hơn',
            auth_signup_message: 'Đăng ký để sử dụng dịch vụ của chúng tôi',
            login: {
                email: 'Email',
                email_required_message: 'Vui lòng nhập email',
                email_format_message: 'Vui lòng nhập đúng định dạng Email',
                password: 'Mật khẩu',
                password_required_message: 'Vui lòng nhập mật khẩu',
                password_remember: 'Ghi nhớ mật khẩu',
                forgot_password: 'Quên mật khẩu',
                no_account: 'Chưa có tài khoản,',
                now: 'ngay',
                User_Is_Not_Exist: 'Email không tồn tại',
                Password_Is_Incorrect: 'Mật khẩu không đúng',
                User_Is_Waiting_Activate: 'Tài khoản đang chờ kích hoạt',
                User_Is_Inactive: 'Tài khoản không hoạt động',
                activate_success: 'Tài khoản của bạn đã được kích hoạt thành công !',
                Email_Is_Not_Exist: 'Email không tồn tại',
                Token_Expired: 'Yêu cầu đã hết hạn',
            },
            sign_up: {
                phone_number: 'Số điện thoại',
                phone_number_required_message: 'Vui lòng nhập số điện thoại',
                password_confirm: 'Nhập lại mật khẩu',
                password_confirm_required_message: 'Vui lòng nhập mật khẩu',
                account: 'Đã có tài khoản,',
                password_six_char: 'Mật khẩu tối thiểu phải có 6 ký tự',
                password_confirm_incorrect: 'Mật khẩu không trùng khớp',
                phone_number_incorrect_format: 'Vui lòng nhập đúng định dạng số điện thoại',
                Email_Address_Already_Exists: 'Email đã tồn tại',
                Phone_Number_Already_Exists: 'Số điện thoại đã tồn tại',
                Emergency_Phone_Already_Exists: 'Số điện thoại khẩn cấp đã tồn tại',
                Send_Email_For_Active_Fail: 'Gửi Email kích hoạt lỗi',
                sign_up_success_message: 'Vui lòng kiểm tra Email của bạn để kích hoạt tài khoản',
                sign_up_success: 'Đăng ký thành công',
                Invalid_Validate_Field: 'Vui lòng nhập đầy đủ thông tin',
                Data_Is_Exist: 'Dữ liệu đã tồn tại'
            },
            logout: 'Đăng xuất',
            change_password: 'Đổi mật khẩu',
            forgot_password: {
                title: 'Quên mật khẩu',
                send: 'Gửi đi',
                message_tutorial: 'Nhập email của bạn vào bên dưới để nhận hướng dẫn đặt lại mật khẩu',
                send_success_title: 'Gửi yêu cầu thành công',
                send_success_message: 'Vui lòng kiểm tra email của bạn và làm theo hướng dẫn',
                reset_password: 'Đặt lại mật khẩu',
                reset_password_message: 'Mật khẩu mới của bạn phải khác với mật khẩu đã sử dụng trước đó',
                new_password: 'Mật khẩu mới',
                new_password_confirm: 'Nhập lại mật khẩu mới',
                reset_text: 'Đặt lại mật khẩu',
                reset_success_title: 'Đặt lại mật khẩu thành công',
                reset_success_message: 'Bạn có thể đăng nhập bằng mật khẩu mới',
                go_back_login: 'Quay về'
            },
            old_password: 'Mật khẩu cũ',
            old_password_required: 'Vui lòng nhập mật khẩu cũ',
            Old_Password_Is_Incorrect: 'Mật khẩu cũ không đúng',
            change_password_success: 'Đổi mật khẩu thành công'
        },
        employee: {
            create: 'Tạo nhân viên',
            edit: 'Chỉnh sửa nhân viên',
            code: 'Mã nhân viên',
            name: 'Tên nhân viên',
            required: {
                code: 'Vui lòng nhập mã nhân viên',
                name: 'Vui lòng nhập tên nhân viên'
            },
            create_success: 'Tạo nhân viên thành công',
            upadte_success: 'Chỉnh sửa nhân viên thành công',
            status: 'Trạng thái nhân viên'
        },
        customer: {
            edit: 'Chỉnh sửa khách hàng',
            code: 'Mã khách hàng',
            name: 'Tên khách hàng',
            type: 'Loại khách hàng',
            status: 'Trạng thái khách hàng',
            required: {
                code: 'Vui lòng nhập mã nhân viên',
                name: 'Vui lòng nhập tên nhân viên'
            },
            create_success: 'Tạo nhân viên thành công',
            update_success: 'Chỉnh sửa khách hàng thành công'
        },
        export: {
            success: 'Xuất tập tin danh sách thành công',
            error: 'Xuất tập tin danh sách thất bại',
        },
        dialog: {
            orders_status: {
                title: 'Tạo mới trạng thái đơn hàng',
                default_title: 'Trạng thái đơn hàng',
                name: 'Tên trạng thái',
                placeholder: 'Vui lòng nhập tên trạng thái',
                require_take_picture: 'Yêu cầu chụp ảnh',
                add_success: 'Tạo mới trạng thái đơn hàng thành công',
                delete_success: 'Xóa trạng thái đơn hàng thành công',
                update_success: 'Cập nhật trạng thái đơn hàng thành công',
                required: {
                    name: 'Vui lòng nhập tên trạng thái',
                },
                note: 'Giữ, kéo và thả để sắp xếp thứ tự của trạng thái đơn hàng'
            }
        },
        orders: {
            create: 'Tạo đơn hàng',
            edit: 'Chỉnh sửa đơn hàng',
            pickup_address: 'Địa chỉ lấy hàng',
            postal_code: 'Mã bưu chính',
            pickup_date: 'Chọn thời gian lấy hàng',
            required: {
                postal_code: 'Vui lòng nhập mã bưu chính',
                pickup_date: 'Vui lòng chọn thời gian lấy hàng',
                weight: 'Vui lòng nhập khổi lượng đơn hàng',
                name: 'Vui lòng nhập tên sản phẩm',
                quantity: 'Vui lòng nhập số lượng',
                long: 'Nhập chiều dài',
                width: 'Nhập chiều rộng',
                height: 'Nhập chiều cao',
                total_value: 'Vui lòng nhập tổng giá trị đơn hàng',
                shipping_fee: 'Vui lòng nhập phí vận chuyển',
                shipper: 'Chọn nhân viên vận chuyển',
                status: 'Chọn trạng thái đơn',
                status_input: 'Vui lòng chọn trạng thái đơn'
            },
            infor: 'Thông tin đơn hàng',
            shipment_info: 'Thông tin giao hàng',
            weight: 'Khối lượng (kg)',
            size: 'Kích thước (cm)',
            name: 'Tên sản phẩm',
            quantity: 'Số lượng',
            shipping_fee: 'Tiền vận chuyển tạm tính',
            service_info: 'Thông tin gói cước',
            total_value: 'Tổng giá trị đơn hàng',
            recipient_amount_payment: 'Tiền thu khách',
            service_info_message: 'Người giao hàng sẽ thu Người Nhận số tiền này khi giao hàng',
            shipping_fee_payment: 'Cước phí vận chuyển',
            cod: 'Tiền thu hộ',
            guaranteed: 'Gửi đảm bảo',
            sender_pay: 'Người gửi trả phí',
            receiver_pay: 'Người nhận trả phí',
            yes: 'Có',
            no: 'Không',
            input_shipping_fee_auto: 'Tự động',
            input_shipping_fee: 'Tự nhập phí vận chuyển',
            create_success: 'Tạo đơn hàng thành công',
            status: 'Trạng thái đơn hàng',
            status_schedule: 'Lịch trình di chuyển',
            shipper_info: 'Thông tin nhân viên vận chuyển',
            shipper: 'Nhân viên vận chuyển',
            update_success: 'Chỉnh sửa đơn hàng thành công',
            delete_success: 'Xóa đơn hàng thành công',
            update_now: 'Xem và cập nhật',
            create_orders_with_other_success: 'Tạo đơn hàng thành công. Thông tin đơn hàng chúng tôi đã gửi đến Email của bạn. Vui lòng đợi trong giây lát để chúng tôi xác nhận',
            create_new: 'Tạo đơn mới',
            sender: 'Người gửi',
            receiver: 'Người nhận',
            sdt: 'SĐT',
            orders_code: 'Mã đơn',
            shipper: 'Shipper',
            total_value: 'Tổng đơn',
            orders_list: 'Danh sách đơn hàng',
            status: 'Trạng thái đơn',
            update_status: 'Cập nhật trạng thái đơn hàng',
            delivery_picture: 'Hình ảnh giao hàng',
            remove_picture: '[Xóa ảnh]',
            cancel_picture: '[Hủy bỏ]',
            click_to_take_picture: '[Nhấn vào để chụp]',
            message_required_take_picture: 'Giao hàng thành công yêu cầu chụp hình',
            search: 'Tìm kiếm theo tên hoặc SĐT người nhận',
            my_orders: 'Đơn hàng của tôi',
            delivered: 'Đơn hàng đã giao',
            search_by_info: 'Tìm kiếm theo thông tin người nhận hoặc người gửi'
        },
        static_page: {
            'about-us': 'Về chúng tôi',
            services: 'Dịch vụ',
            contact: 'Liên hệ',
            policy: 'Chính sách',
            'terms-and-conditions': 'Điều khoản & Điều kiện',
            'security-and-privacy': 'Bảo mật & Quyền riêng tư',
            support: 'Hỗ trợ',
            transport: 'Vận chuyển',
            edit: 'Chỉnh sửa trang',
            name: 'Tên trang',
            content: 'Nội dung'
        },
        report: {
            employee: {
                total: 'Tổng số nhân viên',
                toltal_active: 'Tổng số nhân viên đang hoạt động',
                toltal_inactive: 'Tổng số nhân viên ngừng hoạt động',
                toltal_waiting_verify: 'Tổng số nhân viên chưa hoạt động'
            },
            customer: {
                total: 'Tổng số khách hàng',
                total_other: 'Tổng số khách vãng lai',
                total_partner: 'Tổng số đối tác',
                total_partner_active: 'Tổng số đối tác đang hoạt động',
                total_partner_inactive: 'Tổng số đối tác ngừng hoạt động',
                total_partner_waiting_verify: 'Tổng số đối tác chưa hoạt động',
            },
            orders: {
                total: 'Tổng số đơn hàng',
            },
            turnover: 'Doanh thu',
            total_shipping_revenue: 'Tổng doanh thu vận chuyển',
            total_order_revenue: 'Tổng giá trị đơn hàng vận chuyển'
        },
        print: {
            phone: 'Điện thoại',
            date: 'Ngày phát',
            amount: 'Tiền thu hộ',
            instruction: 'Chỉ dẫn khi phát',
            date_full: 'Ngày ..... tháng ..... năm .....'
        }
    }
}
