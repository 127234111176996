import {
    createGlobalStyle,
    keyframes
} from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons';
import { Colors } from '~/constants/styles';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`

// Theme.js
const darkTheme = {
    textColor: "#d9d9d9",
    background: "#26242E"
};

const lightTheme = {
    textColor: "#26242E",
    background: "#FFFFFF"
};

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Poppins&display=swap');

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    body {
        -webkit-font-smoothing: antialiased;
        font: 13px 'Montserrat';
    }

    body {
        background-color: ${({ theme }) => theme.background};
        color: ${({ theme }) => theme.textColor};
    }

    span, p, i {
        color: ${Colors.dark};
        font-weight: 500;
    }

    input, button, p, i {
        animation: ${fadeIn} .5s linear;
    }

    #root {
        width: 100%;
        height: 100vh;
    }

    button {
        cursor: pointer;
    }

    .form-check {
        display: flex;
        align-items: center;
    }

    .form-check-input:checked {
        background-color: ${Colors.primary};
        border-color: ${Colors.primary};
    }

    .form-check-input {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        cursor: pointer;
    }

    .form-control {
        margin-top: 10px;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: #ffffff;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #9da2d1;
        border-radius: 5px;
    }

    .dialogMessage {
        margin-top: 12%;
        .modal-content {
            border-radius: 10px;
            border: none;
        }
    }

    .dialogComponent {
        max-width: 80%;
        .modal-content {
            border-radius: 10px;
            border: none;
        }
    }

    @media only screen and (max-width: 600px) {
        .dialogMessage {
            margin: 0 auto;
            margin-top: 45%;
        }

        .dialogComponent {
            margin-top: 15%;
            max-width: 90%;
            margin-left: 5%;
        }
    }
`;

export {
    GlobalStyle,
    lightTheme,
    darkTheme
}