import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';

export const MenuWrapper = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    width: 220px;
    justify-content: space-between;
    margin-right: 50px;
    @media ${DEVICE_RESPONSIVE.mobile} {
        display: none;
    }
`;

export const MenuItem = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    cursor: pointer;

    span {
        margin-left: 5px;
        font-weight: bold;
        font-size: 14px;
    }
`;