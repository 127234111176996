import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const StatusContainer = styled.div`
    height: 30px;
    padding: 0px 15px;
    background: ${(props) => props.background || Colors.dark};
    border: ${(props) => props.borderColor ? `2px solid ${props.borderColor}` : `2px solid ${Colors.dark}`};
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${(props) => props.marginLeft || '0px'};
    span {
        font-size: 14px;
        font-weight: 500;
        color: ${(props) => props.color || '#ffffff'}
    }

    @media ${DEVICE_RESPONSIVE.mobile} {
        margin-left: 0px;
        margin: 10px 0px;
        width: 200px;
    }
`;