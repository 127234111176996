import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const ProfileContainer = styled.div`
    position: relative;
    &:hover .menu-drop {
        display: flex;
        transition: 1s;
    }
`;

export const ProfileWrapper = styled.div`
    cursor: pointer;
    display: flex;
    padding: 10px 0px;

    img {
        height: 34px;
        width: 34px;
        border-radius: 34px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    div {
        display: flex;
        align-content: center;
        flex-direction: column;
        margin-left: 15px;
        justify-content: center;

        span:nth-child(1) {
            font-size: 14px;
            font-weight: 600;
        }

        span:nth-child(2) {
            font-size: 12px;
            font-weight: 500;
        }
    }
`;

export const ArrowUp = styled.div`
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #ffffff;
    margin: 0 auto;
    position: absolute;
    top: -15px;
    filter: drop-shadow(0px 2px 2px rgba(0,0,0,0.1));
`;

export const ProfileMenu = styled.div`
    position: absolute;
    top: 55px;
    background: white;
    width: 196px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0px;
    transition: 1s;
    display: none;
    padding-left: 6px;
    z-index: 99;
    left: 10px;
`;

export const ProfileItem = styled.div`
    color: ${Colors.dark};
    font-size: 15px;
    list-style: none;
    padding: 4px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;

    div {
        margin-left: 8px;
    }
`;