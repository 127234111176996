import { COOKIE_KEY } from "~/constants/common";
import { ROUTE } from "~/constants/routes";
import { handleGetCookies } from "~/utils/cookies";
import { redirectTo } from "./navigations";

/**
 * Checks if user is authenticated
 */
const checkUserAuthenticated = (auth, roles = []) => {
    const userInfo = handleGetCookies(COOKIE_KEY.LOGIN);
    if (!userInfo && auth) {
        redirectTo(ROUTE.HOME);
    } else if (userInfo && auth) {
        if (roles.length) {
            const { UserRole: { RoleType: { name: roleName = '' } = {} } = {} } = userInfo || {};
            const isHavePermission = roles.includes(roleName);
            if (!isHavePermission) {
                redirectTo(ROUTE.NO_PERMISSION);
            }
        }
    }
};

export {
    checkUserAuthenticated,
};
