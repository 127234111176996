import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MobileTopHeaderContainer, Logo, ListMenu, ItemMenu, Mask } from './styles'
// icons
import logo from '~/assets/images/home/home-logo.svg';
import arrowDownIcon from '~/assets/images/arrow_down_ic.svg';
// components
import UserProfile from '~/components/UserProfile';
import Icon from '~/components/Icon';
// utils
import { redirectTo } from '~/utils/navigations';
import { handleGetCookies,  } from '~/utils/cookies';
import { isEmpty } from '~/utils/helper';
// constant
import { Colors } from '~/constants/styles';
import { ROUTE } from '~/constants/routes';
import { COOKIE_KEY, DRIVER_IMAGE_URL, SETTINGS_KEY } from '~/constants/common';

function MobileTopHeader() {

    const [isToggle, setIsToggle] = useState(false);

    const { t } = useTranslation();
    const {
        settings: { settings = {} },
    } = useSelector(state => {
        return state;
    });

    const userInfo = handleGetCookies(COOKIE_KEY.LOGIN);

    const onRedireactToStaticPage = (key) => {
        setIsToggle(false);
        redirectTo(`${ROUTE.SETTINGS.STATIC_PAGE_FOR_USER}/${key}`)
    }

    const renderLogo = () => {
        const logoSetting = settings.find(setting => setting.key === SETTINGS_KEY.BRAND_IMAGE);
        if (logoSetting?.value) {
            return `${DRIVER_IMAGE_URL}${logoSetting.value}`
        } else {
            return logo;
        }
    }

    return (
        <MobileTopHeaderContainer>
            <nav className={`nav-bar ${isToggle && 'toggle'}`}>
                <div className="toggle-menu" onClick={() => setIsToggle(!isToggle)}>
                    <div className="line line1"></div>
                    <div className="line line2"></div>
                    <div className="line line3"></div>
                </div>
                <Logo src={renderLogo()} onClick={() => { redirectTo(ROUTE.HOME) }} />
                <ListMenu>
                    <ItemMenu onClick={() => onRedireactToStaticPage(SETTINGS_KEY.ABOUT_US)}>
                        <a>{t('home.about_us')}</a>
                        {/* <img src={arrowDownIcon} alt=''/> */}
                    </ItemMenu>
                    <ItemMenu onClick={() => onRedireactToStaticPage(SETTINGS_KEY.SERVICES)}>
                        <a>{t('home.service')}</a>
                        {/* <img src={arrowDownIcon} alt=''/> */}
                    </ItemMenu>
                    <ItemMenu onClick={() => onRedireactToStaticPage(SETTINGS_KEY.CONTACT)}>
                        <a>{t('home.contact')}</a>
                        {/* <img src={arrowDownIcon} alt=''/> */}
                    </ItemMenu>
                    {isEmpty(userInfo) ? (
                        <>
                            <ItemMenu onClick={() => redirectTo(ROUTE.AUTH.SIGN_UP)}>
                                <a href="#">{t('common.sign_up')}</a>
                            </ItemMenu>
                            <ItemMenu onClick={() => redirectTo(ROUTE.AUTH.LOGIN)}>
                                <a href="#">{t('common.sign_in')}</a>
                            </ItemMenu>
                        </>
                    ) : (
                        <UserProfile />
                    )}
                </ListMenu>
            </nav>
            <Mask isToggle={isToggle} onClick={() => setIsToggle(false)}></Mask>
        </MobileTopHeaderContainer>
    )
}

export default MobileTopHeader
