export default {
    translation: {
        common: {
            create_orders: 'Tạo đơn',
            delivery: 'Vận chuyển',
            support: 'Hỗ trợ',
            no_data: 'Không có dữ liệu',
            total: 'Tổng',
            sign_in: 'Đăng nhập',
            sign_up: 'Đăng ký',
            search: 'Tìm kiếm',
            close: 'Đóng',
            agree: 'Đồng ý',
            success: 'Thành công',
            fail: 'Thất bại',
            delete_data: 'Xóa dữ liệu',
            delete_data_confirm: 'Bạn có chắc chắn muốn xóa dữ liệu này không? Việc xóa dữ liệu sẽ không thể khôi phục lại.',
            admin_page: 'Trang quản trị',
            full_name: 'Họ và tên',
            day_of_birth: 'Ngày sinh',
            gender: 'Giới tính',
            phone_number: 'Số điện thoại',
            email: 'Email',
            emergency_phone: 'Số điện thoại khẩn cấp',
            province: 'Tỉnh/Thành',
            district: 'Quận/Huyện',
            ward: 'Phường/Xã',
            address: 'Địa chỉ',
            go_back: 'Quay lại',
            reset_input: 'Nhập lại',
            save: 'Lưu',
            my_profile: 'Thông tin cá nhân',
            my_profile_user: 'Hồ sơ của tôi',
            update: 'Cập nhật',
            edit_my_profile_success: 'Chỉnh sửa thông tin thành công',
            back_home: 'Quay về trang chủ',
            create: 'Tạo mới',
            not_found: {
                title: 'Trang này không tồn tại',
                description: 'Trang bạn đang tìm kiếm không có sẵn, vui lòng kiểm tra lại!',
            },
            no_permission: {
                title: 'Quyền truy cập bị từ chối',
                description: 'Bạn không có quyền xem trang thông tin này',
            },
            required: {
                full_name: 'Vui lòng nhập họ tên',
                day_of_birth: 'Vui lòng chọn ngày sinh',
                gender: 'Vui lòng chọn giới tính',
                phone_number: 'Vui lòng nhập số điện thoại',
                email: 'Vui lòng nhập địa chỉ email',
                emergency_phone: 'Vui lòng nhập số điện thoại khẩn cấp',
                province: 'Vui lòng chọn Tỉnh/Thành',
                district: 'Vui lòng chọn Quận/Huyện',
                ward: 'Vui lòng chọn Phường/Xã',
                address: 'Vui lòng nhập địa chỉ',
                customer_type: 'Vui lòng chọn loại khách hàng',
            },
            orders: {
                heading_title: 'Quản lý đơn hàng'
            },
            customer: {
                heading_title: 'Quản lý khách hàng'
            },
            employee: {
                heading_title: 'Quản lý nhân viên'
            },
            settings: {
                heading_title: 'Cài đặt',
                logo_image: 'Hình ảnh thương hiệu',
                slider_image: 'Hình ảnh trang bìa quảng cáo',
                login_image: 'Hình ảnh trang đăng nhập',
            },
            account_status: {
                active: 'Hoạt động',
                waiting_verify: 'Chưa kích hoạt',
                inactive: 'Ngừng hoạt động'
            },
            customer_type: {
                partner: 'Đối tác',
                other: 'Khách vãng lai'
            },
        },
        dashboard: {
            sidebar: {
                menu: {
                    orders_management: 'Quản lý đơn hàng',
                    customer_management: 'Quản lý khách hàng',
                    employee_management: 'Quản lý nhân viên',
                    settings: 'Cài đặt'
                },
                submenu: {
                    orders_status: 'Trạng thái đơn hàng',
                    appearance: 'Giao diện',
                    page_static: 'Trang tĩnh'
                }
            }
        },
        home: {
            about_us: 'Về chúng tôi',
            service: 'Dịch vụ',
            contact: 'Liên hệ',
            content_title: 'Đối tác giao hàng tin cậy',
            content: {
                content1: {
                    title: 'Giao hàng tốc hành, tỷ lệ hoàn hàng thấp',
                    message: 'Giao hàng nhanh, giảm thiểu rủi ro đình trệ và hoàn hàng.'
                },
                content2: {
                    title: 'Giao hàng an toàn, tiết kiệm chi phí',
                    message: 'Với tiêu chuẩn vận chuyển nghiêm ngặt, VivuShip đảm bảo đơn hàng được giao với chi phí hợp lý nhất.'
                },
                content3: {
                    title: 'Đối soát trả tiền nhanh chóng và chính xác',
                    message: 'Hỗ trợ giao hàng thu tiền hộ, thời gian hoàn tiền về người bán ngắn, mức độ tin cậy cao.'
                },
                content4: {
                    title: 'Đội ngũ nhân viên chuyên nghiệp, tận tâm',
                    message: 'Các nhân viên của VivuShip luôn nỗ lực cố gắng để đáp ứng kịp thời các nhu cầu từ khách hàng.'
                }
            },
            order: {
                create_order_with_us: 'Tạo đơn ngay với VivuShip',
                create_order: 'Tạo đơn ngay'
            },
            search: {
                input_order_info: 'Nhập mã đơn hàng bạn cần tra cứu'
            },
            banner: {
                slogan: 'An toàn, nhanh chóng, đáng tin cậy.',
                start_create_order: 'Bắt đầu tạo đơn',
                message: 'Tạo đơn hàng dễ dàng và nhanh chóng, không cần thủ tục rờm rà'
            },
            footer: {
                text1: '©️2022 Bản quyền thuộc về Công ty TNHH Chuyển Phát Nhanh VIVUSHIP',
                text2: 'Chính sách | Điều khoản & Điều kiện | Bảo mật & Quyền riêng tư | Hỗ trợ | Sitemap'
            }
        },
        auth: {
            auth_login_message: 'Đăng nhập để sử dụng và theo dõi quản lý đơn hàng chi tiết, chính xác hơn',
            auth_signup_message: 'Đăng ký để sử dụng dịch vụ của chúng tôi',
            login: {
                email: 'Email',
                email_required_message: 'Vui lòng nhập email',
                email_format_message: 'Vui lòng nhập đúng định dạng Email',
                password: 'Mật khẩu',
                password_required_message: 'Vui lòng nhập mật khẩu',
                password_remember: 'Ghi nhớ mật khẩu',
                forgot_password: 'Quên mật khẩu',
                no_account: 'Chưa có tài khoản,',
                now: 'ngay',
                User_Is_Not_Exist: 'Email không tồn tại',
                Password_Is_Incorrect: 'Mật khẩu không đúng',
                User_Is_Waiting_Activate: 'Tài khoản đang chờ kích hoạt',
                User_Is_Inactive: 'Tài khoản không hoạt động',
                activate_success: 'Tài khoản của bạn đã được kích hoạt thành công !',
                Email_Is_Not_Exist: 'Email không tồn tại',
                Token_Expired: 'Yêu cầu đã hết hạn'
            },
            sign_up: {
                phone_number: 'Số điện thoại',
                phone_number_required_message: 'Vui lòng nhập số điện thoại',
                password_confirm: 'Nhập lại mật khẩu',
                password_confirm_required_message: 'Vui lòng nhập mật khẩu',
                account: 'Đã có tài khoản,',
                password_six_char: 'Mật khẩu tối thiểu phải có 6 ký tự',
                password_confirm_incorrect: 'Mật khẩu không trùng khớp',
                phone_number_incorrect_format: 'Vui lòng nhập đúng định dạng số điện thoại',
                Email_Address_Already_Exists: 'Email đã tồn tại',
                Phone_Number_Already_Exists: 'Số điện thoại đã tồn tại',
                Send_Email_For_Active_Fail: 'Gửi Email kích hoạt lỗi',
                sign_up_success_message: 'Vui lòng kiểm tra Email của bạn để kích hoạt tài khoản',
                sign_up_success: 'Đăng ký thành công'
            },
            logout: 'Đăng xuất',
            change_password: 'Đổi mật khẩu',
            forgot_password: {
                title: 'Quên mật khẩu',
                send: 'Gửi đi',
                message_tutorial: 'Nhập email của bạn vào bên dưới để nhận hướng dẫn đặt lại mật khẩu',
                send_success_title: 'Gửi yêu cầu thành công',
                send_success_message: 'Vui lòng kiểm tra email của bạn và làm theo hướng dẫn',
                reset_password: 'Đặt lại mật khẩu',
                reset_password_message: 'Mật khẩu mới của bạn phải khác với mật khẩu đã sử dụng trước đó',
                new_password: 'Mật khẩu mới',
                new_password_confirm: 'Nhập lại mật khẩu mới',
                reset_text: 'Đặt lại mật khẩu',
                reset_success_title: 'Đặt lại mật khẩu thành công',
                reset_success_message: 'Bạn có thể đăng nhập bằng mật khẩu mới',
                go_back_login: 'Quay về'
            },
            old_password: 'Mật khẩu cũ',
            old_password_required: 'Vui lòng nhập mật khẩu cũ',
            Old_Password_Is_Incorrect: 'Mật khẩu cũ không đúng',
            change_password_success: 'Đổi mật khẩu thành công'
        },
        employee: {
            create: 'Tạo nhân viên',
            edit: 'Chỉnh sửa nhân viên',
            code: 'Mã nhân viên',
            name: 'Tên nhân viên',
            required: {
                code: 'Vui lòng nhập mã nhân viên',
                name: 'Vui lòng nhập tên nhân viên'
            },
            create_success: 'Tạo nhân viên thành công',
            upadte_success: 'Chỉnh sửa nhân viên thành công'
        },
        customer: {
            code: 'Mã khách hàng',
            name: 'Tên khách hàng',
            type: 'Loại khách hàng',
            required: {
                code: 'Vui lòng nhập mã nhân viên',
                name: 'Vui lòng nhập tên nhân viên'
            },
            create_success: 'Tạo nhân viên thành công'
        },
        export: {
            success: 'Xuất tập tin danh sách thành công',
            error: 'Xuất tập tin danh sách thất bại',
        },
        dialog: {
            orders_status: {
                title: 'Tạo mới trạng thái đơn hàng',
                name: 'Tên trạng thái',
                placeholder: 'Vui lòng nhập tên trạng thái',
                require_take_picture: 'Yêu cầu chụp ảnh',
                add_success: 'Tạo mới trạng thái đơn hàng thành công',
                delete_success: 'Xóa trạng thái đơn hàng thành công',
                update_success: 'Cập nhật trạng thái đơn hàng thành công',
                required: {
                    name: 'Vui lòng nhập tên trạng thái',
                },
                note: 'Giữ, kéo và thả để sắp xếp thứ tự của trạng thái đơn hàng'
            }
        }
    }
}
