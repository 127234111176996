import React from 'react';
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
// icons
import banner1 from '~/assets/images/home/banner1.svg';
import banner2 from '~/assets/images/home/banner2.svg';
// components
import RemakeButton from '~/components/RemakeButton';
// styles
import { SlideImg, BannerContainer, SloganAndOrder, SloganText, MessageText, ButtonWrapper } from './styles';
import { DRIVER_IMAGE_URL, SETTINGS_KEY } from '~/constants/common';

const items = [
    {
        src: banner1,
    },
    {
        src: banner2,
    }
];

function BannerComponent(props) {

    const { children, isOpen } = props;
    const { t } = useTranslation();
    const {
        settings: { settings = {} },
    } = useSelector(state => {
        return state;
    });

    const renderSliderImages = () => {
        const logoSetting = settings.find(setting => setting.key === SETTINGS_KEY.BANNER_IMAGES);
        let images = [];
        if (logoSetting?.value) {
            images = logoSetting?.value.split(',').map(item => {
                return {
                    src: `${DRIVER_IMAGE_URL}${item}`
                }
            })
        } else {
            images = items;
        }
        return images;
    }

    const slides = renderSliderImages().map((item) => {
        return (
            <div key={item.src}>
                <SlideImg src={item.src} alt={item.altText} />
            </div>
        );
    });

    const slideSettings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000
    };

    return (
        <BannerContainer>
            <Slider {...slideSettings}>
                {slides}
            </Slider>
            {children}
            <SloganAndOrder>
                <ScrollAnimation animateIn="animate__backInDown" duration={2} animateOnce>
                    <SloganText>
                        {t('home.banner.slogan')}
                    </SloganText>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__backInDown" duration={2} animateOnce>
                    <ButtonWrapper>
                        <RemakeButton
                            type="primary"
                            label={<>{t('home.banner.start_create_order')} {isOpen && <Spinner color="light" children={null} size="sm" />}</>}
                            labelStyles={{ fontSize: 22 }}
                            buttonStyles={{ height: 78, width: 350, borderRadius: 10 }}
                            onClick={props?.onCreateOrders}
                        />
                    </ButtonWrapper>
                    <MessageText>
                        {t('home.banner.message')}
                    </MessageText>
                </ScrollAnimation>
            </SloganAndOrder>
        </BannerContainer>
    )
}

export default BannerComponent
