import { ACTION_TYPE } from '~/constants/actionTypes';

const handleExport = (params) => {
    return { type: ACTION_TYPE.EXPORT.EXPORT_DATA, params };
}

const handleExportSuccess = () => {
    return {
        type: ACTION_TYPE.EXPORT.EXPORT_DATA_SUCCESS,
    };
}

export {
    handleExport,
    handleExportSuccess,
}
