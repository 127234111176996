import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
// styles
import { DialogContainer, DialogTitle, Message, DialogContent, Icon } from './styles';
// icons
import successIcon from '~/assets/images/success_ic_dialog.svg';
import errorsIcon from '~/assets/images/errors_ic.dialog.svg';

function DialogMessage(props) {

    const { t } = useTranslation();
    const {
        width = 350,
        leftLabel = t('common.close'),
        rightLabel = t('common.agree'),
        isOpen,
        leftAction,
        rightAction,
        title,
        message,
        type = 'success'
    } = props;

    return (
        <DialogContainer>
            <Modal isOpen={isOpen} style={{ width }} className='dialogMessage'>
                <DialogContent>
                    <Icon src={type === 'success' ? successIcon : errorsIcon} />
                    <ModalBody>
                        <DialogTitle>{title}</DialogTitle>
                        <Message>{message}</Message>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={leftAction} className='leftButton'>{leftLabel}</Button>{' '}
                        <Button onClick={rightAction} className={type === 'success' ? 'rightButton' : 'rightButtonError'}>{rightLabel}</Button>
                    </ModalFooter>
                </DialogContent>
            </Modal>
        </DialogContainer>
    )
}

export default DialogMessage
