import React from 'react';
import { AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { RadioInputContainer, Label } from './styles'

function RemakeAvRadioInput(props) {
    const { options = [], label, labelStyle, height } = props;

    const renderRadio = () => {
        return options.map(item => {
            const { label, value } = item;
            return (
                <AvRadio key={value} label={label} value={value} />
            )
        })
    }

    return (
        <RadioInputContainer height={height}>
            {label && <Label style={labelStyle && labelStyle}>{label}</Label>}
            <AvRadioGroup {...props} label={undefined}>
                {renderRadio()}
            </AvRadioGroup>
        </RadioInputContainer>
    )
}

export default RemakeAvRadioInput
