import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import Dashboard from '~/components/Dashboard';
import { AvForm } from 'availity-reactstrap-validation';
import Card from '~/components/Card';
import HeadingTitle from '~/components/HeadingTitle';
import { useTranslation } from 'react-i18next';
import Table from '~/components/Table';
import { deleteOrders, getOrders } from '~/modules/orders/actions';
import { addressFormat } from '~/utils/formats';
import DialogMessage from '~/components/DialogMessage';
import RemakeAvField from '~/components/RemakeAvField';
import RemakeButton from '~/components/RemakeButton';
import { Container, RowSearchContainer, SearchContainer, ButtonsContainer, HeadingWrapper } from './styles';
import { isEmpty } from '~/utils/helper';
import { DATE_FORMAT, EXPORT_TYPE, ROLES } from '~/constants/common';
import RemakeSelect from '~/components/RemakeSelect';
import Exports from '~/components/Exports';
import DatePicker from '~/components/DatePicker';
import dayjs from 'dayjs';
import { handleGetOrdersStatuses } from '~/modules/settings/actions';
import { ROUTE } from '~/constants/routes';
import { redirectTo } from '~/utils/navigations';
import { handleGetUsers } from '~/modules/user/actions';
import vi from 'dayjs/locale/vi';
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function OrdersListPage(props) {
    let query = useQuery();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const searchFormRef = useRef(null);
    // Default variable
    const paginationParams = { page: 1, pageSize: 20 };
    const ordersStatusDefault = {
        label: 'Tất cả',
        value: 'All'
    }

    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [ordersSelected, setOrdersSelected] = useState(null);
    const [ordersStatusSelected, setOrdersStatusSelected] = useState(ordersStatusDefault);
    const [employeeSelected, setEmployeeSelected] = useState(ordersStatusDefault);
    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);
    const [isShowMore, setIsShowMore] = useState(false);
    const [searchParams, setSearchParams] = useState({});

    const {
        ordersStatuses = [],
    } = useSelector(state => {
        return state.settings;
    });
    const {
        orders = [],
        total = 0,
    } = useSelector(state => {
        return state.orders;
    });

    const { users = [] } = useSelector(state => {
        return state.user;
    });

    let ordersStatusesFormat = ordersStatuses.length && ordersStatuses.map(item => {
        const { name, id } = item;
        return {
            value: id,
            label: name
        }
    });

    let employeesFormat = users.length && users.map(item => {
        const { fullName, id } = item;
        return {
            value: id,
            label: fullName
        }
    });

    if (ordersStatusesFormat.length) {
        ordersStatusesFormat.unshift(ordersStatusDefault);
    }

    if (employeesFormat.length) {
        employeesFormat.unshift(ordersStatusDefault);
    }

    useEffect(() => {
        dispatch(handleGetOrdersStatuses());
        dispatch(handleGetUsers({ roleType: ROLES.EMPLOYEE }));
        dispatch(getOrders({ page: 1, pageSize: paginationParams.pageSize }));
    }, [])

    useEffect(() => {
        if (!isEmpty(ordersStatuses)) {
            handleGetOrdersListByParams();
        }
    }, [ordersStatuses])

    const handleGetOrdersListByParams = () => {
        const queryParams = query.get('orderStatusId');
        if (!isEmpty(queryParams)) {
            setIsShowMore(true);
            const orderStatusItem = getOrderStatusById(queryParams);
            setOrdersStatusSelected(orderStatusItem);
            const params = {
                page: 1,
                pageSize: paginationParams.pageSize,
                ordersStatus: queryParams,
            }
            dispatch(getOrders(params));
        }
    }

    const getOrderStatusById = (orderStatusId) => {
        return ordersStatusesFormat.find(item => item?.value === orderStatusId)
    }

    const handleClickEdit = (item) => {
        const { id } = item;
        redirectTo(`${ROUTE.ORDERS.EDIT}/${id}`);
    }

    const handleClickDelete = (item) => {
        setOrdersSelected(item);
        setIsOpenDialog(true);
    }

    const handleClickPagination = (item) => {
        const { recordNumberOnPage = 0, paginationSelectedIndex = 0 } = item;
        dispatch(getOrders({ page: paginationSelectedIndex, pageSize: recordNumberOnPage }));
    }

    const handleOkeDialog = () => {
        const { id } = ordersSelected;
        dispatch(deleteOrders({ ordersId: id, page: 1, pageSize: paginationParams.pageSize }));
        setIsOpenDialog(false);
    }

    const onChangeOrdersStatus = (item) => {
        setOrdersStatusSelected(item);
    }

    const onChangeEmployee = (item) => {
        setEmployeeSelected(item);
    }

    // Process data source
    const ordersResults = orders.map(orders => {
        const {
            createdAt,
            statusInfo: { name: statusInfoName = '' } = {},
            shipperInfo: { fullName: fullNameShipper = '' },
            recipientProvinceInfo: { name: recipientProvinceInfoName = '' } = {},
            recipientDistrictInfo: { name: recipientDistrictInfoName = '' } = {},
            recipientWardInfo: { name: recipientWardInfoName = '' } = {},
            recipientAddress: recipientAddressDetail
        } = orders;
        return {
            ...orders,
            recipientAddress: t(addressFormat(recipientProvinceInfoName, recipientDistrictInfoName, recipientWardInfoName, recipientAddressDetail)),
            createdAt: dayjs(createdAt).locale(vi).format(DATE_FORMAT.DD_MM_YYYY_FULL),
            statusInfoName,
            fullNameShipper
        };
    })

    const handleSearch = (event, errors, values) => {
        if (isEmpty(errors)) {
            const { code, searchText } = values;
            const params = {
                page: 1,
                pageSize: paginationParams.pageSize,
                code,
                searchText,
                fromDate: fromDate,
                toDate: toDate,
                ordersStatus: ordersStatusSelected.value !== 'All' ? ordersStatusSelected.value : undefined,
                employeeId: employeeSelected.value !== 'All' ? employeeSelected.value : undefined,
            }
            setSearchParams(params);
            dispatch(getOrders(params));
        }
    }

    const handleShowMoreFilter = () => {
        setIsShowMore(!isShowMore);
    }

    const handleResetField = () => {
        searchFormRef.current.reset();
        setOrdersStatusSelected(ordersStatusDefault);
        setFromDate(undefined);
        setToDate(undefined);
        setEmployeeSelected(ordersStatusDefault);
    }

    const onChangeFromDate = (date) => {
        setFromDate(dayjs(date).format(DATE_FORMAT.YYYY_MM_DD));
    }

    const onChangeToDate = (date) => {
        setToDate(dayjs(date).format(DATE_FORMAT.YYYY_MM_DD));
    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'no',
            width: '2%',
        },
        {
            title: 'Mã đơn',
            dataIndex: 'code',
            key: 'code',
            width: '12%',
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '10%',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'statusInfoName',
            key: 'statusInfoName',
            width: '10%',
        },
        {
            title: 'Tên người nhận',
            dataIndex: 'recipientName',
            key: 'recipientName',
            width: '13%',
        },
        {
            title: 'SĐT Người nhận',
            dataIndex: 'recipientPhone',
            key: 'recipientPhone',
            width: '10%',
        },
        {
            title: 'Địa chỉ người nhận',
            dataIndex: 'recipientAddress',
            key: 'recipientAddress',
            width: '28%',
        },
        {
            title: 'Nhân viên vận chuyển',
            dataIndex: 'fullNameShipper',
            key: 'fullNameShipper',
            width: '12%',
        },
        {
            title: '',
            dataIndex: '',
            key: 'action',
            width: '4%',
        }
    ]

    return (
        <Container>
            <Dashboard>
                <Card>
                    <HeadingWrapper>
                        <HeadingTitle
                            type="large"
                            label={t('common.orders.heading_title')}
                        />
                        <Exports
                            exportType={EXPORT_TYPE.ORDERS}
                            searchParams={searchParams}
                        />
                    </HeadingWrapper>
                    <AvForm onSubmit={handleSearch} ref={searchFormRef}>
                        <RemakeAvField
                            height={'35px'}
                            name="searchText"
                            label={t('common.search')}
                            placeholder={t('orders.search_by_info')}
                            type="text"
                        />
                        {isShowMore && (
                            <SearchContainer>
                                <RowSearchContainer>
                                    <RemakeAvField
                                        height={'35px'}
                                        name="code"
                                        label={t('common.orders.code')}
                                        placeholder={t('common.orders.code')}
                                        type="text"
                                    />
                                    <RemakeSelect
                                        label={t('common.orders.status')}
                                        placeholder={t('common.orders.status')}
                                        options={ordersStatusesFormat}
                                        value={ordersStatusSelected}
                                        onChange={onChangeOrdersStatus}
                                        hasAll
                                    />
                                    <DatePicker
                                        name='fromDate'
                                        label={t('common.fromDate')}
                                        placeholder={t('common.fromDate')}
                                        value={fromDate}
                                        onChange={onChangeFromDate}
                                        options={
                                            {
                                                // maxDate: dayjs().format(DATE_FORMAT.YYYY_MM_DD),
                                                dateFormat: DATE_FORMAT.DD_MM_YYYY,
                                            }
                                        }
                                        format={DATE_FORMAT.DD_MM_YYYY}
                                    />
                                    <DatePicker
                                        name='toDate'
                                        label={t('common.toDate')}
                                        placeholder={t('common.toDate')}
                                        value={toDate}
                                        onChange={onChangeToDate}
                                        options={
                                            {
                                                minDate: new Date(fromDate),
                                                dateFormat: DATE_FORMAT.DD_MM_YYYY,
                                            }
                                        }
                                        format={DATE_FORMAT.DD_MM_YYYY}
                                    />
                                    <RemakeSelect
                                        label={t('common.employee.name')}
                                        placeholder={t('common.employee.name')}
                                        options={employeesFormat}
                                        value={employeeSelected}
                                        onChange={onChangeEmployee}
                                        hasAll
                                    />
                                </RowSearchContainer>
                            </SearchContainer>
                        )}
                        <RowSearchContainer style={{ marginTop: 10 }}>
                            <ButtonsContainer style={{ gridColumnStart: 4 }}>
                                <RemakeButton
                                    label={!isShowMore ? t('common.show_more_fileds') : t('common.hide_more_fileds')}
                                    type="button"
                                    onClick={handleShowMoreFilter}
                                    buttonStyles={{ marginRight: 10, background: '#207346', width: 200 }}
                                    labelStyles={{ textTransform: 'none' }}
                                />
                                <RemakeButton
                                    label={t('common.reset_input')}
                                    type="button"
                                    onClick={() => handleResetField()}
                                    buttonStyles={{ marginRight: 10, background: '#C4C4C4' }}
                                />
                                <RemakeButton label={t('common.search')} type='primary' />
                            </ButtonsContainer>
                        </RowSearchContainer>
                    </AvForm>
                </Card>
                <Card style={{ marginTop: 20 }}>
                    <Table
                        columns={columns}
                        dataSource={ordersResults}
                        recordNumberOnPage={paginationParams.pageSize}
                        totalRecord={total}
                        onClickEdit={(item) => handleClickEdit(item)}
                        onClickDelete={(item) => handleClickDelete(item)}
                        onClickPagination={(item) => handleClickPagination(item)}
                    />
                </Card>
            </Dashboard>
            <DialogMessage
                type="error"
                title={t('common.delete_data')}
                message={t('common.delete_data_confirm')}
                isOpen={isOpenDialog}
                leftAction={() => setIsOpenDialog(false)}
                rightAction={() => handleOkeDialog()}
            />
        </Container>
    );
}
