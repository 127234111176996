import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const FooterContainer = styled.div`
    height: 80px;
    background:${Colors.primary};
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 33px;

    div {
        color: #ffffff;
        font-size: 15px;
    }

    div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        div {
            cursor: pointer;
        }
    }

    @media ${DEVICE_RESPONSIVE.mobile} {
        font-size: 25px;
        flex-direction: column;
        padding: 10px 0px;
        text-align: center;
        height: auto;
        div:nth-child(2) {
            margin: 10px 0px;
        }
    }
`;