import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const Container = styled.button`
    height: 35px;
    width: 140px;
    padding: 5px;
    background-color: ${Colors.primary};
    border-radius: 5px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    border: none;

    span {
        color: #ffffff;
        font-weight: bold;
        font-size: 15px;
        text-transform: uppercase;
    }

    @media ${DEVICE_RESPONSIVE.mobile} {
        height: 35px;
    }
`;