import React, { useEffect, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Card from '~/components/Card';
import { Input } from 'reactstrap';
import Container from '~/components/Container';
import Dashboard from '~/components/Dashboard';
import HeadingTitle from '~/components/HeadingTitle';
import SunEditor from 'suneditor-react';
import { editorSettings, SETTINGS_KEY, SETTINGS_KEY_FORMAT } from '~/constants/common';
import { StaticPageLabel, ButtonEditing } from './styles';
import { clearSetting, getSettingByKey, updateSettingByKey } from '~/modules/settings/actions';
import RemakeButton from '~/components/RemakeButton';
import { history } from '~/utils/navigations';


function StaticPageEditing(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { match } = props;
    const { params: { key: settingKey } = {} } = match || {};
    const { settingData = {} } = useSelector(state => state.settings);

    const [settingContent, setSettingContent] = useState('');

    const { key, value } = settingData;

    useEffect(() => {
        const params = {
            key: SETTINGS_KEY_FORMAT[settingKey]
        }
        dispatch(getSettingByKey(params));
        return function cleanup() {
            dispatch(clearSetting());
        };
    }, []);


    const onChangeContent = (value) => {
        setSettingContent(value);
    }

    const handleSave = () => {
        const body = {
            key,
            value: settingContent
        }
        dispatch(updateSettingByKey(body));
    }


    return (
        <Container>
            <Dashboard>
                <Card>
                    <HeadingTitle
                        type="large"
                        label={t('static_page.edit')}
                    />
                    <div>
                        <StaticPageLabel>{t('static_page.name')}</StaticPageLabel>
                        <Input name='name' disabled={true} value={t(`static_page.${SETTINGS_KEY[key]}`)} />
                    </div>
                    <div>
                        <StaticPageLabel>{t('static_page.content')}</StaticPageLabel>
                        <SunEditor
                            setContents={value}
                            onChange={onChangeContent}
                            setOptions={editorSettings}
                        />
                    </div>
                    <ButtonEditing>
                        <RemakeButton
                            label={t('common.go_back')}
                            type='dark'
                            buttonStyles={{ marginRight: 10 }}
                            onClick={() => history.goBack()}
                        />
                        <RemakeButton
                            label={t('common.save')}
                            type='primary'
                            onClick={handleSave}
                        />
                    </ButtonEditing>
                </Card>
            </Dashboard>
        </Container>
    )
}

export default memo(StaticPageEditing);
