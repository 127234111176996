import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import HeadingTitle from '~/components/HeadingTitle';
import { ReportContainer, ReportItem, ReportList, LeftContent, RightContent } from '~/components/Reports/Employee/styles';
import { ChartLine } from '@styled-icons/fa-solid/ChartLine';
import { Colors } from '~/constants/styles';
import { getTotalReports } from '~/modules/report/actions';
import { formatNumberWithZero, VNDCurrencyFormatting } from '~/utils/helper';
import { USER_STATUS_TYPE } from '~/constants/common';
import { ROUTE } from '~/constants/routes';

function TotalReport() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { totalReportsData = {} } = useSelector(state => state.reports);
    const { shippingRevenue, ordersTotalValue } = totalReportsData;

    useEffect(() => {
        dispatch(getTotalReports())
    }, []);

    return (
        <ReportContainer>
            <HeadingTitle
                type="large"
                label={t('report.turnover')}
            />
            <ReportList columns={3}>
                <ReportItem>
                    <LeftContent>
                        <span>{t('report.total_shipping_revenue')}</span>
                        <h3>{VNDCurrencyFormatting(shippingRevenue)}</h3>
                    </LeftContent>
                    <RightContent>
                        <ChartLine color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
                <ReportItem>
                    <LeftContent>
                        <span>{t('report.total_order_revenue')}</span>
                        <h3>{VNDCurrencyFormatting(ordersTotalValue)}</h3>
                    </LeftContent>
                    <RightContent>
                        <ChartLine color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
            </ReportList>
        </ReportContainer>
    )
}

export default TotalReport
