import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const Container = styled.div`

`;

export const HeadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SearchContainer = styled.div`
    margin-top: 15px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
`;

export const SearchButton = styled.div`
    margin-top: 25px;
    margin-left: 10px;
`;


export const RowSearchContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: ${props => props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(3, 1fr)'};
    color: ${Colors.dark};
    width: 100%;

    @media ${DEVICE_RESPONSIVE.mobile} {
        grid-template-columns: repeat(1,1fr);
    }
`;
