const LOADING_PREFIX = '@loading/';
const THEME_MODE_PREFIX = '@themeMode/'
const DEV_TO_PREFIX = '@devto/';
const GITHUB_TRENDING_PREFIX = '@github/';
const HACKER_NEWS_PREFIX = '@hackerNews/';

const ACTION_TYPE = {
    LOADING: {
        LOADING: `${LOADING_PREFIX}LOADING`,
        LOADING_SUCCESS: `${LOADING_PREFIX}LOADING_SUCCESS`,
    },
    THEME_MODE: {
        THEME_MODE: `${THEME_MODE_PREFIX}THEME_MODE`,
        THEME_MODE_SUCCESS: `${THEME_MODE_PREFIX}THEME_MODE_SUCCESS`,
    },
    DEV_TO: {
        GET_DEVTO_NEWS: `${DEV_TO_PREFIX}GET_DEVTO_NEWS`,
        GET_DEVTO_NEWS_SUCCESS: `${DEV_TO_PREFIX}GET_DEVTO_NEWS_SUCCESS`
    },
    GITHUB: {
        GET_GITHUB_TRENDING: `${GITHUB_TRENDING_PREFIX}GET_GITHUB_TRENDING`,
        GET_GITHUB_TRENDING_SUCCESS: `${GITHUB_TRENDING_PREFIX}GET_GITHUB_TRENDING_SUCCESS`
    },
    HACKER_NEWS_PARENT: {
        GET_HACKER_NEWS: `${HACKER_NEWS_PREFIX}GET_HACKER_NEWS`
    },
    AUTH: {
        LOGIN: 'LOGIN',
        LOGIN_SUCCESS: 'LOGIN_SUCCESS',
        SIGN_UP: 'SIGN_UP',
        SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
        LOGOUT: 'LOGOUT',
        FORGOT_PASSWORD: 'FORGOT_PASSWORD',
        FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
        RESET_PASSWORD: 'RESET_PASSWORD',
        RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
        CLEAR_SIGN_UP_DATA: 'CLEAR_SIGN_UP_DATA',
        CHANGE_PASSWORD: 'CHANGE_PASSWORD',
        CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    },
    USER: {
        GET_USER: 'GET_USER',
        GET_USER_SUCCESS: 'GET_USER_SUCCESS',
        DELETE_USER: 'DELETE_USER',
        DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
        CREATE_USER: 'CREATE_USER',
        CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
        GET_USER_BY_ID: 'GET_USER_BY_ID',
        GET_USER_BY_ID_SUCCESS: 'GET_USER_BY_ID_SUCCESS',
        UPDATE_USER: 'UPDATE_USER',
        UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
        CLEAR_GET_EMPLOYEE_ID: 'CLEAR_GET_EMPLOYEE_ID',
    },
    SETTINGS: {
        SELECTED_MENU_SIDEBAR_SUCCESS: 'SELECTED_MENU_SIDEBAR_SUCCESS',
        GET_ORDERS_STATUSES: 'GET_ORDERS_STATUSES',
        GET_ORDERS_STATUSES_SUCCESS: 'GET_ORDERS_STATUSES_SUCCESS',
        ADD_ORDERS_STATUS: 'ADD_ORDERS_STATUS',
        ADD_ORDERS_STATUS_SUCCESS: 'ADD_ORDERS_STATUS_SUCCESS',
        DELETE_ORDERS_STATUS: 'DELETE_ORDERS_STATUS',
        DELETE_ORDERS_STATUS_SUCCESS: 'DELETE_ORDERS_STATUS_SUCCESS',
        GET_ORDERS_STATUS_BY_ID: 'GET_ORDERS_STATUS_BY_ID',
        GET_ORDERS_STATUS_BY_ID_SUCCESS: 'GET_ORDERS_STATUS_BY_ID_SUCCESS',
        UPDATE_ORDERS_STATUS_BY_ID: 'UPDATE_ORDERS_STATUS_BY_ID',
        UPDATE_ORDERS_STATUS_BY_ID_SUCCESS: 'UPDATE_ORDERS_STATUS_BY_ID_SUCCESS',
        UPDATE_SORT_INDEX_ORDERS_STATUSES: 'UPDATE_SORT_INDEX_ORDERS_STATUSES',
        UPDATE_SORT_INDEX_ORDERS_STATUSES_SUCCESS: 'UPDATE_SORT_INDEX_ORDERS_STATUSES_SUCCESS',
        GET_BY_KEY: 'GET_BY_KEY',
        GET_BY_KEY_SUCCESS: 'GET_BY_KEY_SUCCESS',
        UPDATE_BY_KEY: 'UPDATE_BY_KEY',
        CLEAR: 'CLEAR',
        GET_ALL: 'GET_ALL',
        GET_ALL_SUCCESS: 'GET_ALL_SUCCESS',
    },
    ADDRESS: {
        GET_PROVINCES: 'GET_PROVINCES',
        GET_PROVINCES_SUCCESS: 'GET_PROVINCES_SUCCESS',
        GET_DISTRICTS: 'GET_DISTRICTS',
        GET_DISTRICTS_SUCCESS: 'GET_DISTRICTS_SUCCESS',
        GET_WARDS: 'GET_WARDS',
        GET_WARDS_SUCCESS: 'GET_WARDS_SUCCESS',
        RESET_WARDS: 'RESET_WARDS',
        RESET_DISTRICTS: 'RESET_DISTRICTS',
    },
    EXPORT: {
        EXPORT_DATA: 'EXPORT_DATA',
        EXPORT_DATA_SUCCESS: 'EXPORT_DATA_SUCCESS'
    },
    ORDERS: {
        CREATE_ORDERS: 'CREATE_ORDERS',
        CREATE_ORDERS_SUCCESS: 'CREATE_ORDERS_SUCCESS',
        GET_BY_ID: 'GET_BY_ID',
        GET_BY_ID_SUCCESS: 'GET_BY_ID_SUCCESS',
        GET_ORDERS: 'GET_ORDERS',
        GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
        CLEAR_BY_ID: 'CLEAR_BY_ID',
        UPDATE: 'UPDATE',
        DELETE: 'DELETE',
        CREATE_WITH_OTHER: 'CREATE_WITH_OTHER',
        CREATE_WITH_OTHER_SUCCESS: 'CREATE_WITH_OTHER_SUCCESS',
        CLEAR_CREATE_WITH_OTHER: 'CLEAR_CREATE_WITH_OTHER',
        CREATE_EVENT: 'CREATE_EVENT',
        CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
        CLEAR_CREATE_EVENT: 'CLEAR_CREATE_EVENT',
        GET_ORDERS_EVENT_BY_CODE: 'GET_ORDERS_EVENT_BY_CODE',
        GET_ORDERS_EVENT_BY_CODE_SUCCESS: 'GET_ORDERS_EVENT_BY_CODE_SUCCESS',
        CLEAR_ORDERS_EVENT_BY_CODE: 'CLEAR_ORDERS_EVENT_BY_CODE',
        GET_SHIPPING_FEE: 'GET_SHIPPING_FEE',
        GET_SHIPPING_FEE_SUCCESS: 'GET_SHIPPING_FEE_SUCCESS',
        CLEAR_SHIPPING_FEE: 'CLEAR_SHIPPING_FEE'
    },
    UPLOAD_FILE: {
        UPLOAD_FILES: 'UPLOAD_FILES',
        UPLOAD_FILES_SUCCESS: 'UPLOAD_FILES_SUCCESS',
        CLEAR: 'CLEAR',
    },
    REPORTS: {
        GET_BY_EMPLOYEE: 'GET_BY_EMPLOYEE',
        GET_BY_EMPLOYEE_SUCCESS: 'GET_BY_EMPLOYEE_SUCCESS',
        GET_BY_CUSTOMER: 'GET_BY_CUSTOMER',
        GET_BY_CUSTOMER_SUCCESS: 'GET_BY_CUSTOMER_SUCCESS',
        GET_BY_ORDERS: 'GET_BY_ORDERS',
        GET_BY_ORDERS_SUCCESS: 'GET_BY_ORDERS_SUCCESS',
        GET_TOTAL: 'GET_TOTAL',
        GET_TOTAL_SUCCESS: 'GET_TOTAL_SUCCESS'
    }
}

export {
    ACTION_TYPE
}