import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const ReportItem = styled.div`
    flex: 1 1 auto;
    padding: 1rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e2e7f1;
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

export const ReportContainer = styled.div`

`;

export const ReportList = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: ${props => props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(4, 1fr)'};
    margin-top: 10px;
    @media ${DEVICE_RESPONSIVE.desktop} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media ${DEVICE_RESPONSIVE.mobile} {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const LeftContent = styled.div`

    h3 {
        margin-top: 10px;
        font-size: 1.5rem;
    }

    span {
        text-transform: uppercase;
    }

`;

export const RightContent = styled.div`

`;