import styled from 'styled-components';
import { Label } from 'reactstrap';
import { DEVICE_RESPONSIVE } from '~/constants/common';

export const Container = styled.div`

`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    @media ${DEVICE_RESPONSIVE.mobile} {
        width: 100%;
    }
`;

export const FormWrapper = styled.div`
    margin-top: 15px;
`;

export const NoteWrapper = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: 15px;
    display: flex;

    span {
        font-size: 12px;
    }
`;

export const DragAndDropWrapper = styled.div`
    margin-top: 10px;
    width: 600px;

    @media ${DEVICE_RESPONSIVE.mobile} {
        width: 100%;
    }
`;

export const DragAndDropItemWrapper = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    div {
        display: flex;
    }
`;

export const OrdersStatusWrapper = styled.div`
    display: flex;
    align-items: center;
    align-content: center;

    div {
        height: 15px;
        width: 15px;
        border-radius: 3px;
        margin-right: 5px;
    }
`;

export const AddIcon = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    cursor: pointer;
`;

export const AvLabel = styled(Label)`
    margin-top: 10px;
`;

export const OrdersStatusFormWrapper = styled.div`
    margin-top: 10px;
`;

export const SelectColorWrapper = styled.div`
    padding: 5px;
    background-color: #ffffff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;

    div {
        width: 36px;
        height: 14px;
        border-radius: 2px;
    }
`;

export const ColorDashboardWrapper = styled.div`
    position: absolute;
    z-index: 2;
`;