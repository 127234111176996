import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { COOKIE_KEY, MENUS_SIDEBAR as menusSidebar } from '~/constants/common';
import { useTranslation } from 'react-i18next';
import {
    Container,
    CreateOrdersButtonWrapper,
    MenuWrapper,
    MenuItem,
    CloseMenuWrapper
} from '~/components/Sidebar/styles';
import { redirectTo } from '~/utils/navigations';
import Icon from '~/components/Icon';
import { Colors } from '~/constants/styles';
import { handleGetCookies } from '~/utils/cookies';
import { handleSelectedMenuSidebar } from '~/modules/settings/actions';
import { useLocation } from 'react-router-dom';
import { ROUTE } from '~/constants/routes';
import { Dropbox } from '@styled-icons/icomoon/Dropbox';
import { Cogs } from '@styled-icons/fa-solid/Cogs';
import { FolderUser } from '@styled-icons/remix-fill/FolderUser';
import { UserCircle } from '@styled-icons/fa-solid/UserCircle';
import { Dashboard } from '@styled-icons/boxicons-solid/Dashboard';
import { Report } from '@styled-icons/boxicons-solid/Report'
import { Close } from '@styled-icons/evaicons-solid/Close';

export default function Sidebar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { menuSelected } = useSelector(state => state.settings);

    const location = useLocation();
    const { pathname = '' } = location;
    const pathnameSplit = pathname.split('/');

    if (pathnameSplit.length) {
        const mainPathCurrent = pathname.split('/')[1];

        switch (mainPathCurrent) {

            case 'reports':
                dispatch(handleSelectedMenuSidebar(menusSidebar[0]));
                break;

            case 'orders':
                dispatch(handleSelectedMenuSidebar(menusSidebar[1]));
                break;

            case 'customer':
                dispatch(handleSelectedMenuSidebar(menusSidebar[2]));
                break;

            case 'employee':
                dispatch(handleSelectedMenuSidebar(menusSidebar[3]));
                break;

            case 'settings':
                dispatch(handleSelectedMenuSidebar(menusSidebar[4]));
                break;

            default:
                break;
        }
    }

    const handleClickMenuItem = (item) => {
        dispatch(handleSelectedMenuSidebar(item));
        redirectTo(item.router);
    }

    const handleCloseMenu = () => {
        document.getElementById('sider-menu').style.display = 'none';
        document.getElementById('mask-menu').style.display = 'none';
    }

    const handleGetMenuIcon = (key) => {
        switch (key) {
            case 'report':
                return <Dashboard color={Colors.dark} width={22} />

            case 'box':
                return <Dropbox color={Colors.dark} width={22} />

            case 'user-circle':
                return <UserCircle color={Colors.dark} width={22} />

            case 'user-pin':
                return <FolderUser color={Colors.dark} width={22} />

            case 'cog':
                return <Cogs color={Colors.dark} width={22} />

            default:
                break;
        }
    }

    const renderMenus = () => {
        return menusSidebar.map(item => {
            const { roles = [] } = item;
            const userInfo = handleGetCookies(COOKIE_KEY.LOGIN);
            if (userInfo) {
                const { UserRole: { RoleType: { name: roleName = '' } = {} } = {} } = userInfo;
                const isAllow = roles.find(item => item === roleName);
                return isAllow && (
                    <MenuItem style={item.label === menuSelected.label ? { backgroundColor: '#eeeeee' } : {}} onClick={() => handleClickMenuItem(item)}>
                        {handleGetMenuIcon(item.icon)}
                        <div>
                            <span style={item.label === menuSelected.label ? { fontWeight: 'bold' } : {}}>{t(item.label)}</span>
                        </div>
                    </MenuItem>
                )
            }
        })
    }

    return (
        <Container id='sider-menu'>
            <CloseMenuWrapper onClick={handleCloseMenu}>
                <Close color={Colors.dark} width={40} />
            </CloseMenuWrapper>
            <CreateOrdersButtonWrapper onClick={() => redirectTo(ROUTE.ORDERS.ADD)}>
                <span>
                    {t('common.create_orders')}
                </span>
            </CreateOrdersButtonWrapper>
            <MenuWrapper>
                {renderMenus()}
            </MenuWrapper>
        </Container>
    )
}
