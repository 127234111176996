import { ACTION_TYPE } from '~/constants/actionTypes';

const getReportsByEmployee = () => {
    return {
        type: ACTION_TYPE.REPORTS.GET_BY_EMPLOYEE,
    }
}

const getReportsByEmployeeSuccess = (data) => {
    return {
        type: ACTION_TYPE.REPORTS.GET_BY_EMPLOYEE_SUCCESS,
        data,
    }
}

const getReportsByCustomer = () => {
    return {
        type: ACTION_TYPE.REPORTS.GET_BY_CUSTOMER,
    }
}

const getReportsByCustomerSuccess = (data) => {
    return {
        type: ACTION_TYPE.REPORTS.GET_BY_CUSTOMER_SUCCESS,
        data,
    }
}

const getReportsByOrders = () => {
    return {
        type: ACTION_TYPE.REPORTS.GET_BY_ORDERS,
    }
}

const getReportsByOrdersSuccess = (data) => {
    return {
        type: ACTION_TYPE.REPORTS.GET_BY_ORDERS_SUCCESS,
        data,
    }
}

const getTotalReports = () => {
    return {
        type: ACTION_TYPE.REPORTS.GET_TOTAL,
    }
}

const getTotalReportsSuccess = (data) => {
    return {
        type: ACTION_TYPE.REPORTS.GET_TOTAL_SUCCESS,
        data,
    }
}

export {
    getReportsByEmployee,
    getReportsByEmployeeSuccess,
    getReportsByCustomer,
    getReportsByCustomerSuccess,
    getReportsByOrders,
    getReportsByOrdersSuccess,
    getTotalReports,
    getTotalReportsSuccess
}