import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const Container = styled.div`
    border: 2px solid grey;
    border-left: 8px solid grey;
    border-right: 8px solid grey;
    padding: 20px;
    margin-top: 30px;
    min-width: 900px;
    overflow-x: scroll;
`;

export const RowOne = styled.div`
    border: 1px solid grey;
    display: flex;
`;

export const ColumnOne = styled.div`
    width: 25%;
    display: flex;
    justify-content: center;
    border-right: 1px solid grey;
    padding: 10px;

    img {
        border: 1px solid grey;
        padding: 8px;
        border-radius: 5px;
        width: 120px;
        height: 120px;
    }
`;

export const ColumnTwo = styled.div`
    width: 45%;
    display: flex;
    justify-content: center;
    border-right: 1px solid grey;
`;

export const ColumnThree = styled.div`
    width: 30%;
    display: flex;
    justify-content: center;
`;

export const Image = styled.img`
    width: 90px;
    height: 90px;
    float: none !important;
`;

export const RowTwo = styled.div`
    border: 1px solid grey;
`;

export const RowTwoColumnOne = styled.div`
    width: 93%;
    border-right: 1px solid grey;
`;

export const RowThree = styled.div`
    border: 1px solid grey;
    display: flex;
    min-height: 160px;
`;

export const RowThreeColumnOne = styled.div`
    width: 51%;
    border-right: 1px solid grey;
    padding: 10px;
    font-size: 12px;
`;

export const RowThreeColumnTwo = styled.div`
    width: 29%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    border-right: 1px solid grey;
    font-size: 12px;
`;

export const RowThreeColumnThree = styled.div`
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RowTwoColumnOneRowOne = styled.div`
    display: flex;
    min-height: 130px;
`;

export const RowTwoColumnOneRowTwo = styled.div`
    border-top: 1px solid grey;
    display: flex;
    min-height: 140px;
`;

export const RowTwoColumnOneRowTwoColumnOne = styled.div`
    width: 55%;
    border-right: 1px solid grey;
    padding: 10px;
    font-size: 12px;
`;

export const RowTwoColumnOneRowTwoColumnTwo = styled.div`
    width: 35%;
    border-right: 1px solid grey;
    padding: 10px;
    font-size: 12px;
`;

export const RowTwoColumnOneRowTwoColumnThree = styled.div`
    width: 10%;
`;

export const RowTwoColumnOneRowOneColumnOne = styled.div`
    width: 37%;
    border-right: 1px solid grey;
    padding: 10px;
    font-size: 12px;
`;

export const RowTwoColumnOneRowOneColumnTwo = styled.div`
    width: 39%;
    padding: 10px;
    font-size: 12px;
    border-right: 1px solid grey;
`;

export const RowTwoColumnOneRowOneColumnThree = styled.div`
    width: 25%;
    font-weight: 600;
    font-size: 12px;
`;

export const Phone = styled.div`
    height: 50%;
    text-align: center;
    padding-top: 6px;
    min-height: 65px;
`;

export const Date = styled.div`
    height: 50%;
    border-top: 1px solid grey;
    text-align: center;
    padding-top: 6px;
`;

export const SenderText = styled.div`
    font-weight: 500;
    text-decoration: underline;
`;

export const SenderName = styled.div`
    margin: 4px 0px;
    font-weight: 600;
`;

export const SenderAddress = styled.div`
    font-weight: 600;
    line-height: 20px;
`;

export const ContentText = styled.div`
    font-weight: 600;
    margin-top: 6px;
`;

export const AmountText = styled.div`
    font-weight: 600;
    margin-top: 30px;
    font-size: 16px;
`;

export const DateYear = styled.div`
    font-style: italic;
`;