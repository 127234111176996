import development from '~/env/development.config';
import test from '~/env/test.config';
import production from '~/env/production.config';

const config = {
    development,
    test,
    production
}

export default config;