import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ACTION_TYPE } from '~/constants/actionTypes';
import httpRequest from '~/services/api';
import { handleGetApiPath } from '~/utils/api';
import { API } from '~/services/app.routes';
import {
    handleGetOrdersStatusesSuccess,
    handleAddOrdersStatusSuccess,
    handleGetOrdersStatusByIdSuccess,
    getSettingByKeySuccess,
    getSettingsSuccess
} from '~/modules/settings/actions';
import { StatusCodes } from 'http-status-codes';
import * as notification from '~/utils/notifications';
import i18n from '~/utils/i18n';
import { NOTIFICATION_TYPE } from '~/constants/common';

function* handleGetOrdersStatuses() {
    const path = handleGetApiPath(API.SETTINGS.ORDERS_STATUSES);
    const result = yield call(httpRequest.get, path);
    const { items = [] } = result;
    yield put(handleGetOrdersStatusesSuccess(items));
}

function* handleAddOrdersStatus({ data }) {
    const path = handleGetApiPath(API.SETTINGS.ORDERS_STATUS);
    const result = yield call(httpRequest.post, path, data);
    const { data: dataAdd = {}, status } = result;
    if (status === StatusCodes.OK) {
        const path = handleGetApiPath(API.SETTINGS.ORDERS_STATUSES);
        const result = yield call(httpRequest.get, path);
        const { items = [] } = result;
        yield put(handleGetOrdersStatusesSuccess(items));
        yield put(handleAddOrdersStatusSuccess(dataAdd));
        notification.custom(i18n.t('common.success'), i18n.t('dialog.orders_status.add_success'), NOTIFICATION_TYPE.SUCCESS);
    }
}

function* handleDeleteOrdersStatus({ params }) {
    const path = handleGetApiPath(API.SETTINGS.ORDERS_STATUS_ID, params);
    const result = yield call(httpRequest.delete, path);
    const { status } = result;
    if (status === StatusCodes.OK) {
        const path = handleGetApiPath(API.SETTINGS.ORDERS_STATUSES);
        const result = yield call(httpRequest.get, path);
        const { items = [] } = result;
        yield put(handleGetOrdersStatusesSuccess(items));
        notification.custom(i18n.t('common.success'), i18n.t('dialog.orders_status.delete_success'), NOTIFICATION_TYPE.SUCCESS);
    }
}

function* handleGetOrdersStatusById({ params }) {
    const path = handleGetApiPath(API.SETTINGS.ORDERS_STATUS_ID, params);
    const result = yield call(httpRequest.get, path, params);
    const { status } = result;
    if (status === StatusCodes.OK) {
        const { data = {} } = result;
        yield put(handleGetOrdersStatusByIdSuccess(data));
    }
}

function* handleUpdateOrdersStatusById({ data }) {
    const path = handleGetApiPath(API.SETTINGS.ORDERS_STATUS_ID, { ordersStatusId: data.ordersStatusId });
    const result = yield call(httpRequest.put, path, data);
    const { status } = result;
    if (status === StatusCodes.OK) {
        const path = handleGetApiPath(API.SETTINGS.ORDERS_STATUSES);
        const result = yield call(httpRequest.get, path);
        const { items = [] } = result;
        yield put(handleGetOrdersStatusesSuccess(items));
        notification.custom(i18n.t('common.success'), i18n.t('dialog.orders_status.update_success'), NOTIFICATION_TYPE.SUCCESS);
    }
}

function* handleUpdateSortIndexOrdersStatuses({ data }) {
    const path = handleGetApiPath(API.SETTINGS.SORT_INDEX_ORDERS_STATUSES);
    const result = yield call(httpRequest.put, path, { ordersStatuses: data });
    const { status } = result;
    if (status === StatusCodes.OK) {
        const path = handleGetApiPath(API.SETTINGS.ORDERS_STATUSES);
        const result = yield call(httpRequest.get, path);
        const { items = [] } = result;
        yield put(handleGetOrdersStatusesSuccess(items));
        notification.custom(i18n.t('common.success'), i18n.t('dialog.orders_status.update_success'), NOTIFICATION_TYPE.SUCCESS);
    }
}

function* handleGetSettingByKey({ params }) {
    const path = handleGetApiPath(API.SETTINGS.GET_BY_KEY, params);
    const result = yield call(httpRequest.get, path);
    const { status, data } = result;
    if (status === StatusCodes.OK) {
        yield put(getSettingByKeySuccess(data));
    }
}

function* handleUpdateSettingByKey({ body }) {
    const path = handleGetApiPath(API.SETTINGS.UPDATE_BY_KEY);
    const result = yield call(httpRequest.put, path, body);
    const { status, data } = result;
    if (status === StatusCodes.OK) {
        notification.custom(i18n.t('common.success'), i18n.t('common.update_success'), NOTIFICATION_TYPE.SUCCESS);
    }
}

function* handleGetSettings() {
    const path = handleGetApiPath(API.SETTINGS.UPDATE_BY_KEY);
    const result = yield call(httpRequest.get, path);
    const { status, items } = result;
    if (status === StatusCodes.OK) {
        yield put(getSettingsSuccess(items));
    }
}

export default all([
    takeLatest(ACTION_TYPE.SETTINGS.GET_ORDERS_STATUSES, handleGetOrdersStatuses),
    takeLatest(ACTION_TYPE.SETTINGS.ADD_ORDERS_STATUS, handleAddOrdersStatus),
    takeLatest(ACTION_TYPE.SETTINGS.DELETE_ORDERS_STATUS, handleDeleteOrdersStatus),
    takeLatest(ACTION_TYPE.SETTINGS.GET_ORDERS_STATUS_BY_ID, handleGetOrdersStatusById),
    takeLatest(ACTION_TYPE.SETTINGS.UPDATE_ORDERS_STATUS_BY_ID, handleUpdateOrdersStatusById),
    takeLatest(ACTION_TYPE.SETTINGS.UPDATE_SORT_INDEX_ORDERS_STATUSES, handleUpdateSortIndexOrdersStatuses),
    takeLatest(ACTION_TYPE.SETTINGS.GET_BY_KEY, handleGetSettingByKey),
    takeLatest(ACTION_TYPE.SETTINGS.UPDATE_BY_KEY, handleUpdateSettingByKey),
    takeLatest(ACTION_TYPE.SETTINGS.GET_ALL, handleGetSettings),
]);
