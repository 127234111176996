import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const ShipInfoContainer = styled.div`
    margin-top: 18px;
    color: ${Colors.dark};
`;

export const SelectInputContainer = styled.div`
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 0.97fr 2fr;
    margin-top: 5px;

    @media ${DEVICE_RESPONSIVE.mobile} {
        grid-template-columns: unset;
    }
`;