import produce from 'immer';
import { ACTION_TYPE } from '~/constants/actionTypes';
import { MENUS_SIDEBAR as menusSidebar } from '~/constants/common';

const INITIAL_STATE = {
    menuSelected: menusSidebar[0],
    ordersStatuses: [],
    dataAdd: null,
    ordersStatusById: null,
    settingData: {},
    settings: []
};

const settingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPE.SETTINGS.SELECTED_MENU_SIDEBAR_SUCCESS:
            return produce(state, draft => {
                const { params } = action;
                draft.menuSelected = params;
            });

        case ACTION_TYPE.SETTINGS.GET_ORDERS_STATUSES_SUCCESS:
            return produce(state, draft => {
                const { items } = action;
                draft.ordersStatuses = items;
            });

        case ACTION_TYPE.SETTINGS.ADD_ORDERS_STATUS_SUCCESS:
            return produce(state, draft => {
                const { dataAdd } = action;
                draft.dataAdd = dataAdd;
            });

        case ACTION_TYPE.SETTINGS.GET_ORDERS_STATUS_BY_ID_SUCCESS:
            return produce(state, draft => {
                const { data = {} } = action;
                draft.ordersStatusById = data;
            });

        case ACTION_TYPE.SETTINGS.GET_BY_KEY_SUCCESS:
            return produce(state, draft => {
                const { data = {} } = action;
                draft.settingData = data;
            });

        case ACTION_TYPE.SETTINGS.CLEAR:
            return produce(state, draft => {
                draft.settingData = {};
            });

        case ACTION_TYPE.SETTINGS.GET_ALL_SUCCESS:
            return produce(state, draft => {
                const { items = [] } = action;
                draft.settings = items;
            });

        default:
            return state;
    }
}

export default settingsReducer;
