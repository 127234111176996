import React, { useState, useEffect, memo } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HeadingTitle from '../HeadingTitle';
import { SettingsTabsContainer, TabContent, TextSuggest } from './styles';
import AttachmentFile from '~/components/AttachmentFile';
import RemakeButton from '~/components/RemakeButton';
import { clearSetting, getSettingByKey, updateSettingByKey } from '~/modules/settings/actions';
import { clearUploadFiles, handleUploadFile } from '~/modules/uploadFile/actions';
import { CATEGORY_TYPE_FILE, SETTINGS_KEY } from '~/constants/common';
import { isEmpty } from '~/utils/helper';

function Appearance() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('1');
    const [files, setFiles] = useState([]);
    const {
        settings: { settingData = {} },
        uploadFiles: { files: filesFromServer = [] } = {}
    } = useSelector(state => {
        return state;
    });
    useEffect(() => {
        const params = {
            key: SETTINGS_KEY.BRAND_IMAGE
        }
        dispatch(getSettingByKey(params));
        return function cleanup() {
            dispatch(clearUploadFiles());
            dispatch(clearSetting());
        };
    }, []);

    useEffect(() => {
        if (isEmpty(settingData)) return;
        const { value = '', key } = settingData;
        const images = formatData(value);
        setFiles(images);
    }, [settingData]);

    useEffect(() => {
        if (isEmpty(filesFromServer)) return;
        const { key } = settingData;
        const fileStrings = files.filter(item => typeof item === 'string');
        const valueBody = activeTab === '2' ? `${fileStrings.toString()},${formatFilesToSendAPI(filesFromServer)}` : formatFilesToSendAPI(filesFromServer);
        const body = {
            key,
            value: valueBody
        }
        dispatch(updateSettingByKey(body));
        dispatch(clearUploadFiles());
    }, [filesFromServer]);

    const formatData = (data) => {
        if (!data) return [];
        return data.split(',');
    }

    const formatFilesToSendAPI = (files) => {
        return files.map(item => {
            return item?.id;
        }).toString();
    }

    const toggleTab = tab => {
        setFiles([]);
        if (activeTab !== tab) setActiveTab(tab);
        const keyObject = {
            '1': SETTINGS_KEY.BRAND_IMAGE,
            '2': SETTINGS_KEY.BANNER_IMAGES,
            '3': SETTINGS_KEY.AUTH_IMAGE,
        }
        const params = {
            key: keyObject[tab]
        }
        dispatch(clearSetting());
        dispatch(getSettingByKey(params));
    }

    const onChangeFile = (file) => {
        setFiles([...files, file]);
    }

    const handleDeleteFile = (files) => {
        setFiles(files);
    }

    const handleUpdateSetting = () => {
        const { key } = settingData;
        const body = {
            key,
            value: files.toString()
        }
        dispatch(updateSettingByKey(body));
    }

    const handleSave = () => {
        let formData = new FormData();
        const filesFilter = files.filter(item => {
            return typeof item === 'object';
        })
        if (isEmpty(filesFilter)) {
            return handleUpdateSetting();
        };
        filesFilter.forEach(file => {
            formData.append('uploadFiles', file);
        });
        const payload = {
            params: {
                categoryType: CATEGORY_TYPE_FILE.SETTINGS,
                targetId: 'Appearance'
            },
            body: formData
        }
        dispatch(handleUploadFile(payload));
    }

    const renderTabs = () => {
        return (
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={`${activeTab === '1' && 'active'}`}
                        onClick={() => { toggleTab('1'); }}
                    >
                        {t('common.settings.logo_image')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === '2' && 'active'}`}
                        onClick={() => { toggleTab('2'); }}
                    >
                        {t('common.settings.slider_image')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`${activeTab === '3' && 'active'}`}
                        onClick={() => { toggleTab('3'); }}
                    >
                        {t('common.settings.login_image')}
                    </NavLink>
                </NavItem>
            </Nav>
        )
    }

    const renderItems = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (item) => {
        const items = renderItems(
            files,
            item.source.index,
            item.destination.index
        );
        setFiles(items);
        const { key } = settingData;
        const body = {
            key,
            value: items.toString()
        }
        dispatch(updateSettingByKey(body));
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <HeadingTitle
                    type="normal"
                    label={t('dashboard.sidebar.submenu.appearance')}
                />
                <SettingsTabsContainer>
                    {renderTabs()}
                    <TabContent>
                        <AttachmentFile
                            hasDrag={activeTab === '2'}
                            onDragEnd={onDragEnd}
                            onChange={onChangeFile}
                            files={files}
                            handleDeleteFile={handleDeleteFile}
                            width={'150px'}
                            height={'150px'}
                            limitFile={activeTab === '2' ? 5 : 1}
                        />
                    </TabContent>
                </SettingsTabsContainer>
                <TextSuggest>{t(`common.settings.appearance.${activeTab}`)}</TextSuggest>
                <div>
                    <RemakeButton
                        label={t('common.save')}
                        type='primary'
                        buttonStyles={{ float: 'right', marginTop: 10 }}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(Appearance);
