import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ACTION_TYPE } from '~/constants/actionTypes';
import httpRequest from '~/services/api';
import { handleGetApiPath } from '~/utils/api';
import { API } from '~/services/app.routes';
import {
    handleDeleteUserSuccess,
    handleGetUsersSuccess,
    handleCreateUserSuccess,
    handleGetUserByIdSuccess,
    handleUpdateUserSuccess
} from '~/modules/user/actions';
import { redirectTo } from '~/utils/navigations';
import { ROUTE } from '~/constants/routes';
import { NOTIFICATION_TYPE } from '~/constants/common';
import * as notification from '~/utils/notifications';
import i18n from '~/utils/i18n';
import { isEmpty } from '~/utils/helper';

function* handleGetUsers(data) {
    const path = handleGetApiPath(API.USER.USERS, data.params);
    const result = yield call(httpRequest.get, path);
    const { items = [], meta: { total = 0 } = {} } = result;
    yield put(handleGetUsersSuccess(items, total));
}

function* handleDeleteUser(data) {
    const { params: { userId, page, pageSize, roleType } = {} } = data;
    const path = handleGetApiPath(API.USER.DELETE_USER, { userId });
    const result = yield call(httpRequest.delete, path);
    const { data: resultData } = result;
    if (Object.keys(resultData).length === 0) {
        const path = handleGetApiPath(API.USER.USERS, { page, pageSize, roleType });
        const result = yield call(httpRequest.get, path);
        const { items = [], meta: { total = 0 } = {} } = result;
        yield put(handleDeleteUserSuccess(resultData));
        yield put(handleGetUsersSuccess(items, total));
    }
}

function* handleCreateUser({ body }) {
    const { data: dataBody, isEmployee } = body;
    const path = handleGetApiPath(API.USER.CREATE);
    const result = yield call(httpRequest.post, path, dataBody);
    const { data, status, error } = result;
    if (status === 200) {
        yield put(handleCreateUserSuccess(data));
        let messageSuccess = '';
        let route = '';
        if (isEmployee) {
            messageSuccess = i18n.t('employee.create_success');
            route = ROUTE.EMPLOYEE.LIST;
        }
        notification.custom(i18n.t('common.success'), messageSuccess, NOTIFICATION_TYPE.SUCCESS);
        redirectTo(route);
    } else {
        const { message } = error || {};
        notification.custom(i18n.t('common.fail'), i18n.t(`auth.sign_up.${message}`), NOTIFICATION_TYPE.ERROR);
    }
}

function* handleGetUserById(data) {
    const { params } = data;
    const path = handleGetApiPath(API.USER.GET_BY_ID, params);
    const result = yield call(httpRequest.get, path);
    const { data: resultData } = result;
    if (!isEmpty(resultData)) {
        yield put(handleGetUserByIdSuccess(resultData));
    }
}

function* handleUpdateUser({ body }) {
    const { data: dataBody, isEmployee, isCustomer, params, isProfile } = body;
    console.log('isCustomer', isCustomer);
    const path = handleGetApiPath(API.USER.GET_BY_ID, params);
    const result = yield call(httpRequest.put, path, dataBody);
    const { data, status, error } = result;
    if (status === 200) {
        let messageSuccess = '';
        let route = '';
        if (isEmployee) {
            messageSuccess = i18n.t('employee.upadte_success');
            route = ROUTE.EMPLOYEE.LIST;
        } else if (isCustomer) {
            messageSuccess = i18n.t('customer.update_success');
            route = ROUTE.CUSTOMER.LIST;
        } else if (isProfile) {
            messageSuccess = i18n.t('common.edit_my_profile_success');
            yield put(handleUpdateUserSuccess(true));
        }
        notification.custom(i18n.t('common.success'), messageSuccess, NOTIFICATION_TYPE.SUCCESS);
        redirectTo(route);
    } else {
        const { message } = error || {};
        notification.custom(i18n.t('common.fail'), i18n.t(`auth.sign_up.${message}`), NOTIFICATION_TYPE.ERROR);
    }
}

export default all([
    takeLatest(ACTION_TYPE.USER.GET_USER, handleGetUsers),
    takeLatest(ACTION_TYPE.USER.DELETE_USER, handleDeleteUser),
    takeLatest(ACTION_TYPE.USER.CREATE_USER, handleCreateUser),
    takeLatest(ACTION_TYPE.USER.GET_USER_BY_ID, handleGetUserById),
    takeLatest(ACTION_TYPE.USER.UPDATE_USER, handleUpdateUser),
]);
