import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as dayjs from 'dayjs';
// components
import RemakeAvField from '~/components/RemakeAvField';
import RemakeSelect from '~/components/RemakeSelect';
import DatePicker from '~/components/DatePicker';
// styles
import { PickupAddressFormContainer, RowContainer, AddressContainer } from './styles';
import { PageTitle } from '~/components/ProfileInfo/styles'
// constant
import { DATE_FORMAT } from '~/constants/common';
//utils
import { formatAddressData, isEmpty } from '~/utils/helper';
// actions
import { getDistricts, getProvinces, getWards } from '~/modules/address/actions';

let isGetDistricts = true;
let isGetWards = true;
function PickupInfoForm(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { provinces = [], districts = [], wards = [] } = useSelector(state => state.address);
    const {
        pickupInfoFormData,
        isCreateOrdersWithoutAuth,
        onChangePickupProvince,
        onChangePickupDistrict,
        isSubmited,
        step,
        isEdit
    } = props;
    const {
        pickupName,
        pickupPhone,
        pickupPostalCode,
        pickupAddress,
        email
    } = pickupInfoFormData || {}
    //state
    const [provincesState, setProvincesState] = useState([]);
    const [districtsState, setDistrictsState] = useState([]);
    const [wardsState, setWardsState] = useState([]);
    var [provinceSelect, setProvinceSelect] = useState(null);
    var [districtSelect, setDistrictSelect] = useState(null);
    var [wardSelect, setWardSelect] = useState(null);
    var [pickupDate, setPickupDate] = useState(undefined);

    useEffect(() => {
        dispatch(getProvinces());
    }, []);

    useEffect(() => {
        const {
            pickupProvince,
            pickupDistrict,
            pickupWard,
            pickupDate
        } = pickupInfoFormData || {};
        if (pickupProvince?.value) {
            getDistrictsAPI(pickupProvince?.value);
        }
        if (pickupDistrict?.value) {
            getWardsAPI(pickupDistrict?.value);
        }
        setProvinceSelect(pickupProvince);
        setDistrictSelect(pickupDistrict);
        setWardSelect(pickupWard);
        setPickupDate(pickupDate);
    }, [pickupInfoFormData]);

    useEffect(() => {
        setProvincesState(provinces);
    }, [provinces]);

    useEffect(() => {
        if (!isEmpty(districts) && isGetDistricts) {
            setDistrictsState(districts);
            isGetDistricts = false;
        }
    }, [districts]);

    useEffect(() => {
        if (!isEmpty(wards) && isGetWards) {
            setWardsState(wards);
            isGetWards = false;
        }
    }, [wards]);

    const getDistrictsAPI = (value) => {
        const params = {
            provinceId: value
        }
        dispatch(getDistricts(params));
    }

    const getWardsAPI = (value) => {
        const params = {
            districtId: value
        }
        dispatch(getWards(params));
    }

    const onChangeProvince = (itemSelected) => {
        const { value } = itemSelected;
        isGetDistricts = true;
        getDistrictsAPI(value);
        setProvinceSelect(itemSelected);
        setDistrictSelect(null);
        setWardSelect(null);
        if (onChangePickupProvince) {
            onChangePickupProvince(itemSelected);
        }
    }

    const onChangeDistrict = (itemSelected) => {
        const { value } = itemSelected;
        isGetWards = true;
        getWardsAPI(value);
        setDistrictSelect(itemSelected);
        setWardSelect(null);
        if (onChangePickupDistrict) {
            onChangePickupDistrict(itemSelected);
        }
    }

    const onChangeWard = (itemSelected) => {
        setWardSelect(itemSelected);
    }

    const onChangePickupDate = (date) => {
        setPickupDate(dayjs(date).format(DATE_FORMAT.YYYY_MM_DD));
    }

    const isTrue = ((step && step == 1) || !step);

    return (
        <PickupAddressFormContainer isShow={isTrue}>
            <PageTitle>{t('orders.pickup_address')}</PageTitle>
            <RowContainer>
                <RemakeAvField
                    height={'35px'}
                    name="pickupName"
                    label={t('common.full_name')}
                    placeholder={t('common.required.full_name')}
                    type="text"
                    value={pickupName}
                    disabled={isEdit}
                    validate={{
                        required: { value: true, errorMessage: t('common.required.full_name') },
                    }}
                />
                <RemakeAvField
                    height={'35px'}
                    name="pickupPhone"
                    label={t('common.phone_number')}
                    placeholder={t('common.required.phone_number')}
                    type="number"
                    value={pickupPhone}
                    disabled={isEdit}
                    validate={{
                        required: { value: true, errorMessage: t('common.required.phone_number') },
                        minLength: { value: 10, errorMessage: t('auth.sign_up.phone_number_incorrect_format') },
                        maxLength: { value: 16, errorMessage: t('auth.sign_up.phone_number_incorrect_format') },
                    }}
                />
                <DatePicker
                    name='pickupDate'
                    label={t('orders.pickup_date')}
                    placeholder={t('orders.pickup_date')}
                    value={pickupDate}
                    onChange={onChangePickupDate}
                    options={
                        {
                            minDate: dayjs().format(DATE_FORMAT.YYYY_MM_DD),
                            dateFormat: DATE_FORMAT.DD_MM_YYYY,
                        }
                    }
                    format={DATE_FORMAT.DD_MM_YYYY}
                    isSubmited={isSubmited}
                    disabled={isEdit}
                    validate={{
                        required: { value: true, errorMessage: t('orders.required.pickup_date') },
                    }}
                />
            </RowContainer>
            <RowContainer>
                <RemakeSelect
                    name='pickupProvince'
                    label={t('common.province')}
                    placeholder={t('common.province')}
                    options={formatAddressData(provincesState)}
                    value={provinceSelect}
                    onChange={onChangeProvince}
                    isSubmited={isSubmited}
                    disabled={isEdit}
                    validate={{
                        required: { value: true, errorMessage: t('common.required.province') },
                    }}
                />
                <RemakeSelect
                    name='pickupDistrict'
                    label={t('common.district')}
                    placeholder={t('common.district')}
                    options={formatAddressData(districtsState)}
                    value={districtSelect}
                    onChange={onChangeDistrict}
                    isSubmited={isSubmited}
                    disabled={isEdit}
                    validate={{
                        required: { value: true, errorMessage: t('common.required.district') },
                    }}
                />
                <RemakeSelect
                    name='pickupWard'
                    label={t('common.ward')}
                    placeholder={t('common.ward')}
                    options={formatAddressData(wardsState)}
                    value={wardSelect}
                    onChange={onChangeWard}
                    isSubmited={isSubmited}
                    disabled={isEdit}
                    validate={{
                        required: { value: true, errorMessage: t('common.required.ward') },
                    }}
                />
            </RowContainer>
            <RowContainer>
                {isCreateOrdersWithoutAuth && (
                    <>
                        <RemakeAvField
                            height={'35px'}
                            name="pickupAddress"
                            label={t('common.address')}
                            placeholder={t('common.required.address')}
                            type="text"
                            value={pickupAddress}
                            disabled={isEdit}
                            validate={{
                                required: { value: true, errorMessage: t('common.required.address') },
                            }}
                        />
                        <RemakeAvField
                            height={'35px'}
                            name="email"
                            label={t('auth.login.email')}
                            placeholder={t('auth.login.email_required_message')}
                            type="email"
                            value={email}
                            disabled={isEdit}
                            validate={{
                                required: { value: true, errorMessage: t('auth.login.email_required_message') },
                                email: { value: true, errorMessage: t('auth.login.email_format_message') }
                            }}
                        />
                    </>
                )}
                {!isCreateOrdersWithoutAuth && (
                    <AddressContainer>
                        <RemakeAvField
                            height={'35px'}
                            name="pickupAddress"
                            label={t('common.address')}
                            placeholder={t('common.required.address')}
                            type="text"
                            value={pickupAddress}
                            disabled={isEdit}
                            validate={{
                                required: { value: true, errorMessage: t('common.required.address') },
                            }}
                        />
                    </AddressContainer>
                )}
                <RemakeAvField
                    height={'35px'}
                    name="pickupPostalCode"
                    label={t('orders.postal_code')}
                    placeholder={t('orders.required.postal_code')}
                    type="number"
                    value={pickupPostalCode}
                    disabled={isEdit}
                    validate={{
                        required: { value: true, errorMessage: t('orders.required.postal_code') },
                    }}
                />
            </RowContainer>
        </PickupAddressFormContainer>
    )
}

export default memo(PickupInfoForm)
