import styled from 'styled-components';

export const Container = styled.div`

`;

export const Wrapper = styled.div`
    z-index: 99999;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    display: flex;
    top: -150px;
`;

export const Image = styled.img`
    height: 100px;
    transition: ease-in 0.5s;
`;
