import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const ProfileContainer = styled.div`
    color: ${Colors.dark};
`;

export const PageTitle = styled.div`
    color: ${Colors.dark};
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
`;

export const ProfileFormContainer = styled.div`

`;

export const Title = styled.p`
    font-size: 15px;
    margin-top: 10px;
`;

export const FieldContainer = styled.div`
    margin-top: 1rem;

`;

export const RowContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    color: ${Colors.dark};

    @media ${DEVICE_RESPONSIVE.mobile} {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
`;

export const ButtonsContainer = styled.div`
    display: ${(props) => (props.isShow || props.isShow === undefined ) ? 'flex' : 'none'};
    justify-content: flex-end;
    margin-top: 20px;
`;