import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// component
import BannerComponent from './Banner';
import TopHeaderComponent from './TopHeader';
import ContentComponent from './Content';
import CreateOrder from './CreateOrder';
import FooterComponent from './Footer';
import SearchComponent from './Search';
import MobileTopHeader from './MobileTopHeader';
import DialogComponent from '../DialogComponent';
import AddOrdersComponent from '~/components/Orders/Add';
import DialogMessage from '~/components/DialogMessage';
import AddOrdersForMobile from '~/components/Orders/AddForMobile';
// actions
import { clearCreateOrdersOther } from '~/modules/orders/actions';
import { getSettings } from '~/modules/settings/actions';
// utils
import { handleGetCookies } from '~/utils/cookies';
import { redirectTo } from '~/utils/navigations';
// constant
import { COOKIE_KEY, ROLES } from '~/constants/common';
import { ROUTE } from '~/constants/routes';

function HomeComponent(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { createOrdersWithOtherData = {} } = useSelector(state => state.orders);
    const userInfo = handleGetCookies(COOKIE_KEY.LOGIN);
    const { token, UserRole } = userInfo || {};
    const { RoleType } = UserRole || {};
    const { name: userRole } = RoleType || {};
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDialog, setIsOpenDialog] = useState(false);

    useEffect(() => {
        dispatch(getSettings());
    },[]);

    useEffect(() => {
        const { status } = createOrdersWithOtherData;
        if (status === 201) {
            setIsOpenDialog(true);
            setIsOpen(false);
            dispatch(clearCreateOrdersOther())
        }
    }, [createOrdersWithOtherData])

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const onCreateOrders = () => {
        if (token && userRole !== ROLES.EMPLOYEE) {
            return redirectTo(ROUTE.ORDERS.ADD);
        }
        setIsOpen(true);
    }

    const handleCreateNew = () => {
        setIsOpenDialog(false);
        setIsOpen(true);
    }

    return (
        <>
            <TopHeaderComponent />
            <MobileTopHeader />
            <BannerComponent isOpen={isOpen} onCreateOrders={onCreateOrders} children={<SearchComponent />} />
            <ContentComponent />
            <CreateOrder onCreateOrders={onCreateOrders} />
            <FooterComponent />
            <DialogComponent isOpen={isOpen} toggle={toggle}>
                {isMobile ? (
                    <AddOrdersForMobile
                        {...props}
                        isCreateOrdersWithoutAuth={true}
                        onCancel={toggle}
                    />
                ) : (
                    <AddOrdersComponent
                        {...props}
                        isCreateOrdersWithoutAuth={true}
                        onCancel={toggle}
                    />
                )}
            </DialogComponent>
            <DialogMessage
                title={t('common.success')}
                message={t('orders.create_orders_with_other_success')}
                isOpen={isOpenDialog}
                rightLabel={t('orders.create_new')}
                leftAction={() => setIsOpenDialog(false)}
                rightAction={handleCreateNew}
            />
        </>
    )
}

export default HomeComponent
