import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import HeadingTitle from '~/components/HeadingTitle';
import { ReportContainer, ReportItem, ReportList, LeftContent, RightContent } from '~/components/Reports/Employee/styles';
import { UserAstronaut } from '@styled-icons/fa-solid/UserAstronaut';
import { Colors } from '~/constants/styles';
import { getReportsByCustomer } from '~/modules/report/actions';
import { formatNumberWithZero } from '~/utils/helper';
import { Users } from '@styled-icons/fa-solid/Users';
import { ROUTE } from '~/constants/routes';
import { CUSTOMER_TYPE, USER_STATUS_TYPE } from '~/constants/common';

function CustomerReports() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { reportsCustomerData = {} } = useSelector(state => state.reports);
    const {
        totalActivePartner,
        totalCustomer,
        totalInactivePartner,
        totalOther,
        totalPartner,
        totalWaitingVerifyPartner
    } = reportsCustomerData;

    useEffect(() => {
        dispatch(getReportsByCustomer())
    }, []);

    const handleViewDetail = (customerType) => {
        let route = '';
        if (!customerType) {
            route = `${ROUTE.CUSTOMER.LIST}`;
        } else {
            route = `${ROUTE.CUSTOMER.LIST}?customerType=${customerType}`;
        }
        window.open(route);
    }

    const handleViewDetailPartner = (status) => {
        let route = `${ROUTE.CUSTOMER.LIST}?customerType=${CUSTOMER_TYPE.PARTNER}&status=${status}`;
        window.open(route);
    }

    return (
        <ReportContainer>
            <HeadingTitle
                type="large"
                label={t('common.customer.name')}
            />
            <ReportList columns={3}>
                <ReportItem onClick={() => handleViewDetail(null)}>
                    <LeftContent>
                        <span>{t('report.customer.total')}</span>
                        <h3>{formatNumberWithZero(totalCustomer)}</h3>
                    </LeftContent>
                    <RightContent>
                        <Users color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
                <ReportItem onClick={() => handleViewDetail(CUSTOMER_TYPE.OTHER)}>
                    <LeftContent>
                        <span>{t('report.customer.total_other')}</span>
                        <h3>{formatNumberWithZero(totalOther)}</h3>
                    </LeftContent>
                    <RightContent>
                        <Users color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
                <ReportItem onClick={() => handleViewDetail(CUSTOMER_TYPE.PARTNER)}>
                    <LeftContent>
                        <span>{t('report.customer.total_partner')}</span>
                        <h3>{formatNumberWithZero(totalPartner)}</h3>
                    </LeftContent>
                    <RightContent>
                        <Users color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
                <ReportItem onClick={() => handleViewDetailPartner(USER_STATUS_TYPE.ACTIVE)}>
                    <LeftContent>
                        <span>{t('report.customer.total_partner_active')}</span>
                        <h3>{formatNumberWithZero(totalActivePartner)}</h3>
                    </LeftContent>
                    <RightContent>
                        <Users color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
                <ReportItem onClick={() => handleViewDetailPartner(USER_STATUS_TYPE.WAITING_VERIFY)}>
                    <LeftContent>
                        <span>{t('report.customer.total_partner_waiting_verify')}</span>
                        <h3>{formatNumberWithZero(totalWaitingVerifyPartner)}</h3>
                    </LeftContent>
                    <RightContent>
                        <Users color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
                <ReportItem onClick={() => handleViewDetailPartner(USER_STATUS_TYPE.INACTIVE)}>
                    <LeftContent>
                        <span>{t('report.customer.total_partner_inactive')}</span>
                        <h3>{formatNumberWithZero(totalInactivePartner)}</h3>
                    </LeftContent>
                    <RightContent>
                        <Users color={Colors.dark} width={40} />
                    </RightContent>
                </ReportItem>
            </ReportList>
        </ReportContainer>
    )
}

export default CustomerReports
