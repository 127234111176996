import React from 'react';
import {
    Container,
} from '~/components/Exports/styles';
import ExportIcon from '~/assets/icons/export_ic.png';
import { handleExport } from '~/modules/export/actions';
import { useDispatch } from 'react-redux';

export default function Exports(props) {
    const dispatch = useDispatch();

    const handleExportData = () => {
        const { exportType, roleType, searchParams } = props;
        const params = {
            exportType,
            roleType,
            ...searchParams
        }

        dispatch(handleExport(params));
    }


    return (
        <Container onClick={() => handleExportData()}>
            <img src={ExportIcon} />
        </Container>
    )
}
