import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const Title = styled.div`
    color: ${Colors.dark};
    font-size: 30px;
    font-weight: 600;
    text-align: center;

    @media ${DEVICE_RESPONSIVE.mobile} {
        font-size: 20px;
    }
`;

export const ContentContainer = styled.div`
    width: 1075px;
    margin: 0 auto;
    margin-top: 142px;

    @media ${DEVICE_RESPONSIVE.desktop} {
        width: auto;
    }

    @media ${DEVICE_RESPONSIVE.mobile} {
        margin-top: 80px;
    }
`;

export const ListItem = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    @media ${DEVICE_RESPONSIVE.mobile} {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const ContentItem = styled.div`
    max-width: 320px;
    margin: 0 auto;
    margin-top: 53px;
`;

export const ItemText = styled.div`
    color: ${Colors.dark};
    font-size: 22px;
    font-weight: 600;
    margin-top: 24px;
    @media ${DEVICE_RESPONSIVE.mobile} {
        margin-top: 5px;
    }
`;

export const ItemMessage = styled.div`
    color: ${Colors.dark};
    font-size: 16px;
    font-weight: 400;
    margin-top: 25px;
`;