import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const TakePictureContainer = styled.div`
    display: flex;
`;

export const LeftContainer = styled.div`
    width: 91%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 56px;
        height: 56px;
    }
    p {
        margin-top: 5px;
        font-weight: 600;
    }
`;

export const RightContainer = styled.div`
    span {
        font-size: 12px;
    }
`;

export const ImagePreviewContainer = styled.div`
    p {
        font-size: 12px;
        margin-bottom: 10px;
    }
    img {
        width: 50%;
        float: left;
    }
    div {
        color: red;
        margin-top: 5px;
        font-weight: 600;
    }
`;

export const CameraContainer = styled.div`
    p {
        font-weight: 600;
        margin-top: 5px;
    }
`;
