import produce from 'immer';
import { ACTION_TYPE } from '~/constants/actionTypes';

const INITIAL_STATE = {
    isExport: false
};

const exportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPE.EXPORT.EXPORT_DATA_SUCCESS:
            return produce(state, draft => {
                draft.isExport = true;
            });

        default:
            return state;
    }
}

export default exportReducer;
