import React from 'react';
import Avt from '~/assets/images/avt.png';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// styles
import { ProfileWrapper, ProfileMenu, ProfileItem, ProfileContainer } from './styles';
// utils
import { redirectTo } from '~/utils/navigations';
import { handleGetCookies, handleRemoveCookies } from '~/utils/cookies';
// constant
import { ROUTE } from '~/constants/routes';
import { COOKIE_KEY, USER_PROFILE_MENU } from '~/constants/common';
// actions
import { logout } from '~/modules/auth/actions';
import Icon from '../Icon';
import { UserCircle } from '@styled-icons/heroicons-outline/UserCircle';
import { Colors } from '~/constants/styles';

function UserProfile() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userInfo = handleGetCookies(COOKIE_KEY.LOGIN);
    const { email, avatar = Avt, fullName, UserRole } = userInfo || {};
    const { RoleType } = UserRole || {};
    const { name: userRole } = RoleType || {};

    const onLogout = () => {
        dispatch(logout());
        handleRemoveCookies(COOKIE_KEY.LOGIN);
        redirectTo(ROUTE.HOME);
    }

    const handleClickMenu = (route) => {
        if (route === 'LOGOUT') {
            return onLogout();
        }
        redirectTo(route)
    }

    const renderMenuItem = () => {
        return USER_PROFILE_MENU.map(menu => {
            const { id, name, route, icon, roles } = menu;
            const isAllow = roles.includes(userRole);
            if (!isAllow) return null;
            return (
                <ProfileItem key={id} onClick={() => handleClickMenu(route)}>
                    <Icon
                        name={icon}
                    />
                    <div>{name}</div>
                </ProfileItem>
            )
        })
    }

    return (
        <ProfileContainer>
            <ProfileWrapper>
                <UserCircle color={Colors.dark} width={40}/>
                <div>
                    {fullName && <span>{`Chào, ${fullName}`}</span>}
                    <span>{email}</span>
                </div>
            </ProfileWrapper>
            <ProfileMenu className='menu-drop'>
                {renderMenuItem()}
            </ProfileMenu>
        </ProfileContainer>
    )
}

export default UserProfile
