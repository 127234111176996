import { call, all, takeLatest } from 'redux-saga/effects';
import { ACTION_TYPE } from '~/constants/actionTypes';
import httpRequest from '~/services/api';
import { handleGetApiPath } from '~/utils/api';
import { API } from '~/services/app.routes';
import * as notification from '~/utils/notifications';
import { NOTIFICATION_TYPE } from '~/constants/common';
import i18n from '~/utils/i18n';
import { StatusCodes } from 'http-status-codes';
import config from '~/config';
import { API_SERVICE_TYPE } from '~/constants/api';

function* handleExport({params}) {
    const path = handleGetApiPath(API.EXPORT, params);
    const result = yield call(httpRequest.get, path);
    const { status, error } = result;
    if (status === StatusCodes.OK) {
        const urlOpen = `${config[API_SERVICE_TYPE.API_URL_FIRST_SERVER]}${path}`
        window.open(urlOpen);
        notification.custom(i18n.t('common.success'), i18n.t('export.success'), NOTIFICATION_TYPE.SUCCESS);
    } else {
        const { message } = error || {};
        notification.custom(i18n.t('common.fail'), i18n.t(`common.${message}`), NOTIFICATION_TYPE.ERROR);
    }
}

export default all([
    takeLatest(ACTION_TYPE.EXPORT.EXPORT_DATA, handleExport),
]);
