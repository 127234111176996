import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const RadioInputContainer = styled.div`
    display: flex;
    align-items: center;
    height:${(props) => props.height || 'auto'};
    .form-group {
        .form-control {
            display: flex;
            color: ${Colors.dark};
            font-size: 14px;
            .form-check {
                margin-right: 10px;
                .form-check-input {
                    margin-top: -2px;
                }
            }
        }
    }

    @media ${DEVICE_RESPONSIVE.mobile} {
        flex-direction: column;
        align-items: flex-start;
        height: auto !important;
        .form-group {
            .form-control {
                font-size: 11px;
            }
        }
    }
`;

export const Label = styled.div `
    margin-top: 7px;
    font-size: 13px;
    margin-right: 18px;
    font-weight: 500;
`