import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// components
import RemakeAvField from '~/components/RemakeAvField';
import RemakeSelect from '~/components/RemakeSelect';
import ParcelInfoComponent from '../ParcelInfo';
import ServiceInfoComponent from '../ServiceInfo';
// styles
import { RowContainer, AddressContainer } from '~/components/Orders/PickupInfoForm/styles';
import { PageTitle } from '~/components/ProfileInfo/styles';
import { OrdersInfoContainer, ShipmentInfoContainer, FormTitle, ParcelFormContainer, ServiceFormContainer } from './styles';
//utils
import { formatAddressData, isEmpty } from '~/utils/helper';
// actions
import { getDistricts, getProvinces, getWards } from '~/modules/address/actions';
import OrdersStatus from '../OrdersStatus';
import ShipInfo from '../ShipInfo';
import { clearShippingFee } from '~/modules/orders/actions';

let isGetDistricts = true;
let isGetWards = true;

function OrdersInfoForm(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { provinces = [], districts = [], wards = [] } = useSelector(state => state.address);
    const {
        ordersInfoForm,
        isEdit,
        shippingFeeData,
        onChangeRecipientProvince,
        onChangeRecipientDistrict,
        isSubmited,
        step,
        isCreateOrdersWithoutAuth
    } = props;
    const {
        recipientName,
        recipientPhone,
        recipientPostalCode,
        recipientAddress,
    } = ordersInfoForm || {}
    //state
    const [provincesState, setProvincesState] = useState([]);
    const [districtsState, setDistrictsState] = useState([]);
    const [wardsState, setWardsState] = useState([]);
    var [provinceSelect, setProvinceSelect] = useState(null);
    var [districtSelect, setDistrictSelect] = useState(null);
    var [wardSelect, setWardSelect] = useState(null);
    const [shippingFee, setShippingFee] = useState(0);

    useEffect(() => {
        dispatch(getProvinces());
        return function cleanup() {
            dispatch(clearShippingFee());
        };
    }, []);

    useEffect(() => {
        const {
            recipientProvince,
            recipientDistrict,
            recipientWard,
        } = ordersInfoForm || {};
        if (recipientProvince?.value) {
            getDistrictsAPI(recipientProvince?.value);
        }
        if (recipientDistrict?.value) {
            getWardsAPI(recipientDistrict?.value);
        }
        setProvinceSelect(recipientProvince);
        setDistrictSelect(recipientDistrict);
        setWardSelect(recipientWard);
    }, [ordersInfoForm]);

    useEffect(() => {
        setProvincesState(provinces);
    }, [provinces]);

    useEffect(() => {
        const { fee } = shippingFeeData;
        setShippingFee(fee);
    }, [shippingFeeData]);

    useEffect(() => {
        if (!isEmpty(districts) && isGetDistricts) {
            setDistrictsState(districts);
            isGetDistricts = false;
        }
    }, [districts]);

    useEffect(() => {
        if (!isEmpty(wards) && isGetWards) {
            setWardsState(wards);
            isGetWards = false;
        }
    }, [wards]);

    const getDistrictsAPI = (value) => {
        const params = {
            provinceId: value
        }
        dispatch(getDistricts(params));
    }

    const getWardsAPI = (value) => {
        const params = {
            districtId: value
        }
        dispatch(getWards(params));
    }

    const onChangeProvince = (itemSelected) => {
        const { value } = itemSelected;
        isGetDistricts = true;
        getDistrictsAPI(value);
        setProvinceSelect(itemSelected);
        setDistrictSelect(null);
        setWardSelect(null);
        if (onChangeRecipientProvince) {
            onChangeRecipientProvince(itemSelected);
        }
    }

    const onChangeDistrict = (itemSelected) => {
        const { value } = itemSelected;
        isGetWards = true;
        getWardsAPI(value);
        setDistrictSelect(itemSelected);
        setWardSelect(null);
        if (onChangeRecipientDistrict) {
            onChangeRecipientDistrict(itemSelected);
        }
    }

    const onChangeWard = (itemSelected) => {
        setWardSelect(itemSelected);
    }

    const onChangeShippingFee = (event) => {
        const value = event.target?.value || 0;
        setShippingFee(value);
    }

    const isShowStep2Form = ((step && step === 2) || !step);
    const isShowStep3Form = ((step && step === 3) || !step);
    const isShowStep4Form = ((step && step === 4) || !step);
    const isShowForm = ((step && step >= 2) || !step);

    return (
        <OrdersInfoContainer isShow={isShowForm}>
            <PageTitle>{t('orders.infor')}</PageTitle>
            <ShipmentInfoContainer isShow={isShowStep2Form}>
                <FormTitle>{t('orders.shipment_info')}</FormTitle>
                <RowContainer>
                    <RemakeAvField
                        height={'35px'}
                        name="recipientName"
                        label={t('common.full_name')}
                        placeholder={t('common.required.full_name')}
                        type="text"
                        value={recipientName}
                        disabled={isEdit}
                        validate={{
                            required: { value: true, errorMessage: t('common.required.full_name') },
                        }}
                    />
                    <RemakeAvField
                        height={'35px'}
                        name="recipientPhone"
                        label={t('common.phone_number')}
                        placeholder={t('common.required.phone_number')}
                        type="number"
                        value={recipientPhone}
                        disabled={isEdit}
                        validate={{
                            required: { value: true, errorMessage: t('common.required.phone_number') },
                            minLength: { value: 10, errorMessage: t('auth.sign_up.phone_number_incorrect_format') },
                            maxLength: { value: 16, errorMessage: t('auth.sign_up.phone_number_incorrect_format') },
                        }}
                    />
                </RowContainer>
                <RowContainer>
                    <RemakeSelect
                        name='recipientProvince'
                        label={t('common.province')}
                        placeholder={t('common.required.province')}
                        options={formatAddressData(provincesState)}
                        value={provinceSelect}
                        onChange={onChangeProvince}
                        isSubmited={isSubmited}
                        disabled={isEdit}
                        validate={{
                            required: { value: true, errorMessage: t('common.required.province') },
                        }}
                    />
                    <RemakeSelect
                        name='recipientDistrict'
                        label={t('common.district')}
                        placeholder={t('common.required.district')}
                        options={formatAddressData(districtsState)}
                        value={districtSelect}
                        onChange={onChangeDistrict}
                        isSubmited={isSubmited}
                        disabled={isEdit}
                        validate={{
                            required: { value: true, errorMessage: t('common.required.district') },
                        }}
                    />
                    <RemakeSelect
                        name='recipientWard'
                        label={t('common.ward')}
                        placeholder={t('common.required.ward')}
                        options={formatAddressData(wardsState)}
                        value={wardSelect}
                        onChange={onChangeWard}
                        isSubmited={isSubmited}
                        disabled={isEdit}
                        validate={{
                            required: { value: true, errorMessage: t('common.required.ward') },
                        }}
                    />
                </RowContainer>
                <RowContainer>
                    <AddressContainer>
                        <RemakeAvField
                            height={'35px'}
                            name="recipientAddress"
                            label={t('common.address')}
                            placeholder={t('common.required.address')}
                            type="text"
                            value={recipientAddress}
                            disabled={isEdit}
                            validate={{
                                required: { value: true, errorMessage: t('common.required.address') },
                            }}
                        />
                    </AddressContainer>
                    <RemakeAvField
                        height={'35px'}
                        name="recipientPostalCode"
                        label={t('orders.postal_code')}
                        placeholder={t('orders.required.postal_code')}
                        type="number"
                        value={recipientPostalCode}
                        disabled={isEdit}
                        validate={{
                            required: { value: true, errorMessage: t('orders.required.postal_code') },
                        }}
                    />
                </RowContainer>
            </ShipmentInfoContainer>
            <ParcelFormContainer isShow={isShowStep3Form}>
                <ParcelInfoComponent
                    ordersInfoForm={ordersInfoForm}
                    onChangeShippingFee={onChangeShippingFee}
                    onBlurWeight={props?.onBlurWeight}
                    shippingFeeData={shippingFeeData}
                    isMobile={isShowStep3Form}
                    isEdit={isEdit}
                    isCreateOrdersWithoutAuth={isCreateOrdersWithoutAuth}
                />
            </ParcelFormContainer>
            <ServiceFormContainer isShow={isShowStep4Form}>
                <ServiceInfoComponent
                    ordersInfoForm={ordersInfoForm}
                    shippingFee={shippingFee}
                    isEdit={isEdit}
                />
            </ServiceFormContainer>
            {isEdit && <ShipInfo ordersInfoForm={ordersInfoForm} />}
        </OrdersInfoContainer>
    )
}

export default memo(OrdersInfoForm);
