import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';
import { CreateOrderContainer, OrderTitle } from './styles';
import RemakeButton from '~/components/RemakeButton';
import { Colors } from '~/constants/styles';

function CreateOrder(props) {

    const { t } = useTranslation();

    return (
        <CreateOrderContainer>
            <ScrollAnimation animateIn={'animate__zoomInDown'} duration={1} animateOnce>
                <OrderTitle>
                    {t('home.order.create_order_with_us')}
                </OrderTitle>
            </ScrollAnimation>
            <ScrollAnimation animateIn={'animate__zoomInDown'} duration={1} animateOnce>
                <RemakeButton
                    type="light"
                    label={t('home.order.create_order')}
                    labelStyles={{ color: Colors.primary, fontSize: 20, fontWeight: 700 }}
                    buttonStyles={{ height: 60, width: 300, borderRadius: 10 }}
                    onClick={props?.onCreateOrders}
                />
            </ScrollAnimation>
        </CreateOrderContainer>
    )
}

export default CreateOrder
