import produce from 'immer';
import { ACTION_TYPE } from '~/constants/actionTypes';

const INITIAL_STATE = {
    provinces: [],
    districts: [],
    wards: []
};

const address = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPE.ADDRESS.GET_PROVINCES_SUCCESS:
            return produce(state, draft => {
                const { provinces = [] } = action;
                draft.provinces = provinces;
            });
        case ACTION_TYPE.ADDRESS.GET_DISTRICTS_SUCCESS:
            return produce(state, draft => {
                const { districts = [] } = action;
                draft.districts = districts;
            });
        case ACTION_TYPE.ADDRESS.GET_WARDS_SUCCESS:
            return produce(state, draft => {
                const { wards = [] } = action;
                draft.wards = wards;
            });
        case ACTION_TYPE.ADDRESS.RESET_DISTRICTS:
            return produce(state, draft => {
                draft.districts = [];
            });
        case ACTION_TYPE.ADDRESS.RESET_WARDS:
            return produce(state, draft => {
                draft.wards = [];
            });
        default:
            return state;
    }
}

export default address;
