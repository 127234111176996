import { ACTION_TYPE } from '~/constants/actionTypes';

const handleGetUsers = (params) => {
    return { type: ACTION_TYPE.USER.GET_USER, params };
}

const handleGetUsersSuccess = (users, total) => {
    return {
        type: ACTION_TYPE.USER.GET_USER_SUCCESS,
        users,
        total
    };
}

const handleDeleteUser = (params) => {
    return {
        type: ACTION_TYPE.USER.DELETE_USER,
        params,
    };
}

const handleDeleteUserSuccess = (data) => {
    return {
        type: ACTION_TYPE.USER.DELETE_USER_SUCCESS,
        data
    };
}

const handleCreateUser = (body) => {
    return {
        type: ACTION_TYPE.USER.CREATE_USER,
        body
    };
}

const handleCreateUserSuccess = (data) => {
    return {
        type: ACTION_TYPE.USER.CREATE_USER_SUCCESS,
        data
    };
}

const handleGetUserById = (params) => {
    return {
        type: ACTION_TYPE.USER.GET_USER_BY_ID,
        params
    };
}

const handleGetUserByIdSuccess = (data) => {
    return {
        type: ACTION_TYPE.USER.GET_USER_BY_ID_SUCCESS,
        data
    };
}

const clearDataGetUserById = () => {
    return {
        type: ACTION_TYPE.USER.CLEAR_GET_EMPLOYEE_ID,
    };
}

const handleUpdateUser = (body) => {
    return {
        type: ACTION_TYPE.USER.UPDATE_USER,
        body
    };
}

const handleUpdateUserSuccess = (data) => {
    return {
        type: ACTION_TYPE.USER.UPDATE_USER_SUCCESS,
        data
    };
}

const clearUpdateUserSuccess = (data) => {
    return {
        type: ACTION_TYPE.USER.UPDATE_USER_SUCCESS,
        data
    };
}

export {
    handleGetUsers,
    handleDeleteUser,
    handleGetUsersSuccess,
    handleDeleteUserSuccess,
    handleCreateUser,
    handleCreateUserSuccess,
    handleGetUserById,
    handleGetUserByIdSuccess,
    handleUpdateUser,
    handleUpdateUserSuccess,
    clearDataGetUserById,
    clearUpdateUserSuccess
}
