import React from 'react';
import {
    Container,
} from '~/components/Card/styles';

export default function Card(props) {
    return (
        <Container {...props}>
            {props.children}
        </Container>
    )
}
