import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const OrdersItem = styled.div`
    background: #EBEBEB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 8px;
`;

export const OrdersInfoContainer = styled.div`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
`;

export const BoxImage = styled.img`
    width: 35px;
    height: 36px;
    margin-right: 10px;
`;

export const BoxImageHidden = styled.img`
    width: 35px;
    height: 36px;
    opacity: 0;
`;

export const OrdersInfo = styled.div`
    width: 100%;
`;

export const OrdersInfoItem = styled.div`
    margin-top: 5px;
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    color: ${Colors.dark};
    margin-right: 5px;

    div:nth-child(1) {
        width: 35%;
    }
    div:nth-child(2) {
        width: 65%;
        font-weight: 500;
        line-height: 18px;
    }
    img  {
        width: 24px;
        height: 24px;
    }
`;

export const TotalValue = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: ${Colors.dark};
    text-align: center;
    margin-top: 12px;
`;

export const ButtonUpdate = styled.div`
    width: 100px;
    height: 30px;
    background-color: ${Colors.primary};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 10px;
    cursor: pointer;

    span {
        font-weight: 600;
        font-size: 12px;
        color: #F4F4F4;
        text-transform: uppercase;
    }
`;

export const MoreInfoContainer = styled.div`
    border-top: 1px solid #C6C6C7;
    margin-top: 5px;
`;