import React, { useState } from 'react'
import { AvField } from 'availity-reactstrap-validation';
import { InputContainer, EyeIcon } from './styles';
import eyeIcon from '~/assets/images/auth/eye.svg';
import eyeIcon2 from '~/assets/images/auth/eye2.svg';

function PasswordInput(props) {

    const [ isPassword, setIsPassword ] = useState(true);

    const handleShowHide = () => {
        setIsPassword(!isPassword);
    }

    return (
        <InputContainer {...props}>
            <AvField {...props} type={isPassword ? "password" : "Text"} />
            <EyeIcon {...props} src={isPassword ? eyeIcon : eyeIcon2} onClick={handleShowHide} />
        </InputContainer>
    )
}

export default PasswordInput
