const ROUTE = {
    HOME: '/',
    NO_PERMISSION: '/no-permission',
    ORDERS: {
        LIST: '/orders/list',
        ADD: '/orders/add',
        EDIT: '/orders/edit',
        EDIT_ID: '/orders/edit/:id',
        EMPLOYEE: '/orders/employee'
    },
    CUSTOMER: {
        LIST: '/customer/list',
        ADD: '/customer/add',
        EDIT: '/customer/edit',
        EDIT_ID: '/customer/edit/:id'
    },
    EMPLOYEE: {
        LIST: '/employee/list',
        ADD: '/employee/add',
        EDIT: '/employee/edit',
        EDIT_ID: '/employee/edit/:id'
    },
    AUTH: {
        LOGIN: '/login',
        SIGN_UP: '/sign-up',
        FORGOT_PASSWORD: '/forgot-password',
        RESET_PASSWORD: '/reset-password',
        CHANGE_PASSWORD: '/change-passworrd'
    },
    MY_PROFILE: '/my-profile',
    SETTINGS: {
        MAIN: '/settings',
        STATIC_PAGE_EDITING: '/settings/page/:key',
        STATIC_PAGE: '/settings/page',
        STATIC_PAGE_FOR_USER_KEY: '/page/:key',
        STATIC_PAGE_FOR_USER: '/page'
    },
    REPORTS: {
        MAIN: '/reports',
    }
}

export {
    ROUTE
}