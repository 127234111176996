import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const OrdersEmployeeContainer = styled.div`
    padding: 15px;
`;

export const OrdersListHeader = styled.div`
    margin-top: 10px;
    .form-control {
        height: 35px;
        &::placeholder {
            color: #DFE0E8;
            font-size: 14px;
        }
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const LogoImage = styled.img`
    width: 132px;
    height: 38px;
    cursor: pointer;
`;

export const HeaderTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: 18px;
        font-weight: 600;
        color: ${Colors.dark};
    }
`;

export const OrdersList = styled.div`
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    display: grid;
    margin-top: 20px;

    @media ${DEVICE_RESPONSIVE.mobile} {
        grid-template-columns: 1fr;
    }
`;

export const UpdateFormContainer = styled.div`
    grid-template-columns: 1fr;
    grid-gap: 10px;
    display: grid;
    color: ${Colors.dark};
`;

export const FormTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${Colors.dark};
    margin-bottom: 15px;
`;

export const ButtonSubmitWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
`;

export const TabsContainer = styled.div`
    margin-top: 20px;
    .nav-tabs {
        .nav-item {
            width: 50%;
            cursor: pointer;
            font-weight: 500;
            .nav-link {
                color: #98a4b5;
            }
            .active {
                color: ${Colors.dark};
                border-color: ${Colors.dark} ${Colors.dark} #fff;
                font-weight: 600;
            }
        }
    }
`;