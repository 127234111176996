import produce from 'immer';
import { ACTION_TYPE } from '~/constants/actionTypes';

const INITIAL_STATE = {
    files: []
};

const UploadFileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPE.UPLOAD_FILE.UPLOAD_FILES_SUCCESS:
            return produce(state, draft => {
                draft.files = action.data;
            });
        case ACTION_TYPE.UPLOAD_FILE.CLEAR:
            return produce(state, draft => {
                draft.files = [];
            });

        default:
            return state;
    }
}

export default UploadFileReducer;
