import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import ProfileInfoComponent from '~/components/ProfileInfo';
import { resetDistricts, resetWards } from '~/modules/address/actions';
import { clearDataGetUserById, handleCreateUser, handleGetUserById, handleUpdateUser } from '~/modules/user/actions';

export default function EmployeeAdd(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userData = {} } = useSelector(state => state.user);
    const { match } = props;
    const { params: { id } = {} } = match || {};

    useEffect(() => {
        if (id) {
            const params = {
                userId: id
            }
            dispatch(handleGetUserById(params))
        }
        return function cleanup() {
            dispatch(clearDataGetUserById());
            dispatch(resetDistricts());
            dispatch(resetWards());
        };
    },[]);

    const handleSubmit = (data) => {
        let body = {
            data,
            isEmployee: true
        }
        if (id) {
            body.params = {
                userId: id
            }
            dispatch(handleUpdateUser(body));
        } else {
            dispatch(handleCreateUser(body));
        }
    }

    return (
        <div>
            <ProfileInfoComponent
                pageTitle={!id ? t('employee.create') : t('employee.edit')}
                handleSubmit={handleSubmit}
                values={userData}
            />
        </div>
    );
}
