import EnglishIcon from '~/assets/icons/english_ic.svg';
import VietnamIcon from '~/assets/icons/vietnam_ic.svg';
import i18n from '~/utils/i18n';
import { ROUTE } from './routes';

const NOTIFICATION_TYPE = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    INFO: 'INFO',
    WARNING: 'WARNING'
}

const LOCAL_STORAGE_KEY_TYPE = {
    THEME_MODE: 'THEME_MODE',
    CURRENT_LANGUAGE: 'CURRENT_LANGUAGE',
    PICK_UP_ADDRESS: 'PICK_UP_ADDRESS'
}

const THEME_MODE_TYPE = {
    LIGHT: 'Light',
    DARK: 'Dark',
}

const LANGUAGES = [
    {
        key: 'en',
        value: 'English',
        flag: EnglishIcon
    },
    {
        key: 'vi',
        value: 'Vietnamese',
        flag: VietnamIcon
    }
]

const ROLES = {
    ADMIN: 'ADMIN',
    CUSTOMER: 'CUSTOMER',
    EMPLOYEE: 'EMPLOYEE'
}

const MENUS_SIDEBAR = [
    {
        icon: 'report',
        label: 'dashboard.sidebar.menu.reports',
        router: '/reports',
        roles: [ROLES.ADMIN]
    },
    {
        icon: 'box',
        label: 'dashboard.sidebar.menu.orders_management',
        router: '/orders/list',
        roles: [ROLES.ADMIN, ROLES.CUSTOMER]
    },
    {
        icon: 'user-circle',
        label: 'dashboard.sidebar.menu.customer_management',
        router: '/customer/list',
        roles: [ROLES.ADMIN]
    },
    {
        icon: 'user-pin',
        label: 'dashboard.sidebar.menu.employee_management',
        router: '/employee/list',
        roles: [ROLES.ADMIN]
    },
    {
        icon: 'cog',
        label: 'dashboard.sidebar.menu.settings',
        router: '/settings',
        roles: [ROLES.ADMIN]
    },
]

const SUB_MENU_KEY = {
    ORDERS_STATUS: 'ORDERS_STATUS',
    APPEARANCE: 'APPEARANCE',
    PAGE_STATIC: 'PAGE_STATIC'
}

const SUB_MENUS_SIDEBAR = [
    {
        key: SUB_MENU_KEY.ORDERS_STATUS,
        icon: 'list-minus',
        label: 'dashboard.sidebar.submenu.orders_status',
        router: '/settings/list',
    },
    {
        key: SUB_MENU_KEY.APPEARANCE,
        icon: 'desktop',
        label: 'dashboard.sidebar.submenu.appearance',
        router: '/customer/list',
    },
    {
        key: SUB_MENU_KEY.PAGE_STATIC,
        icon: 'text',
        label: 'dashboard.sidebar.submenu.page_static',
        router: '/customer/list',
    },
]

const size = {
    mobile: '600px', /* Small devices (portrait tablets and large phones, 600px and up) */
    tablet: '768px', /* Medium devices (landscape tablets, 768px and up) */
    laptop: '992px', /* Large devices (laptops/desktops, 992px and up) */
    desktop: '1200px' /* Extra large devices (large laptops and desktops, 1200px and up) */
}

const DEVICE_RESPONSIVE = {
    mobile: `only screen and (max-width: ${size.mobile})`,
    tablet: `only screen and (max-width: ${size.tablet})`,
    laptop: `only screen and (max-width: ${size.laptop})`,
    desktop: `only screen and (max-width: ${size.desktop})`,
};

const COOKIE_KEY = {
    LOGIN: 'VIVUSHIP_USER'
}

const CUSTOMER_TYPE = {
    OTHER: 'OTHER',
    PARTNER: 'PARTNER',
}

const CUSTOMER_TYPE_SELECT = [
    {
        value: 'ALL',
        label: 'Tất cả'
    },
    {
        value: CUSTOMER_TYPE.PARTNER,
        label: 'Đối tác'
    },
    {
        value: CUSTOMER_TYPE.OTHER,
        label: 'Khách vãng lai'
    },
]

const USER_STATUS_TYPE = {
    ACTIVE: 'Active',
    WAITING_VERIFY: 'Waiting_Verify',
    INACTIVE: 'Inactive'
}

const STATUS_OPTIONS = [
    {
        label: 'Tất cả',
        value: 'All'
    },
    {
        label: i18n.t('common.account_status.active'),
        value: USER_STATUS_TYPE.ACTIVE
    },
    {
        label: i18n.t('common.account_status.waiting_verify'),
        value: USER_STATUS_TYPE.WAITING_VERIFY
    },
    {
        label: i18n.t('common.account_status.inactive'),
        value: USER_STATUS_TYPE.INACTIVE
    }
];

const GENDERS = [
    {
        value: 'Male',
        label: 'Nam'
    },
    {
        value: 'Female',
        label: 'Nữ'
    },
    {
        value: 'Other',
        label: 'Khác'
    }
]

const ACTION = {
    EDIT: 'EDIT',
    ADD: 'ADD',
}

const DATE_FORMAT = {
    DD_MM_YYYY: 'd-m-Y',
    DD_MM_YYYY_FULL: 'DD-MM-YYYY HH:mm:ss',
    YYYY_MM_DD: 'YYYY-MM-DD',
    FULL_DATE_TIME: 'dddd, DD-MM-YYYY HH:mm:ss'
}

const SHIPPING_FEE_PAYMENT = {
    SENDER_PAY: 'SenderPay',
    RECEIVER_PAY: 'ReceiverPay'
}

const EXPORT_TYPE = {
    ORDERS: 'ORDERS',
    USERS: 'USERS'
}

const USER_PROFILE_MENU = [
    {
        id: 1,
        name: i18n.t('common.admin_page'),
        route: ROUTE.REPORTS.MAIN,
        icon: 'layout',
        roles: [ROLES.ADMIN, ROLES.CUSTOMER]
    },
    {
        id: 2,
        name: i18n.t('common.orders_management'),
        route: ROUTE.ORDERS.EMPLOYEE,
        icon: 'layout',
        roles: [ROLES.EMPLOYEE]
    },
    {
        id: 3,
        name: i18n.t('common.my_profile_user'),
        route: ROUTE.MY_PROFILE,
        icon: 'user',
        roles: [ROLES.ADMIN, ROLES.CUSTOMER, ROLES.EMPLOYEE]
    },
    {
        id: 4,
        name: i18n.t('auth.change_password'),
        route: ROUTE.AUTH.CHANGE_PASSWORD,
        icon: 'lock-alt',
        roles: [ROLES.ADMIN, ROLES.CUSTOMER, ROLES.EMPLOYEE]
    },
    {
        id: 5,
        name: i18n.t('auth.logout'),
        route: 'LOGOUT',
        icon: 'chevron-right',
        roles: [ROLES.ADMIN, ROLES.CUSTOMER, ROLES.EMPLOYEE]
    },
]

const CATEGORY_TYPE_FILE = {
    ORDERS: 'Orders',
    USERS: 'Users',
    SETTINGS: 'Settings'
}

const SETTINGS_KEY = {
    BRAND_IMAGE: 'BRAND_IMAGE',
    BANNER_IMAGES: 'BANNER_IMAGES',
    AUTH_IMAGE: 'AUTH_IMAGES',
    ABOUT_US: 'about-us',
    SERVICES: 'services',
    CONTACT: 'contact',
    POLICY: 'policy',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
    SECURITY_AND_PRIVACY: 'security-and-privacy',
    SUPPORT: 'support',
    TRANSPORT: 'transport'
}

const SETTINGS_KEY_FORMAT = {
    'about-us': 'ABOUT_US',
    'services': 'SERVICES',
    'contact': 'CONTACT',
    'policy': 'POLICY',
    'terms-and-conditions': 'TERMS_AND_CONDITIONS',
    'security-and-privacy': 'SECURITY_AND_PRIVACY',
    'support': 'SUPPORT',
    'transport': 'TRANSPORT'
}

const DRIVER_IMAGE_URL = 'https://drive.google.com/uc?id=';

const editorSettings = {
    buttonList: [
        ["undo", "redo"],
        ["font", "fontSize"],
        // ['paragraphStyle', 'blockquote'],
        [
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript"
        ],
        ["fontColor", "hiliteColor"],
        ["align", "list", "lineHeight"],
        ["outdent", "indent"],

        ["table", "horizontalRule", "link", "image", "video"],
        // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
        // ['imageGallery'], // You must add the "imageGalleryUrl".
        // ["fullScreen", "showBlocks", "codeView"],
        ["preview", "print"],
        ["removeFormat"]
        // ['save', 'template'],
        // '/', Line break
    ], // Or Array of button list, eg. [['font', 'align'], ['image']]
    defaultTag: "div",
    minHeight: 500,
    showPathLabel: false,
    font: [
        "Logical",
        "Salesforce Sans",
        "Garamond",
        "Sans-Serif",
        "Serif",
        "Times New Roman",
        "Helvetica",
        "Arial",
    ]
}

const ORDERS_STATUS = {
    SUCCESS: 'SUCCESS'
}

export {
    NOTIFICATION_TYPE,
    LOCAL_STORAGE_KEY_TYPE,
    THEME_MODE_TYPE,
    LANGUAGES,
    MENUS_SIDEBAR,
    SUB_MENU_KEY,
    SUB_MENUS_SIDEBAR,
    DEVICE_RESPONSIVE,
    COOKIE_KEY,
    CUSTOMER_TYPE,
    CUSTOMER_TYPE_SELECT,
    USER_STATUS_TYPE,
    GENDERS,
    ACTION,
    DATE_FORMAT,
    ROLES,
    SHIPPING_FEE_PAYMENT,
    EXPORT_TYPE,
    size,
    USER_PROFILE_MENU,
    CATEGORY_TYPE_FILE,
    SETTINGS_KEY,
    DRIVER_IMAGE_URL,
    editorSettings,
    ORDERS_STATUS,
    SETTINGS_KEY_FORMAT,
    STATUS_OPTIONS
}
