import styled from 'styled-components';

export const DateContainer = styled.div`
    position: relative;
    .flatpickr-input {
        border: 1px solid #c3c3c3;
        height: 35px;
        line-height: 35px;
        width: 100%;
        padding: 8px;
        border-radius: 3px;
        font-size: 1rem;
        background: ${props => props.disabled ? '#e9ecef' : '#ffffff'};
        border: ${(props) => props.isError ? '1px solid #dc3545' : '1px solid #ced4da'};

        &::placeholder {
            color: #DFE0E8;
            font-size: 14px;
        }
    }
`;

export const DateLabel = styled.div`
    margin-bottom: 10px;
    font-weight: 500;
    span {
        color: red;
        margin-left: 2px;
    }
`;

export const Image = styled.img`
    position: absolute;
    right: 10px;
    top: 32px;
`;