import styled, { keyframes } from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 35px;
    position: fixed;
    width: 100%;
    background: white;
    z-index: 99;
    border-bottom: 1px solid #cacbd7;
    @media ${DEVICE_RESPONSIVE.mobile} {
        display: none;
    }
`;

export const LeftContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${DEVICE_RESPONSIVE.desktop} {
        justify-content: space-between;
    }
`;

export const Logo = styled.img`
    height: 75px;
    cursor: pointer;
    @media ${DEVICE_RESPONSIVE.desktop} {
        width: 30%;
    }
`;

const rotateZ = keyframes`
    0% {
        opacity: 0;
        transform: scale(0);
    }

    80% {
        transform: scale(1.07);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

export const MenuItem = styled.div`
    padding: 20px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    font-size: 18px;
    perspective: 1000px;
    z-index: 100;
    
    &:hover {
        cursor: pointer;
    }

    @media ${DEVICE_RESPONSIVE.tablet} {
        padding: 20px 10px;
    }

    &:hover .dropdown_menu li {
        display: block;
        opacity: 1;
    }

    &:hover .dropdown_menu {
        display: block;
    }

    .dropdown_menu {
        margin-top: -12px;
        border-radius: 10px;

        display: none;
        li {
            display: block;
            opacity: 1;
        }
    }

    .dropdown_menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        perspective: 1000px;
        z-index: -1;
        padding-left: 0px;
        li {
            display: none;
            color: ${props => props.color ? props.color : 'white'};
            background-color: ${props => props.bg ? props.bg : Colors.primary};
            padding: 10px 20px;
            font-size: 16px;
            opacity: 0;

            &:hover {
            }
        }
        .dropdown_item-1 {
            transform-origin: top center;
            animation: ${rotateZ} 300ms ease-in-out;
        }
        .dropdown_item-2 {
            transform-origin: top center;
            animation: ${rotateZ} 400ms ease-in-out;
        }
        .dropdown_item-3 {
            transform-origin: top center;
            animation: ${rotateZ} 500ms ease-in-out;
        }
    }

    span {
        color: ${Colors.dark};
        font-weight: 700;
        font-size: 16px;
    }

    #icon {
        color: ${Colors.dark};
    }
`;

export const MenuWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 96px;
    justify-content: center;
    @media ${DEVICE_RESPONSIVE.desktop} {
        margin-left: 0px;
    }
`;

export const AuthButtonWrapper = styled.div`
    width: 233px;
    height: 43px;
    background: ${Colors.primary};
    border: 2px solid ${Colors.primary};
    box-shadow: 0px 100px 80px rgba(49, 49, 49, 0.1),
                0px 64.8148px 46.8519px rgba(49, 49, 49, 0.0759259), 
                0px 38.5185px 25.4815px rgba(49, 49, 49, 0.0607407), 
                0px 20px 13px rgba(49, 49, 49, 0.05), 
                0px 8.14815px 6.51852px rgba(49, 49, 49, 0.0392593), 
                0px 1.85185px 3.14815px rgba(49, 49, 49, 0.0240741);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 16px;
    @media ${DEVICE_RESPONSIVE.tablet} {
        flex-direction: column;
        width: 120px;
        height: 50px;
    }
    
`;

export const LoginButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    cursor: pointer;

    img {
        margin-left: 4px;
    }
    @media ${DEVICE_RESPONSIVE.tablet} {
        img {
            display: none
        }
        margin-top: 3px;
    }
`;

export const SignupButton = styled.div`
    margin-right: 8px;
    cursor: pointer;
`;

export const Line = styled.div`
    @media ${DEVICE_RESPONSIVE.tablet} {
        display: none;
    }
`;