import styled from 'styled-components';

export const SelectContainer = styled.div`
    
`;

export const SelectLabel = styled.div`
    margin-bottom: 10px;
    font-weight: 500;
    color: #6F7399;
    span {
        color: red;
        margin-left: 2px;
    }
`;

export const MessageError = styled.div`
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
`;