import styled from 'styled-components';

export const InputContainer = styled.div`
    position: relative;
    .form-group {
        input {
            height: ${props => props.height ? props.height : '50px'};
            &::placeholder {
                color: #DFE0E8;
                font-size: 15px;
            }
        }
    }
`;

export const EyeIcon = styled.img`
    position: absolute;
    right: 30px;
    top: ${props => props.height ? '30px' : '38px'};
    width: ${props => props.height ? '25px' : 'auto'};
    height: ${props => props.height ? '25px' : 'auto'};
    cursor: pointer;
`;