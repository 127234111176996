import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ACTION_TYPE } from '~/constants/actionTypes';
import httpRequest from '~/services/api';
import { handleGetApiPath } from '~/utils/api';
import { API } from '~/services/app.routes';
import { loginSuccess, signupSuccess, forgotPasswordSuccess, resetPasswordSuccess, changePasswordSuccess } from '~/modules/auth/actions';
import i18n from '~/utils/i18n';
import * as notification from '~/utils/notifications';
import { NOTIFICATION_TYPE } from '~/constants/common';

function* login(bodyData) {
    const { user } = bodyData;
    const path = handleGetApiPath(API.LOGIN);
    const result = yield call(httpRequest.post, path, user);
    const { data, error, status } = result;
    const dataRes = data || {...error, status};
    yield put(loginSuccess(dataRes));
}

function* singup(bodyData) {
    const { singupData } = bodyData;
    const path = handleGetApiPath(API.SIGN_UP);
    const result = yield call(httpRequest.post, path, singupData);
    const { data, error, status } = result;
    const dataRes = data || error;
    yield put(signupSuccess({...dataRes, status}));
}

function* forgotPassword(bodyData) {
    const { userData } = bodyData;
    const path = handleGetApiPath(API.FORGOT_PASSWORD);
    const result = yield call(httpRequest.post, path, userData);
    const { data, error, status } = result;
    const dataRes = data || error;
    yield put(forgotPasswordSuccess({...dataRes, status}));
}

function* resetPassword(bodyData) {
    const { userData } = bodyData;
    const path = handleGetApiPath(API.RESET_PASSWORD);
    const result = yield call(httpRequest.post, path, userData);
    const { data, error, status } = result;
    const dataRes = data || error;
    yield put(resetPasswordSuccess({...dataRes, status}));
}

function* changePassword({ body }) {
    const path = handleGetApiPath(API.CHANGE_PASSWORD);
    const result = yield call(httpRequest.post, path, body);
    const { status, error, data } = result;
    if (status === 200) {
        yield put(changePasswordSuccess(result));
        notification.custom(i18n.t('common.success'), i18n.t('auth.change_password_success'), NOTIFICATION_TYPE.SUCCESS);
    } else {
        const { message } = error || {};
        notification.custom(i18n.t('common.fail'), i18n.t(`auth.${message}`), NOTIFICATION_TYPE.ERROR);
    }
}

export default all([
    takeLatest(ACTION_TYPE.AUTH.LOGIN, login),
    takeLatest(ACTION_TYPE.AUTH.SIGN_UP, singup),
    takeLatest(ACTION_TYPE.AUTH.FORGOT_PASSWORD, forgotPassword),
    takeLatest(ACTION_TYPE.AUTH.RESET_PASSWORD, resetPassword),
    takeLatest(ACTION_TYPE.AUTH.CHANGE_PASSWORD, changePassword),
]);
