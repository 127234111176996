import { ACTION_TYPE } from '~/constants/actionTypes';

const getProvinces = () => {
    return {
        type: ACTION_TYPE.ADDRESS.GET_PROVINCES,
    };
}

const getProvincesSuccess = (provinces) => {
    return {
        type: ACTION_TYPE.ADDRESS.GET_PROVINCES_SUCCESS,
        provinces
    };
}

const getDistricts = (params) => {
    return {
        type: ACTION_TYPE.ADDRESS.GET_DISTRICTS,
        params
    };
}

const getDistrictsSuccess = (districts) => {
    return {
        type: ACTION_TYPE.ADDRESS.GET_DISTRICTS_SUCCESS,
        districts
    };
}

const getWards = (params) => {
    return {
        type: ACTION_TYPE.ADDRESS.GET_WARDS,
        params
    };
}

const getWardsSuccess = (wards) => {
    return {
        type: ACTION_TYPE.ADDRESS.GET_WARDS_SUCCESS,
        wards
    };
}

const resetDistricts = () => {
    return {
        type: ACTION_TYPE.ADDRESS.RESET_DISTRICTS,
    };
}

const resetWards = () => {
    return {
        type: ACTION_TYPE.ADDRESS.RESET_WARDS,
    };
}

export {
    getProvinces,
    getProvincesSuccess,
    getDistricts,
    getDistrictsSuccess,
    getWards,
    getWardsSuccess,
    resetDistricts,
    resetWards
}