import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const DialogContainer = styled.div`
`;

export const Icon = styled.img`
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    top: -55px;
`;

export const DialogTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: '#7C8691';
    text-align: center;
`;

export const Message = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.dark};
    text-align: center;
    margin-top: 10px;
`;

export const DialogContent = styled.div`
    position: relative;
    .modal-footer {
        justify-content: space-between;
        background: #F1F5F8;
        border-radius: 0px 0px 10px 10px;

        button {
            width: 47%;
            border-color: transparent;
            height: 38px;
        }

        .leftButton {
            background: #D0DEEB;
            color: #616c78;
            font-weight: 800;
            font-size: 14px;
        }

        .rightButton {
            background: ${Colors.primary};
            color: #FFFFFF;
            font-weight: 800;
            font-size: 14px;
        }

        .rightButtonError {
            background: #FB4B4B;
            color: #FFFFFF;
            font-weight: 800;
            font-size: 14px;
        }
    }
    .modal-body {
        margin-top: 50px;
    }
`;