import React, { useState, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormTitle, ParcelInfoContainer, OrdersSizeContainer } from '~/components/Orders/OrdersInfoForm/styles';
import { RowContainer } from '~/components/Orders/PickupInfoForm/styles';
import { ShippingFeeContainer, ShippingFee } from './styles';
import RemakeAvField from '~/components/RemakeAvField';
import RemakeAvRadioInput from '~/components/RemakeAvRadioInput';
import { VNDCurrencyFormatting } from '~/utils/helper';

function ParcelInfo(props) {

    const { t } = useTranslation();
    const { ordersInfoForm, shippingFeeData, isEdit, isCreateOrdersWithoutAuth } = props;
    const {
        weight,
        width,
        long,
        height,
        ordersName,
        ordersQuantity,
        shippingFee = 0
    } = ordersInfoForm || {};
    const [isAuto, setIsAuto] = useState(true);
    const [shippingFeeState, setShippingFeeState] = useState(0);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    useEffect(() => {
        const { fee = 0 } = shippingFeeData;
        setShippingFeeState(fee);
    }, [shippingFeeData]);

    useEffect(() => {
        if (isMobile) return;
        const { shippingFee = 0 } = ordersInfoForm || {};
        setShippingFeeState(shippingFee);
    }, [ordersInfoForm])

    const isCODOptions = [
        {
            label: t('orders.input_shipping_fee_auto'),
            value: true
        },
        {
            label: t('orders.input_shipping_fee'),
            value: false
        }
    ]

    const onChangeShippingFeeType = (event) => {
        const value = event?.target?.value;
        if (value !== undefined) {
            setIsAuto(JSON.parse(value));
        }
        const data = {
                target: {
                    value: shippingFeeState
                }
        }
        props.onChangeShippingFee(data);
    };

    const onBlurShippingFee = (event) => {
        const value = event?.target?.value;
        props.onChangeShippingFee(event);
        setShippingFeeState(value)
    }

    return (
        <ParcelInfoContainer>
            <FormTitle>{t('orders.infor')}</FormTitle>
            <RowContainer columns={'0.97fr 2fr'}>
                <RemakeAvField
                    height={'35px'}
                    name="weight"
                    label={t('orders.weight')}
                    placeholder={t('orders.required.weight')}
                    type="number"
                    value={weight}
                    disabled={isEdit}
                    validate={{
                        required: { value: true, errorMessage: t('orders.required.weight') },
                    }}
                    onBlur={props?.onBlurWeight}
                />
                <OrdersSizeContainer>
                    <RemakeAvField
                        height={'35px'}
                        name="long"
                        label={t('orders.size')}
                        placeholder={t('orders.required.long')}
                        type="number"
                        value={long}
                        disabled={isEdit}
                    />
                    <RemakeAvField
                        height={'35px'}
                        name="width"
                        label={' '}
                        placeholder={t('orders.required.width')}
                        type="number"
                        value={width}
                        disabled={isEdit}
                    />
                    <RemakeAvField
                        height={'35px'}
                        name="height"
                        label={' '}
                        placeholder={t('orders.required.height')}
                        type="number"
                        value={height}
                        disabled={isEdit}
                    />
                </OrdersSizeContainer>
            </RowContainer>
            <RowContainer columns={'0.97fr 2fr'}>
                <RemakeAvField
                    height={'35px'}
                    name="ordersName"
                    label={t('orders.name')}
                    placeholder={t('orders.required.name')}
                    type="text"
                    value={ordersName}
                    disabled={isEdit}
                    validate={{
                        required: { value: true, errorMessage: t('orders.required.name') },
                    }}
                />
                <OrdersSizeContainer columns={'0.97fr 2fr'}>
                    <RemakeAvField
                        height={'35px'}
                        name="ordersQuantity"
                        label={t('orders.quantity')}
                        placeholder={t('orders.required.quantity')}
                        type="number"
                        value={ordersQuantity}
                        disabled={isEdit}
                        validate={{
                            required: { value: true, errorMessage: t('orders.required.quantity') },
                        }}
                    />
                    <ShippingFeeContainer>
                        {(!isCreateOrdersWithoutAuth && !isEdit) && (
                            <RemakeAvRadioInput
                                name='shippingFeeType'
                                height={'16px'}
                                options={isCODOptions}
                                defaultValue={true}
                                onChange={onChangeShippingFeeType}
                                disabled={isEdit}
                            />
                        )}
                        {(isAuto || isCreateOrdersWithoutAuth) ? (
                            <ShippingFee>
                                {`${t('orders.shipping_fee')}: `}<b>{VNDCurrencyFormatting(shippingFeeState ? shippingFeeState : 0)}</b>
                                <RemakeAvField
                                    height={'35px'}
                                    name={'shippingFee'}
                                    value={shippingFeeState}
                                    style={{
                                        opacity: 0,
                                        height: 0,
                                        position: "absolute"
                                    }}
                                />
                            </ShippingFee>
                        ) : (
                            <RemakeAvField
                                height={'35px'}
                                name="shippingFeeInput"
                                placeholder={t('orders.required.shipping_fee')}
                                type="number"
                                value={shippingFeeState}
                                disabled={isEdit}
                                isCurrency
                                validate={{
                                    required: { value: true, errorMessage: t('orders.required.shipping_fee') },
                                }}
                                onBlur={onBlurShippingFee}
                            />
                        )}
                    </ShippingFeeContainer>
                </OrdersSizeContainer>
            </RowContainer>
        </ParcelInfoContainer>
    )
}

export default memo(ParcelInfo)
