import React, { useEffect, memo, useState } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// components
import PickupInfoForm from '../PickupInfoForm';
import OrdersInfoForm from '../OrdersInfoForm';
import RemakeButton from '~/components/RemakeButton';
// styles
import { ButtonsContainer, PageTitle } from '~/components/ProfileInfo/styles';
// utils
import { history } from '~/utils/navigations';
import { isEmpty } from '~/utils/helper';
// actions
import { createOrders, getOrdersById, clearOrdersById, updateOrders, createOrdersWithOther, getShippingFee, clearCreateOrdersEvent } from '~/modules/orders/actions';
import { SHIPPING_FEE_PAYMENT } from '~/constants/common';
import HeadingTitle from '~/components/HeadingTitle';
import StatusComponent from '~/components/Status';
import OrdersStatus from '../OrdersStatus';
import { resetDistricts, resetWards } from '~/modules/address/actions';
import RemakeButtonNotSubmit from '~/components/RemakeButtonNotSubmit';
import OrdersPrint from '../Print';

function AddOrders(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { ordersData = {}, shippingFeeData = {}, createOrdersEventData } = useSelector(state => state.orders);
    const { match, isCreateOrdersWithoutAuth = false } = props;
    const { params: { id } = {} } = match || {};
    const isEdit = id ? true : false;
    const { statusInfo, code = '' } = ordersData;
    const { name = '', color, id: ordersStatusId } = statusInfo || {};

    const [ordersFormData, setOrdersFormData] = useState({});
    var [pickupProvinceSelected, setPickupProvinceSelected] = useState({});
    var [pickupDistrictSelected, setPickupDistrictSelected] = useState({});
    var [recipientProvinceSelected, setRecipientProvinceSelected] = useState({});
    var [recipientDistrictSelected, setRecipientDistrictSelected] = useState({});
    var [weightSelected, setWeightSelected] = useState(0);
    const [isSubmited, setIsSubmited] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (id) {
            const params = {
                ordersId: id
            }
            dispatch(getOrdersById(params))
        }
        return function cleanup() {
            dispatch(clearOrdersById());
            dispatch(resetDistricts());
            dispatch(resetWards());
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(createOrdersEventData) && id) {
            const params = {
                ordersId: id
            }
            dispatch(getOrdersById(params));
            dispatch(clearCreateOrdersEvent());
        }
    }, [createOrdersEventData]);

    useEffect(() => {
        const {
            pickupName = '',
            pickupPhone = '',
            pickupPostalCode = '',
            pickupProvinceInfo = '',
            pickupDistrictInfo = '',
            pickupWardInfo = '',
            pickupAddress = '',
            pickupDate = '',
            recipientName = '',
            recipientPhone = '',
            recipientPostalCode = '',
            recipientProvinceInfo = '',
            recipientDistrictInfo = '',
            recipientWardInfo = '',
            recipientAddress = '',
            weight = '',
            width = '',
            long = '',
            height = '',
            ordersName = '',
            ordersQuantity = '',
            shippingFeePayment = SHIPPING_FEE_PAYMENT.SENDER_PAY,
            isCOD = true,
            isGuaranteed = true,
            shippingFee = 0,
            totalValue = 0,
            recipientAmountPayment = '',
            shipperInfo = ''
        } = ordersData;
        setOrdersFormData(
            {
                pickupName,
                pickupPhone,
                pickupPostalCode,
                pickupProvince: addressItemFormating(pickupProvinceInfo),
                pickupDistrict: addressItemFormating(pickupDistrictInfo),
                pickupWard: addressItemFormating(pickupWardInfo),
                pickupAddress,
                pickupDate,
                recipientName,
                recipientPhone,
                recipientPostalCode,
                recipientProvince: addressItemFormating(recipientProvinceInfo),
                recipientDistrict: addressItemFormating(recipientDistrictInfo),
                recipientWard: addressItemFormating(recipientWardInfo),
                shipper: userItemFormating(shipperInfo),
                recipientAddress,
                weight,
                width,
                long,
                height,
                ordersName,
                ordersQuantity,
                shippingFeePayment,
                isCOD,
                isGuaranteed,
                shippingFee,
                totalValue,
                recipientAmountPayment,
                code
            }
        )
    }, [ordersData]);

    useEffect(() => {
        handleCallApiGetShippingFee();
    }, [
        pickupDistrictSelected,
        recipientDistrictSelected,
        weightSelected
    ]);

    const addressItemFormating = (addressItem) => {
        if (!addressItem) return undefined;
        return {
            label: addressItem?.name,
            value: addressItem?.id
        }
    }

    const userItemFormating = (userItem) => {
        if (!userItem) return undefined;
        return {
            label: userItem?.fullName,
            value: userItem?.id
        }
    }

    const handleSubmitForm = (event, errors, values) => {
        setIsSubmited(true);
        const { shippingFeeInput, shippingFee } = values;
        if (isEmpty(errors)) {
            if (isEdit) {
                const payloadBody = {
                    params: {
                        ordersId: id
                    },
                    body: {
                        ...values,
                        shippingFee: shippingFee || shippingFeeInput
                    }
                }
                dispatch(updateOrders(payloadBody));
            } else {
                const payloadBody = {
                    ...values,
                    shippingFee: shippingFee || shippingFeeInput,
                    isPartner: !isCreateOrdersWithoutAuth
                }
                if (isCreateOrdersWithoutAuth) {
                    dispatch(createOrdersWithOther(payloadBody));
                } else {
                    dispatch(createOrders(payloadBody));
                }
            }
        }
    }

    const handleClickButton = () => {
        if (isCreateOrdersWithoutAuth) {
            props.onCancel();
        } else {
            history.goBack();
        }
    }

    const handleCallApiGetShippingFee = () => {
        if (
            !isEmpty(pickupProvinceSelected) &&
            !isEmpty(pickupDistrictSelected) &&
            !isEmpty(recipientProvinceSelected) &&
            !isEmpty(recipientDistrictSelected)
        ) {
            const { label: pickupProvinceName } = pickupProvinceSelected;
            const { label: pickupDistrictName } = pickupDistrictSelected;
            const { label: recipientProvinceName } = recipientProvinceSelected;
            const { label: recipientDistrictName } = recipientDistrictSelected;
            const params = {
                pick_province: pickupProvinceName,
                pick_district: pickupDistrictName,
                province: recipientProvinceName,
                district: recipientDistrictName,
                weight: weightSelected,
                transport: 'road'
            }
            dispatch(getShippingFee(params));
        }
    }

    const onChangePickupProvince = (province) => {
        setPickupProvinceSelected(province);
    }

    const onChangePickupDistrict = (district) => {
        setPickupDistrictSelected(district)
    }

    const onChangeRecipientProvince = (province) => {
        setRecipientProvinceSelected(province);
    }

    const onChangeRecipientDistrict = (district) => {
        setRecipientDistrictSelected(district)
    }

    const onBlurWeight = (event) => {
        const weight = event?.target?.value;
        const weightParseToGam = (parseFloat(weight) * 1000);
        setWeightSelected(weightParseToGam);
    }

    return (
        <div>
            <HeadingTitle
                type="large"
                isBlock={true}
                label={isEdit ? t('orders.edit') : t('orders.create')}
                status={<StatusComponent marginLeft={'10px'} name={name} background={color} />}
            >
                {
                    isEdit && <OrdersStatus
                        ordersId={id}
                        ordersStatusId={ordersStatusId}
                    />
                }
            </HeadingTitle>
            {(isEdit && code) && <PageTitle>{`${t('orders.orders_code')}: ${code}`}</PageTitle>}
            <AvForm onSubmit={handleSubmitForm}>
                <PickupInfoForm
                    pickupInfoFormData={ordersFormData}
                    isCreateOrdersWithoutAuth={isCreateOrdersWithoutAuth}
                    onChangePickupProvince={onChangePickupProvince}
                    onChangePickupDistrict={onChangePickupDistrict}
                    isSubmited={isSubmited}
                    isEdit={isEdit}
                />
                <OrdersInfoForm
                    ordersInfoForm={ordersFormData}
                    isEdit={isEdit}
                    shippingFeeData={shippingFeeData}
                    onChangeRecipientProvince={onChangeRecipientProvince}
                    onChangeRecipientDistrict={onChangeRecipientDistrict}
                    onBlurWeight={onBlurWeight}
                    isSubmited={isSubmited}
                    isCreateOrdersWithoutAuth={isCreateOrdersWithoutAuth}
                />
                <ButtonsContainer>
                    <RemakeButton
                        label={isCreateOrdersWithoutAuth ? t('common.cancel') : t('common.go_back')}
                        type='button'
                        buttonStyles={{ marginRight: 10, background: '#C4C4C4' }}
                        onClick={handleClickButton}
                    />
                    {isEdit && (
                        <RemakeButtonNotSubmit
                            label={t('common.print')}
                            type='primary'
                            onClick={() => setIsOpen(true)}
                            buttonStyles={{ marginRight: 10 }}
                        />
                    )}
                    <RemakeButton
                        label={isEdit ? t('common.save') : t('common.create_orders')}
                        type='primary'
                    />
                </ButtonsContainer>
            </AvForm>
            <OrdersPrint
                isOpenDialog={isOpen}
                ordersData={ordersFormData}
                handleCloseDialog={() => setIsOpen(false)}
            />
        </div>
    )
}

export default memo(AddOrders);
