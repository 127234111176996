import React, { useEffect } from 'react';
import {
    Container,
    SidebarBodyWrapper,
    MenuOutlineWrapper,
    MaskMenu
} from '~/components/Dashboard/styles';
import { useDispatch } from 'react-redux';
import Sidebar from '~/components/Sidebar';
import Header from '~/components/Header';
import Body from '~/components/Body';
import { handleGetCookies } from '~/utils/cookies';
import { COOKIE_KEY, ROLES } from '~/constants/common';
import { getSettings } from '~/modules/settings/actions';
import { MenuOutline } from '@styled-icons/evaicons-outline/MenuOutline';
import { Colors } from '~/constants/styles';

export default function Dashboard(props) {

    const userInfo = handleGetCookies(COOKIE_KEY.LOGIN);
    const { UserRole } = userInfo || {};
    const { RoleType } = UserRole || {};
    const { name: userRole } = RoleType || {};
    const isEmployee = userRole === ROLES.EMPLOYEE;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSettings());
    }, []);

    const handleOpenMenu = () => {
        document.getElementById('sider-menu').style.display = 'block';
        document.getElementById('mask-menu').style.display = 'block';
    }

    const handleCloseMenu = () => {
        document.getElementById('sider-menu').style.display = 'none';
        document.getElementById('mask-menu').style.display = 'none';
    }

    return (
        <Container>
            <Header />
            <SidebarBodyWrapper>
                {!isEmployee && <Sidebar />}
                <MaskMenu id='mask-menu' onClick={handleCloseMenu}/>
                <MenuOutlineWrapper onClick={handleOpenMenu}>
                    <MenuOutline color={Colors.dark} width={40} />
                </MenuOutlineWrapper>
                <Body width={isEmployee ? '100%' : '90%'}>{props.children}</Body>
            </SidebarBodyWrapper>
        </Container>
    )
}
