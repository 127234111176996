import React from 'react';
import { Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';
// styles
import { DialogContent } from './styles';
import closeIcon from '~/assets/images/close_ic.svg';

function DialogComponent(props) {

    const {
        isOpen,
        width
    } = props;

    return (
            <Modal style={width && { width }} isOpen={isOpen} toggle={props?.toggle} className='dialogComponent'>
                <DialogContent>
                    <img src={closeIcon} alt='' onClick={props?.toggle} />
                    {props?.children}
                </DialogContent>
            </Modal>
    )
}

export default DialogComponent
