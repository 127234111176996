import { CUSTOMER_TYPE, USER_STATUS_TYPE } from "~/constants/common";
// this export three methods, so we destruct and rename it all at once
export const { format: formatPrice } = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'USD',
});

export const kFormatter = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
}

export const customerFormat = (type) => {
    if (type === CUSTOMER_TYPE.OTHER) {
        return 'common.customer_type.other';
    } else {
        return 'common.customer_type.partner';
    }
}

export const statusFormat = (type) => {
    switch (type) {
        case USER_STATUS_TYPE.ACTIVE:
            return 'common.account_status.active';

        case USER_STATUS_TYPE.WAITING_VERIFY:
            return 'common.account_status.waiting_verify';

        default:
            return 'common.account_status.inactive';
    }
}

export const addressFormat = (province, district, ward, address) => {
    return `${address}, ${ward}, ${district}, ${province}`;
}
