import React from 'react';
import Logo from '~/assets/images/logo/logo.svg';
import { useSelector } from 'react-redux';
import {
    Container,
    LogoWrapper,
    HeaderContentWrapper,
} from '~/components/Header/styles';
import HeaderMenu from '~/components/HeaderMenu';
import UserProfile from '~/components/UserProfile';
import { ROUTE } from '~/constants/routes';
import { redirectTo } from '~/utils/navigations';
import { DRIVER_IMAGE_URL, SETTINGS_KEY } from '~/constants/common';

export default function Header() {

    const {
        settings: { settings = {} },
    } = useSelector(state => {
        return state;
    });


    const renderLogo = () => {
        const logoSetting = settings.find(setting => setting.key === SETTINGS_KEY.BRAND_IMAGE);
        if (logoSetting?.value) {
            return `${DRIVER_IMAGE_URL}${logoSetting.value}`
        } else {
            return '';
        }
    }

    return (
        <Container>
            <LogoWrapper onClick={() => { redirectTo(ROUTE.HOME) }}>
                {renderLogo() && <img alt="" src={renderLogo()} />}
            </LogoWrapper>
            <HeaderContentWrapper>
                <HeaderMenu />
                <UserProfile />
            </HeaderContentWrapper>
        </Container>
    )
}
