import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
//  components
import RemakeSelect from '~/components/RemakeSelect';
// actions
import { handleGetUsers } from '~/modules/user/actions';
// styles
import { FormTitle } from '../OrdersInfoForm/styles';
import { ShipInfoContainer, SelectInputContainer } from './styles';
// constant
import { ROLES } from '~/constants/common';
// utils
import { formatUsersData, isEmpty } from '~/utils/helper';

function ShipInfo(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { users = [] } = useSelector(state => {
        return state.user;
    });
    const{ ordersInfoForm } = props;

    const [shipperSelect, setShipperSelect] = useState(undefined);

    useEffect(() => {
        dispatch(handleGetUsers({ roleType: ROLES.EMPLOYEE }));
    }, []);

    useEffect(() => {
        if (!isEmpty(ordersInfoForm)) {
            const { shipper } = ordersInfoForm;
            setShipperSelect(shipper);
        }
    }, [ordersInfoForm]);

    const onChangeShipper = (value) => {
        setShipperSelect(value);
    }

    return (
        <ShipInfoContainer>
            <FormTitle>{t('orders.shipper_info')}</FormTitle>
            <SelectInputContainer>
                <RemakeSelect
                    name='shipperId'
                    label={t('orders.shipper')}
                    placeholder={t('orders.required.shipper')}
                    options={formatUsersData(users)}
                    value={shipperSelect}
                    onChange={onChangeShipper}
                />
            </SelectInputContainer>
        </ShipInfoContainer>
    )
}

export default memo(ShipInfo);
