import { combineReducers } from 'redux';

import devTo from '~/modules/devto/reducers';
import github from '~/modules/github/reducers';
import loading from '~/modules/loading/reducers';
import themeMode from '~/modules/theme/reducers';
import auth from '~/modules/auth/reducers';
import user from '~/modules/user/reducers';
import settings from '~/modules/settings/reducers';
import address from '~/modules/address/reducers';
import exports from '~/modules/export/reducers';
import orders from '~/modules/orders/reducers';
import uploadFiles from '~/modules/uploadFile/reducers';
import reports from '~/modules/report/reducers';

export default combineReducers({
    devTo,
    github,
    loading,
    themeMode,
    auth,
    user,
    settings,
    address,
    exports,
    orders,
    uploadFiles,
    reports
});
