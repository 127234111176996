import produce from 'immer';
import { ACTION_TYPE } from '~/constants/actionTypes';

const INITIAL_STATE = {
    loginData: {},
    signupData: {},
    fpData: {},
    rpData: {},
    cpData: null,
};

const auth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPE.AUTH.LOGIN_SUCCESS:
            return produce(state, draft => {
                const { userData = {} } = action;
                draft.loginData = userData;
            });
        case ACTION_TYPE.AUTH.SIGN_UP_SUCCESS:
            return produce(state, draft => {
                const { signupData = {} } = action;
                draft.signupData = signupData;
            });
        case ACTION_TYPE.AUTH.LOGOUT:
            return produce(state, draft => {
                draft.loginData = {};
        });
        case ACTION_TYPE.AUTH.FORGOT_PASSWORD_SUCCESS:
            return produce(state, draft => {
                const { fpData } = action;
                draft.fpData = fpData;
        });

        case ACTION_TYPE.AUTH.RESET_PASSWORD_SUCCESS:
            return produce(state, draft => {
                const { rpData } = action;
                draft.rpData = rpData;
        });

        case ACTION_TYPE.AUTH.CLEAR_SIGN_UP_DATA:
            return produce(state, draft => {
                draft.signupData = {};
        });

        case ACTION_TYPE.AUTH.CHANGE_PASSWORD_SUCCESS:
            return produce(state, draft => {
                const { cpData } = action;
                draft.cpData = cpData;
        });

        default:
            return state;
    }
}

export default auth;
