import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const OrdersInfoContainer = styled.div`
    display: ${(props) => props.isShow ? 'block' : 'none'};
`;

export const ShipmentInfoContainer = styled.div`
    margin-top: 16px;
    display: ${(props) => props.isShow ? 'block' : 'none'};
`;

export const FormTitle = styled.div`
    font-size: 15;
    font-weight: 600;
    color: ${Colors.dark};
`;

export const ParcelInfoContainer = styled.div`
    margin-top: 16px;
`;

export const OrdersSizeContainer = styled.div`
    grid-column: 2/3;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: ${(props) => props.columns ? props.columns : 'repeat(3, 1fr)'};

    @media ${DEVICE_RESPONSIVE.mobile} {
        grid-template-columns: 1fr;
        grid-column: 1;
        grid-gap: 10px;
    }
`;

export const ServiceInfo = styled.div`
    margin-top: 16px;
`;

export const ParcelFormContainer = styled.div`
    display: ${(props) => props.isShow ? 'block' : 'none'};
`;

export const ServiceFormContainer = styled.div`
    display: ${(props) => props.isShow ? 'block' : 'none'};
`;