
import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';

export const SlideImg = styled.img`
    height: 587px;
    width: 100%;
    object-fit: cover;

    @media ${DEVICE_RESPONSIVE.mobile} {
        height: 240px;
    }
`;

export const BannerContainer = styled.div`
    position: relative;
    padding-top: 95px;
    @media ${DEVICE_RESPONSIVE.mobile} {
        margin-top: 50px;
        padding-top: 0px;
    }

    .slick-slider {
        .slick-prev {
            left: 40px;
            z-index: 99;
        }
        .slick-next {
            right: 40px;
        }
    }
`;

export const SloganAndOrder = styled.div`
    position: absolute;
    top: 28%;
    left: 185px;
    max-width: 630px;
    @media ${DEVICE_RESPONSIVE.laptop} {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        max-width: 530px;
    }
    @media ${DEVICE_RESPONSIVE.mobile} {
        max-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const SloganText = styled.div`
    color: #ffffff;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 24px;
    line-height: 61px;

    @media ${DEVICE_RESPONSIVE.tablet} {
        font-size: 38px;
    }

    @media ${DEVICE_RESPONSIVE.mobile} {
        font-size: 17px;
        margin-bottom: 12px;
        line-height: 22px;
    }
`;

export const MessageText = styled.div`
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    max-width: 307px;
    margin-top: 11px;

    @media ${DEVICE_RESPONSIVE.tablet} {
        font-size: 18px;
    }

    @media ${DEVICE_RESPONSIVE.mobile} {
        display: none;
    }
`;

export const ButtonWrapper = styled.div`
    @media ${DEVICE_RESPONSIVE.mobile} {
        button {
            height: 55px !important;
            width: 180px !important;

            span {
                font-size: 14px !important;
            }
        }
    }
`;