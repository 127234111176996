import styled, { keyframes } from 'styled-components';
import { Colors } from '~/constants/styles';

const flyAnimation = keyframes`
    0% { transform: translateY(-30px); }
    50% { transform: translateY(0px); }
    100% { transform: translateY(-30px); }
`;

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    h1 {
        font-size: 100px;
    }

    img {
        margin-top: 10px;
        height: 250px;
        animation-name: ${flyAnimation};
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        margin-top: 30px;
    }
`;

export const Link404 = styled.a`
    font-size: 15px;
    color: #fff!important;
    padding: 10px 20px;
    background: ${Colors.primary};
    margin: 20px 0;
    display: inline-block;
    text-decoration: none;
`;
