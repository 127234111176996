import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const Container = styled.div`
    height: 93vh;
    width: 220px;
    padding: 0px 16px;
    min-width: 220px;
    transition: 0.5s;

    @media ${DEVICE_RESPONSIVE.mobile} {
        display: none;
        position: fixed;
        z-index: 9999999;
        background: white;
        box-shadow: blue;
        box-shadow: 0px 2px 2px grey;
        transition: 0.5s;
        top: 0;
        height: 100vh;
    }
`;

export const CreateOrdersButtonWrapper = styled.div`
    border: 2px solid ${Colors.dark};
    border-radius: 5px;
    height: 40px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 0px 20px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    span {
        font-size: 14px;
        color: ${Colors.dark};
        font-weight: bold;
        text-transform: uppercase;
    }

    &:hover {
        background-color: ${Colors.primary};
        border-width: 0px;
        transition: 0.3s;

        span {
            font-size: 15px;
            transition: 0.1s;
            color: #ffffff;
        }
    }
`;

export const MenuWrapper = styled.div`
    margin-top: 50px;
`;

export const CloseMenuWrapper = styled.div`
    margin-bottom: 10px;
    display: none;
    @media ${DEVICE_RESPONSIVE.mobile} {
        display: block;
    }
`;

export const MenuItem = styled.div`
    display: flex;
    align-content: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 8px 3px;

    div {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-left: 5px;

        span {
            font-size: 14px;
        }
    }

    &:hover {
        background-color: #eeeeee;
        transition: .4s;
        div {
            span {
                color: #444970;
                transition: .1s;
            }
        }
    }
`;
