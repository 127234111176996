import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const StaticPageContainer = styled.div`
    padding-top: 95px;
    min-height: calc(100vh - 107px);

    @media ${DEVICE_RESPONSIVE.mobile} {
        padding-top: 50px;
    }
`;

export const StaticPageTitle = styled.div`
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    color: ${Colors.dark};
`;

export const StaticPageContent = styled.div`
    display: flex;
    justify-content: center;
    padding: 0px 10px;
    width: 1075px;
    margin: 0 auto;

    @media ${DEVICE_RESPONSIVE.mobile} {
        width: 100%;
    }
`;

export const StaticPageEmptyContent = styled.div`
    margin-top: 15%;
`;