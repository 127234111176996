import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ACTION_TYPE } from '~/constants/actionTypes';
import httpRequest from '~/services/api';
import { handleGetApiPath } from '~/utils/api';
import { API } from '~/services/app.routes';
import { getProvincesSuccess, getDistrictsSuccess, getWardsSuccess } from '~/modules/address/actions';

function* getProvinces() {
    const path = handleGetApiPath(API.ADDRESS.GET_PROVINCES);
    const result = yield call(httpRequest.get, path);
    const { items, error, status } = result;
    const dataRes = items || {...error, status};
    yield put(getProvincesSuccess(dataRes));
}

function* getDistricts(bodyData) {
    const { params } = bodyData;
    const path = handleGetApiPath(API.ADDRESS.GET_DISTRICTS, params);
    const result = yield call(httpRequest.get, path);
    const { items, error, status } = result;
    const dataRes = items || {...error, status};
    yield put(getDistrictsSuccess(dataRes));
}

function* getWards(bodyData) {
    const { params } = bodyData;
    const path = handleGetApiPath(API.ADDRESS.GET_WARDS, params);
    const result = yield call(httpRequest.get, path);
    const { items, error, status } = result;
    const dataRes = items || {...error, status};
    yield put(getWardsSuccess(dataRes));
}

export default all([
    takeLatest(ACTION_TYPE.ADDRESS.GET_PROVINCES, getProvinces),
    takeLatest(ACTION_TYPE.ADDRESS.GET_DISTRICTS, getDistricts),
    takeLatest(ACTION_TYPE.ADDRESS.GET_WARDS, getWards)
]);
