import React, { useState } from 'react';
import Camera from 'react-html5-camera-photo';
import { useTranslation } from 'react-i18next';
import { TakePictureContainer, LeftContainer, RightContainer, ImagePreviewContainer, CameraContainer } from './styles';
import takePictireIcon from '~/assets/images/take_picture_ic.png';

function TakePictureComponent(props) {

    const { t } = useTranslation();

    const [isTakePicture, setIsTakePicture] = useState(false);
    const [imgUri, setImgUri] = useState(null);

    const handleTakePhoto = (dataUri) => {
        setImgUri(dataUri);
        props.handleTakePhoto(dataUri);
    }

    const handleOpenCamera = () => {
        setIsTakePicture(true);
    }

    const handleRemoveImg = () => {
        setImgUri(null);
    }

    const renderCamera = () => {
        return (
            imgUri ? (
                <ImagePreviewContainer>
                    <p>{t('orders.delivery_picture')}</p>
                    <img src={imgUri} />
                    <div onClick={handleRemoveImg}>{t('orders.remove_picture')}</div>
                </ImagePreviewContainer >
            ) : (
                <CameraContainer>
                    <Camera
                        onTakePhoto={handleTakePhoto}
                        sizeFactor={0.5}
                    />
                    <p onClick={() => setIsTakePicture(false)}>{t('orders.cancel_picture')}</p>
                </CameraContainer>
            )
        );
    }

    const renderContent = () => {
        return (
            <TakePictureContainer>
                <LeftContainer onClick={handleOpenCamera}>
                    <img src={takePictireIcon} alt='' />
                    <p>{t('orders.click_to_take_picture')}</p>
                </LeftContainer>
                <RightContainer>
                    <span>{t('orders.message_required_take_picture')}</span>
                </RightContainer>
            </TakePictureContainer>
        )
    }

    return (
        isTakePicture ? renderCamera() : renderContent()
    )
}

export default TakePictureComponent;
