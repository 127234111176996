import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';
// styles
import { Title, ContentContainer, ListItem, ContentItem, ItemText, ItemMessage } from './styles';
// icons
import content1 from '~/assets/images/home/content1.svg';
import content2 from '~/assets/images/home/content2.svg';
import content3 from '~/assets/images/home/content3.svg';
import content4 from '~/assets/images/home/content4.svg';

function ContentComponent() {

    const { t } = useTranslation();

    const contents = [
        {
            title: t('home.content.content1.title'),
            message: t('home.content.content1.message'),
            img: content1,
            animateName: 'animate__fadeInLeft',
            duration: 2
        },
        {
            title: t('home.content.content2.title'),
            message: t('home.content.content2.message'),
            img: content2,
            animateName: 'animate__fadeInRight',
            duration: 2
        },
        {
            title: t('home.content.content3.title'),
            message: t('home.content.content3.message'),
            img: content3,
            animateName: 'animate__fadeInUp',
            duration: 2
        },
        {
            title: t('home.content.content4.title'),
            message: t('home.content.content4.message'),
            img: content4,
            animateName: 'animate__fadeInUp',
            duration: 2
        }
    ];

    const renderContentList = () => {
        return contents.map(item => {
            return (
                <ScrollAnimation key={item.title} animateIn={item.animateName} duration={item.duration} animateOnce>
                    <ContentItem>
                        <img src={item.img} alt="" height="280" width="320" />
                        <ItemText>
                            {item.title}
                        </ItemText>
                        <ItemMessage>
                            {item.message}
                        </ItemMessage>
                    </ContentItem>
                </ScrollAnimation>
            )
        })
    }

    return (
        <ContentContainer>
            <ScrollAnimation animateIn={'animate__zoomIn'} duration={1} animateOnce>
                <Title>
                    {t('home.content_title')}
                </Title>
            </ScrollAnimation>
            <ListItem>
                {renderContentList()}
            </ListItem>
        </ContentContainer>
    )
}

export default ContentComponent
