const API = {
    GET_DEV_TO: {
        path: '/repositories'
    },
    GET_GITHUB_TRENDING: {
        path: '/repositories'
    },
    LOGIN: {
        path: '/auth'
    },
    SIGN_UP: {
        path: '/signup'
    },
    FORGOT_PASSWORD: {
        path: '/forgotPassword'
    },
    RESET_PASSWORD: {
        path: '/resetPassword'
    },
    CHANGE_PASSWORD: {
        path: '/changePassword'
    },
    USER: {
        USERS: {
            path: '/users'
        },
        DELETE_USER: {
            path: '/user/:userId'
        },
        CREATE: {
            path: '/user'
        },
        GET_BY_ID: {
            path: '/user/:userId'
        }
    },
    SETTINGS: {
        ORDERS_STATUSES: {
            path: '/ordersStatuses'
        },
        ORDERS_STATUS: {
            path: '/ordersStatus'
        },
        ORDERS_STATUS_ID: {
            path: '/ordersStatus/:ordersStatusId'
        },
        SORT_INDEX_ORDERS_STATUSES: {
            path: '/sortIndexOrdersStatuses'
        },
        GET_BY_KEY: {
            path: '/setting'
        },
        UPDATE_BY_KEY: {
            path: '/settings'
        }
    },
    ADDRESS: {
        GET_PROVINCES: {
            path: '/provinces'
        },
        GET_DISTRICTS: {
            path: '/districtsByProvince/:provinceId'
        },
        GET_WARDS: {
            path: '/wardsByDistrict/:districtId'
        }
    },
    EXPORT: {
        path: '/export'
    },
    ORDERS: {
        CREATE_BY_PARTNER: {
            path: '/orders/createByPartner'
        },
        CREATE_WITH_OTHER: {
            path: '/orders/createByOther'
        },
        GET_BY_ID: {
            path: '/orders/:ordersId'
        },
        GET_ORDERS: {
            path: '/orders'
        },
        UPDATE: {
            path: '/orders/:ordersId'
        },
        CREATE_EVENT: {
            path: '/orders/event'
        },
        GET_EVENT_BY_CODE: {
            path: '/orders/events/byCode'
        },
        GET_SHIPPING_FEE: {
            path: '/shippingFee'
        }
    },
    UPLOAD_FILES: {
        path: '/uploadFiles'
    },
    REPORTS: {
        EMPLOYEE: {
            path: '/reports/employee'
        },
        CUSTOMER: {
            path: '/reports/customer'
        },
        ORDERS: {
            path: '/reports/orders'
        },
        TOTAL: {
            path: '/reports/total'
        }
    }
};

export {
    API
}