import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';

export const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const MaskMenu = styled.div`
    width: 600px;
    height: 100vh;
    position: fixed;
    background-color: #1c1b1b61;
    margin-left: 220px;
    display: none;
    top: 0;
    z-index: 99999;
`;

export const SidebarBodyWrapper = styled.div`
    height: auto;
    display: flex;
    background-color: #ffffff;

    @media ${DEVICE_RESPONSIVE.mobile} {
        display: block;
    }
`;

export const MenuOutlineWrapper = styled.div`
    margin-left: 16px;
    display: none;
    @media ${DEVICE_RESPONSIVE.mobile} {
        display: block;
    }
`;