import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from '~/components/Dashboard';
import { AvForm } from 'availity-reactstrap-validation';
import Card from '~/components/Card';
import HeadingTitle from '~/components/HeadingTitle';
import { useTranslation } from 'react-i18next';
import Table from '~/components/Table';
import { handleDeleteUser, handleGetUsers } from '~/modules/user/actions';
import { customerFormat, statusFormat } from '~/utils/formats';
import DialogMessage from '~/components/DialogMessage';
import RemakeAvField from '~/components/RemakeAvField';
import RemakeButton from '~/components/RemakeButton';
import { Container, RowSearchContainer, SearchContainer, ButtonsContainer, HeadingWrapper } from './styles';
import { isEmpty } from '~/utils/helper';
import { CUSTOMER_TYPE_SELECT, EXPORT_TYPE, ROLES, STATUS_OPTIONS } from '~/constants/common';
import RemakeSelect from '~/components/RemakeSelect';
import Exports from '~/components/Exports';
import { useLocation } from "react-router-dom";
import { ROUTE } from '~/constants/routes';
import { redirectTo } from '~/utils/navigations';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CustomerListPage() {
    let query = useQuery();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const searchFormRef = useRef(null);
    const paginationParams = { page: 1, pageSize: 20 };
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [customerSelected, setCustomerSelected] = useState(null);
    const [customerTypeSelect, setCustomerTypeSelect] = useState(CUSTOMER_TYPE_SELECT?.[0]);
    const [statusSelected, setStatusSelected] = useState(STATUS_OPTIONS[0]);
    const [searchParams, setSearchParams] = useState({});

    const { users = [], total = 0 } = useSelector(state => {
        return state.user;
    });

    useEffect(() => {
        handleGetUsersByParams();
    }, [])

    const handleGetUsersByParams = () => {
        const customerTypeParams = query.get('customerType');
        const statusParams = query.get('status');
        let params = {
            page: paginationParams.page,
            pageSize: paginationParams.pageSize,
            roleType: ROLES.CUSTOMER,
        }
        if (!isEmpty(statusParams)) {
            const statusItem = getStatusById(statusParams);
            setStatusSelected(statusItem);
            params.status = statusItem?.value !== 'All' ? statusItem?.value : undefined;
        }
        if (!isEmpty(customerTypeParams)) {
            const customerItem = getCustomerById(customerTypeParams);
            setCustomerTypeSelect(customerItem);
            params.customerType = customerItem?.value !== 'ALL' ? (customerItem?.value || undefined) : undefined;
        }
        dispatch(handleGetUsers(params));
    }

    const getCustomerById = (value) => {
        return CUSTOMER_TYPE_SELECT.find(item => item?.value === value)
    }

    const getStatusById = (value) => {
        return STATUS_OPTIONS.find(item => item?.value === value)
    }

    const handleClickEdit = (item) => {
        const { id } = item;
        redirectTo(`${ROUTE.CUSTOMER.EDIT}/${id}`);
    }

    const handleClickDelete = (item) => {
        setCustomerSelected(item);
        setIsOpenDialog(true);
    }

    const handleClickPagination = (item) => {
        const { recordNumberOnPage = 0, paginationSelectedIndex = 0 } = item;
        dispatch(handleGetUsers({ page: paginationSelectedIndex, pageSize: recordNumberOnPage, roleType: ROLES.CUSTOMER }));
    }

    const handleOkeDialog = () => {
        const { id } = customerSelected;
        dispatch(handleDeleteUser({ userId: id, page: 1, pageSize: paginationParams.pageSize }));
        setIsOpenDialog(false);
    }

    const onChangeCustomerType = (item) => {
        setCustomerTypeSelect(item);
    }

    // Process data source
    const usersResults = users.map(user => {
        const { Customer = {}, status = '' } = user;
        const { CustomerType: { name } = {} } = Customer || {};
        return { ...user, customerType: t(customerFormat(name)), status: t(statusFormat(status)) };
    })

    const handleSearch = (event, errors, values) => {
        if (isEmpty(errors)) {
            const { code, fullName, phoneNumber, email } = values;
            // if (!code && !fullName && !phoneNumber && !email) return;
            const params = {
                page: 1,
                pageSize: paginationParams.pageSize,
                roleType: ROLES.CUSTOMER,
                code: code || undefined,
                fullName: fullName || undefined,
                phoneNumber: phoneNumber || undefined,
                email: email || undefined,
                customerType: customerTypeSelect.value !== 'ALL' ? (customerTypeSelect.value || undefined) : undefined,
            }
            setSearchParams(params);
            dispatch(handleGetUsers(params));
        }
    }

    const handleResetField = () => {
        searchFormRef.current.reset();
        setCustomerTypeSelect(CUSTOMER_TYPE_SELECT?.[0]);
    }

    const onChangeStatus = (item) => {
        setStatusSelected(item);
    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'no',
            width: '2%',
        },
        {
            title: 'Mã KH',
            dataIndex: 'code',
            key: 'code',
            width: '10%',
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'fullName',
            key: 'fullName',
            width: '29%',
        },
        {
            title: 'SĐT',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '15%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
        },
        {
            title: 'Loại khách hàng',
            dataIndex: 'customerType',
            key: 'customerType',
            width: '10%',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
        },
        {
            title: '',
            dataIndex: '',
            key: 'action',
            width: '4%',
        }
    ]

    return (
        <Container>
            <Dashboard>
                <Card>
                    <HeadingWrapper>
                        <HeadingTitle
                            type="large"
                            label={t('common.customer.heading_title')}
                        />
                        <Exports
                            exportType={EXPORT_TYPE.USERS}
                            roleType={ROLES.CUSTOMER}
                            searchParams={searchParams}
                        />
                    </HeadingWrapper>
                    <SearchContainer>
                        <AvForm onSubmit={handleSearch} ref={searchFormRef}>
                            <RowSearchContainer columns={4}>
                                <RemakeAvField
                                    height={'35px'}
                                    name="code"
                                    label={t('customer.code')}
                                    placeholder={t('customer.required.code')}
                                    type="text"
                                />
                                <RemakeAvField
                                    height={'35px'}
                                    name="fullName"
                                    label={t('customer.name')}
                                    placeholder={t('customer.required.name')}
                                    type="text"
                                />
                                <RemakeSelect
                                    label={t('customer.type')}
                                    placeholder={t('common.required.customer_type')}
                                    options={CUSTOMER_TYPE_SELECT}
                                    value={customerTypeSelect}
                                    onChange={onChangeCustomerType}
                                />
                                <RemakeSelect
                                    label={t('customer.status')}
                                    placeholder={t('customer.status')}
                                    options={STATUS_OPTIONS}
                                    value={statusSelected}
                                    onChange={onChangeStatus}
                                    hasAll
                                />
                            </RowSearchContainer>
                            <RowSearchContainer style={{ marginTop: 10 }}>
                                <RemakeAvField
                                    height={'35px'}
                                    name="phoneNumber"
                                    label={t('common.phone_number')}
                                    placeholder={t('common.required.phone_number')}
                                    type="text"
                                />
                                <RemakeAvField
                                    height={'35px'}
                                    name="email"
                                    label={t('common.email')}
                                    placeholder={t('common.required.email')}
                                    type="text"
                                />
                                <ButtonsContainer>
                                    <RemakeButton
                                        label={t('common.reset_input')}
                                        type="button"
                                        onClick={() => handleResetField()}
                                        buttonStyles={{ marginRight: 10, background: '#C4C4C4' }}
                                    />
                                    <RemakeButton label={t('common.search')} type='primary' />
                                </ButtonsContainer>
                            </RowSearchContainer>
                        </AvForm>
                    </SearchContainer>
                </Card>
                <Card style={{ marginTop: 20 }}>
                    <Table
                        columns={columns}
                        dataSource={usersResults}
                        recordNumberOnPage={paginationParams.pageSize}
                        totalRecord={total}
                        onClickEdit={(item) => handleClickEdit(item)}
                        onClickDelete={(item) => handleClickDelete(item)}
                        onClickPagination={(item) => handleClickPagination(item)}
                    />
                </Card>
            </Dashboard>
            <DialogMessage
                type="error"
                title={t('common.delete_data')}
                message={t('common.delete_data_confirm')}
                isOpen={isOpenDialog}
                leftAction={() => setIsOpenDialog(false)}
                rightAction={() => handleOkeDialog()}
            />
        </Container>
    );
}
