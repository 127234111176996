import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const SettingsTabsContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 40px;
    .nav-tabs {
        .nav-item {
            /* width: 50%; */
            cursor: pointer;
            font-weight: 500;
            .nav-link {
                color: #98a4b5;
            }
            .active {
                color: ${Colors.dark};
                border-color: ${Colors.dark} ${Colors.dark} #fff;
                font-weight: 600;
            }
        }
    }
`;

export const TabContent = styled.div`
    margin-top: 20px;
`;

export const TextSuggest = styled.div`
    font-style: italic;
    font-size: 12px;
    color: #685e5e;
`;