import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// styles
import { FieldContainer, OrdersStatusContainer, RowContainer, UpdateButton } from './styles';
import DialogContent from '~/components/DialogContent';
import Table from '~/components/Table';
import RemakeAvField from '~/components/RemakeAvField';
import RemakeSelect from '~/components/RemakeSelect';
import { formatAddressData, isEmpty } from '~/utils/helper';
import { getDistricts, getWards } from '~/modules/address/actions';
import { handleGetOrdersStatuses } from '~/modules/settings/actions';
import RemakeButton from '~/components/RemakeButton';
import { ButtonsContainer } from '~/components/ProfileInfo/styles';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import { clearOrdersEventsByCode, createOrdersEvent, getOrdersEventByOrdersCode } from '~/modules/orders/actions';
import { addressFormat } from '~/utils/formats';
import { DATE_FORMAT } from '~/constants/common';
import dayjs from 'dayjs';
import vi from 'dayjs/locale/vi';

function OrdersStatus(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const paginationParams = { page: 1, pageSize: 20 };
    const [provinceSelect, setProvinceSelect] = useState(null);
    const [districtSelect, setDistrictSelect] = useState(null);
    const [wardSelect, setWardSelect] = useState(null);
    const [ordersStatusSelected, setOrdersStatusSelected] = useState();
    const [isSubmited, setIsSubmited] = useState(false);

    const { provinces = [], districts = [], wards = [] } = useSelector(state => state.address);
    const {
        ordersStatuses = [],
    } = useSelector(state => {
        return state.settings;
    });
    const { ordersEvents = [], ordersEventTotal } = useSelector(state => {
        // console.log('state', state.orders);
        return state.orders;
    });
    const { ordersData = {} } = useSelector(state => state.orders);
    const { code = '' } = ordersData;

    const { ordersId, ordersStatusId } = props;

    useEffect(() => {
        dispatch(handleGetOrdersStatuses());
        return function cleanup() {
            dispatch(clearOrdersEventsByCode());
        };
    }, [])

    useEffect(() => {
        const orderStatusSelected = getOrdersStatusById(ordersStatusId);
        setOrdersStatusSelected(orderStatusSelected);
    }, [ordersStatusId])

    useEffect(() => {
        const params = {
            code
        }
        dispatch(getOrdersEventByOrdersCode(params));
    }, [ordersData])

    useEffect(() => {
    }, [ordersEvents]);

    const handleSubmitForm = (event, errors, values) => {
        setIsSubmited(true);
        if (isEmpty(errors)) {
            const {
                address,
                notes,
                ordersStatusId,
                provinceId,
                districtId,
                wardId,
            } = values;

            const body = {
                ordersStatusId,
                provinceId,
                districtId,
                wardId,
                address,
                notes,
                ordersId,
                code,
                isGetList: true
            }
            dispatch(createOrdersEvent(body));
            handleCloseReset();
        }
    }

    const getOrdersStatusById = (orderStatusId) => {
        if (isEmpty(ordersStatusesFormat)) return [];
        return ordersStatusesFormat.find(item => item?.value === orderStatusId);
    }

    let ordersStatusesFormat = ordersStatuses.length && ordersStatuses.map(item => {
        const { name, id } = item;
        return {
            value: id,
            label: name
        }
    });

    const handleOpenDialog = () => {
        setIsOpenDialog(true);
    }

    const handleClickPagination = (item) => {
        const { recordNumberOnPage = 0, paginationSelectedIndex = 0 } = item;
        dispatch(getOrdersEventByOrdersCode({ page: paginationSelectedIndex, pageSize: recordNumberOnPage, code }));
    }

    const getDistrictsAPI = (value) => {
        const params = {
            provinceId: value
        }
        dispatch(getDistricts(params));
    }

    const getWardsAPI = (value) => {
        const params = {
            districtId: value
        }
        dispatch(getWards(params));
    }

    const onChangeProvince = (itemSelected) => {
        const { value } = itemSelected;
        getDistrictsAPI(value);
        setProvinceSelect(itemSelected);
        setDistrictSelect(null);
        setWardSelect(null);
    }

    const onChangeDistrict = (itemSelected) => {
        const { value } = itemSelected;
        getWardsAPI(value);
        setDistrictSelect(itemSelected);
        setWardSelect(null);
    }

    const onChangeWard = (itemSelected) => {
        setWardSelect(itemSelected);
    }

    const onChangeOrdersStatus = (item) => {
        if (ordersStatuses.length) {
            const ordersStatus = ordersStatuses.find(i => ((i.key === 'PICKUPED_ORDERS') && (i.id === item.value)));
            console.log('ordersStatus', ordersStatus);
        }
        setOrdersStatusSelected(item);
    }

    const handleClose = () => {
        handleCloseReset();
        dispatch(clearOrdersEventsByCode());
    }

    const handleCloseReset = () => {
        setIsSubmited(false);
        setIsOpenDialog(false);
        setDistrictSelect(null);
        setProvinceSelect(null);
        setWardSelect(null);
    }

    let ordersEventsResults = ordersEvents && ordersEvents.map(item => {
        const {
            createdAt,
            statusInfo: {
                name: statusInfoName
            } = {},
            updatedByUser: {
                fullName: fullNameShipper
            } = {},
            provinceInfo: {
                name: provinceInfoName = ''
            },
            districtInfo: {
                name: districtInfoName = ''
            },
            wardInfo: {
                name: wardInfoName = ''
            },
            address,
        } = item;
        return {
            statusInfoName,
            address: addressFormat(provinceInfoName, districtInfoName, wardInfoName, address),
            createdAt: dayjs(createdAt).locale(vi).format(DATE_FORMAT.FULL_DATE_TIME),
            fullNameShipper,
        }
    })

    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'no',
            width: '3%',
        },
        {
            title: 'Thời gian',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '20%',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'statusInfoName',
            key: 'statusInfoName',
            width: '15%',
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            width: '45%',
        },
        {
            title: 'Cập nhật bởi',
            dataIndex: 'fullNameShipper',
            key: 'fullNameShipper',
            width: '17%',
        }
    ]

    const renderOrdersStatusContent = () => {
        return (
            <div>
                <Table
                    columns={columns}
                    dataSource={ordersEventsResults}
                    recordNumberOnPage={paginationParams.pageSize}
                    totalRecord={ordersEventTotal}
                    onClickPagination={(item) => handleClickPagination(item)}
                    hasMinWidth={true}
                />
                <div style={{ height: 1, background: '#e9ecef', marginBottom: 10 }}></div>
                <p style={{ fontSize: 16 }}>Cập nhật trạng thái đơn hàng</p>
                <AvForm onSubmit={handleSubmitForm}>
                    <FieldContainer>
                        <RemakeSelect
                            name='ordersStatusId'
                            label={t('common.orders.status')}
                            placeholder={t('common.orders.status')}
                            options={ordersStatusesFormat}
                            value={ordersStatusSelected}
                            onChange={onChangeOrdersStatus}
                            hasAll
                            isSubmited={isSubmited}
                            validate={{
                                required: { value: true, errorMessage: t('common.required.orders_status') },
                            }}
                        />
                    </FieldContainer>
                    <RowContainer style={{ marginTop: 15 }}>
                        <RemakeSelect
                            name="provinceId"
                            label={t('common.province')}
                            placeholder={t('common.required.province')}
                            options={formatAddressData(provinces)}
                            value={provinceSelect}
                            onChange={onChangeProvince}
                            isSubmited={isSubmited}
                            validate={{
                                required: { value: true, errorMessage: t('common.required.province') },
                            }}
                        />
                        <RemakeSelect
                            name="districtId"
                            label={t('common.district')}
                            placeholder={t('common.required.district')}
                            options={formatAddressData(districts)}
                            value={districtSelect}
                            onChange={onChangeDistrict}
                            isSubmited={isSubmited}
                            validate={{
                                required: { value: true, errorMessage: t('common.required.district') },
                            }}
                        />
                        <RemakeSelect
                            name="wardId"
                            label={t('common.ward')}
                            placeholder={t('common.required.ward')}
                            options={formatAddressData(wards)}
                            value={wardSelect}
                            onChange={onChangeWard}
                            isSubmited={isSubmited}
                            validate={{
                                required: { value: true, errorMessage: t('common.required.ward') },
                            }}
                        />
                    </RowContainer>
                    <FieldContainer>
                        <RemakeAvField
                            height={'35px'}
                            name="address"
                            label={t('common.address')}
                            placeholder={t('common.required.address')}
                            type="text"
                            validate={{
                                required: { value: true, errorMessage: t('common.required.address') },
                            }}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <RemakeAvField
                            height={'35px'}
                            name="notes"
                            label={t('common.note')}
                            placeholder={t('common.required.note')}
                            type="text"
                        />
                    </FieldContainer>
                    <ButtonsContainer>
                        <RemakeButton
                            label={t('common.cancel')}
                            type='button'
                            buttonStyles={{ marginRight: 10, background: '#C4C4C4' }}
                            onClick={() => handleCloseReset()}
                        />
                        <RemakeButton
                            label={t('common.save')}
                            type='primary'
                        />
                    </ButtonsContainer>
                </AvForm>
            </div>
        )
    }

    return (
        <OrdersStatusContainer>
            <UpdateButton onClick={() => handleOpenDialog()}>
                <span>{t('orders.status_schedule')}</span>
            </UpdateButton>
            <DialogContent
                width={1200}
                title={t('dialog.orders_status.default_title')}
                onCancelAction={() => handleClose()}
                isOpen={isOpenDialog}>
                {renderOrdersStatusContent()}
            </DialogContent>
        </OrdersStatusContainer>
    )
}

export default OrdersStatus
