import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';

export const Container = styled.div`
    height: 7vh;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    min-height: 60px;
`;

export const LogoWrapper = styled.div`
    width: 220px;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    cursor: pointer;

    img {
        height: 55px;
        width: 230px;
    }
    @media ${DEVICE_RESPONSIVE.mobile} {
        margin-right: 5px;
        img {
            width: 100%;
        }
    }
`;

export const HeaderContentWrapper = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 30px;
    @media ${DEVICE_RESPONSIVE.mobile} {
        margin-right: 10px;
    }
`;

export const MenuWrapper = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    width: 200px;
    justify-content: space-between;
`;

export const MenuItem = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    cursor: pointer;
    
    span {
        margin-left: 5px;
        font-weight: bold;
        font-size: 14px;
    }
`;

export const ProfileWrapper = styled.div`
    margin-left: 50px;
    cursor: pointer;

    img {
        height: 40px;
        width: 40px;
    }

    span {
        margin-left: 10px;
        font-weight: bold;
        font-size: 14px;
    }
`;