import { ACTION_TYPE } from '~/constants/actionTypes';

const handleUploadFile = (payload) => {
    return {
        type: ACTION_TYPE.UPLOAD_FILE.UPLOAD_FILES,
        payload
    };
}

const handleUploadFileSuccess = (data) => {
    return {
        type: ACTION_TYPE.UPLOAD_FILE.UPLOAD_FILES_SUCCESS,
        data
    };
}

const clearUploadFiles = () => {
    return {
        type: ACTION_TYPE.UPLOAD_FILE.CLEAR,
    };
}

export {
    handleUploadFile,
    handleUploadFileSuccess,
    clearUploadFiles
}
