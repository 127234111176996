import React, { useState } from 'react';
import {
    Container, SubMenuMenuItem,
    SettingWrapper
} from '~/pages/Settings/styles';
import { useTranslation } from 'react-i18next';
import Dashboard from '~/components/Dashboard';
import Card from '~/components/Card';
import HeadingTitle from '~/components/HeadingTitle';
import { SUB_MENUS_SIDEBAR as subMenusSidebar, SUB_MENU_KEY } from '~/constants/common';
import Icon from '~/components/Icon';
import { Colors } from '~/constants/styles';
import OrdersStatus from '~/components/OrdersStatus';
import Appearance from '~/components/Appearance';
import StaticPage from '~/components/StaticPage';
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';
import { List } from '@styled-icons/evaicons-solid/List';
import { DesktopEdit } from '@styled-icons/fluentui-system-regular/DesktopEdit';
import { TextareaT } from '@styled-icons/bootstrap/TextareaT';
export default function Settings() {
    const { t } = useTranslation();
    const [subMenuSelected, setSubMenuSelected] = useState(subMenusSidebar[0]);

    const handleClickMenuItem = (item) => {
        setSubMenuSelected(item);
    }

    const handleGetMenuIcon = (key) => {
        switch (key) {
            case 'list-minus':
                return <List color={Colors.dark} width={25} />

            case 'desktop':
                return <DesktopEdit color={Colors.dark} width={25} />

            case 'text':
                return <TextareaT color={Colors.dark} width={25} />

            default:
                break;
        }
    }

    const renderSubMenu = () => {
        return subMenusSidebar.map(item => {
            return (
                <SubMenuMenuItem onClick={() => handleClickMenuItem(item)}>
                    <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', width: 170, height: 30 }}>
                        {handleGetMenuIcon(item.icon)}
                        <div>
                            <span style={item.key === subMenuSelected.key ? { fontWeight: 'bold' } : {}}>{t(item.label)}</span>
                        </div>
                    </div>
                    <div style={{ width: 30 }}>
                        {item.key === subMenuSelected.key &&
                            <ArrowRightShort color={Colors.dark} />
                        }
                    </div>
                </SubMenuMenuItem>
            )
        })
    }

    const renderSubMenuContent = () => {
        switch (subMenuSelected.key) {
            case SUB_MENU_KEY.ORDERS_STATUS:
                return <OrdersStatus />;

            case SUB_MENU_KEY.APPEARANCE:
                return <Appearance />;

            case SUB_MENU_KEY.PAGE_STATIC:
                return <StaticPage />
        }
    }

    const renderSubMenuPageStaticContent = () => {
        return (
            <HeadingTitle
                type="normal"
                label={t('dashboard.sidebar.submenu.page_static')}
            />
        )
    }

    return (
        <Container>
            <Dashboard>
                <Card>
                    <HeadingTitle
                        type="large"
                        label={t('common.settings.heading_title')}
                    />
                    <SettingWrapper>
                        <div style={{ height: '100%', border: '1px solid #e0e0e0', borderWidth: '0px 2px 0px 0px', padding: '0px 15px 10px 0px' }} >
                            {renderSubMenu()}
                        </div>
                        <div style={{ padding: '6px 10px', width: '100%' }}>
                            {renderSubMenuContent()}
                        </div>
                    </SettingWrapper>
                </Card>
            </Dashboard>
        </Container>
    )
}
