import React, { useEffect } from 'react';
import { Wrapper } from '~/components/Container/styles';
import { ThemeProvider } from 'styled-components';
import {
    lightTheme,
    darkTheme
} from '~/styles/global';
import { useSelector } from 'react-redux';
import { THEME_MODE_TYPE } from '~/constants/common';
import i18n from 'i18next';

export default function Container(props) {
    const { themeMode } = useSelector(state => state.themeMode);

    useEffect(() => {
        // Set default language
        i18n.changeLanguage('vi');
    }, [])

    return (
        <Wrapper>
            <ThemeProvider theme={themeMode === THEME_MODE_TYPE.DARK ? darkTheme : lightTheme}>
                {props.children}
            </ThemeProvider>
        </Wrapper>
    )
}
