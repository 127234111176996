import styled from 'styled-components';

export const DialogContent = styled.div`
    padding: 30px;
    overflow-x: auto;

    img {
        float: right;
        cursor: pointer;
    }
`;