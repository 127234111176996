import styled from 'styled-components';
import { Colors } from '~/constants/styles';

export const ReportItem = styled.div`
    flex: 1 1 auto;
    padding: 1rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e2e7f1;
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-between;
`;

export const ReportContainer = styled.div`

`;

export const ReportList = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 10px;
`;

export const LeftContent = styled.div`

    h3 {
        margin-top: 10px;
        font-size: 1.5rem;
    }

    span {
        text-transform: uppercase;
    }

`;

export const RightContent = styled.div`

`;