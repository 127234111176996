import styled from 'styled-components';
import { DEVICE_RESPONSIVE } from '~/constants/common';
import { Colors } from '~/constants/styles';

export const ShippingFeeContainer = styled.div`
`;
export const ShippingFee = styled.div`
    font-weight: 500;
    font-size: 15px;
    grid-column: 2/3;
    margin-top: 20px;
    @media ${DEVICE_RESPONSIVE.mobile} {
        margin-top: 0px;
    }
`;